<template>
  <b-input-group>
    <template #prepend>
      <b-input-group-text><b-icon-search /></b-input-group-text>
    </template>
    <b-form-input
      v-model="query"
      :placeholder="placeholder"
      @input="filter"
    />
    <template #append>
      <b-input-group-text>
        <b-checkbox
          v-model="caseSensitive"
          @input="filter"
        />
        Case Sensitive
      </b-input-group-text>
    </template>
  </b-input-group>
</template>

<script>
  import { BIconSearch } from 'bootstrap-vue'
  export default {
    components: {BIconSearch},
    props: {
      data: {type: Array, default: null},
      featuresToQuery: {type: Array, default: null},
      placeholder: {type: String, default: null},
      filtered: {type: Array, default: null}
    },
    data: () => ({query: '', caseSensitive: false}),
    created() { this.filter(); },
    methods: {
      searchRegex() {
        return new RegExp(this.query, `g${this.caseSensitive ? '' : 'i'}`);
      },
      matched: (regex, value) => value.replace(
        regex, substring => `<span class="bg-warning">${substring}</span>`
      ),
      nestedIndex(object, indexList) {
        var result = object;
        indexList.split('.').forEach(index => result = result[index]);
        return result;
      },
      filter() {
        this.$emit(
          'update:filtered',
          this.data.filter(
            entry => {
              const regex = this.searchRegex();
              return this.featuresToQuery.some(
                featureToQuery => this.nestedIndex(
                  entry, featureToQuery
                ).match(regex)
              );
            }
          ).map(
            (entry, index) => {
              if (this.query === '') {
                return {
                  index, entry, queried: Object.fromEntries(
                    this.featuresToQuery.map(
                      featureToQuery => [
                        featureToQuery, this.nestedIndex(entry, featureToQuery)
                      ]
                    )
                  ),
                };
              }
              const regex = this.searchRegex();
              return {
                index, entry, queried: Object.fromEntries(
                  this.featuresToQuery.map(
                    featureToQuery => [
                      featureToQuery,
                      this.matched(
                        regex, this.nestedIndex(entry, featureToQuery)
                      )
                    ]
                  )
                ),
              };
            }
          )
        );
      },
    }
  };
</script>
