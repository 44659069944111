<template>
  <section class="h-100">
    <b-modal
      :visible="isSaving"
      size="lg"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
    >
      <div class="d-flex justify-content-around my-2">
        <h3 class="flex-fill">
          Saving...
        </h3>
        <b-spinner class="border-1" />
      </div>
    </b-modal>
    <b-modal
      id="custom-report-save-failed"
      size="lg"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
    >
      <h3>Error occurred while saving!</h3>
      <div v-text="error" />
      <b-button
        class="ml-1 float-right"
        variant="primary"
        @click="$bvModal.hide('custom-report-save-failed')"
        v-text="`OK`"
      />
    </b-modal>
    <b-modal
      id="custom-report-save-succeeded"
      size="lg"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
    >
      <h3>Saved successfuly!</h3>
      <b-button
        class="ml-1 float-right"
        variant="primary"
        @click="$bvModal.hide('custom-report-save-succeeded')"
        v-text="`OK`"
      />
    </b-modal>
    <b-modal
      id="image-failed"
      size="lg"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
    >
      <h3>Error occurred while processing image!</h3>
      <div v-text="error" />
      <b-button
        class="ml-1 float-right"
        variant="primary"
        @click="$bvModal.hide('image-failed')"
        v-text="`OK`"
      />
    </b-modal>
    <div class="h-100">
      <template v-if="deployAll && isEditing">
        <div
          v-if="isEditing"
          class="d-flex mb-1"
          :style="{height: 'calc(100% - 190px)'}"
        >
          <textarea
            v-model="localReportCode"
            v-focus="isEditing"
            :style="{'width': '50%', resize: 'none'}"
            class="form-control"
          />
          <div
            class="w-50 ml-2 p-2 border rounded-3 d-flex bg-light position-relative"
          >
            <!-- eslint-disable vue/no-v-html -->
            <div
              class="flex-fill text-justify overflow-auto mb-4"
              v-html="renderedMd(localReportCode)"
            />
            <!-- eslint-enable -->
            <div
              class="text-muted mb-1 mr-2 position-absolute"
              :style="{bottom: 0, right: 0}"
            >
              <small class="mr-1">Preview</small>
              <b-icon icon="markdown" />
            </div>
          </div>
        </div>
        <div
          :style="{height: '150px'}"
          class="border rounded mb-1 px-1 d-flex position-relative"
          @dragover.stop.prevent="onDragOver"
          @dragleave="onDragLeave"
          @drop.stop.prevent="onDrop"
        >
          <div
            v-for="filename in uploadedImages"
            :key="filename"
            :title="imageReference(filename)"
            class="my-2 mx-1 border rounded d-flex align-items-start flex-column text-truncate position-relative"
            :style="{'width': '150px'}"
          >
            <img
              :src="imageReference(filename)"
              :style="{'width': '152px', 'height': '99px', 'object-fit': 'cover'}"
              class="rounded-top"
            >
            <b-icon-x-circle-fill
              class="position-absolute text-danger rounded-circle bg-light"
              :style="{'right': '3px', 'top': '3px', 'cursor': 'pointer'}"
              title="Remove this image from server"
              @click="removeImage(filename)"
            />
            <div class="btn-group w-100">
              <button
                class="btn btn-sm btn-outline-secondary mt-auto"
                :style="{
                  'border-top-left-radius': '0!important',
                  'border-top-right-radius': '0!important',
                }"
                @click="copyMd(filename)"
              >
                <b-icon-markdown />
              </button>
              <button
                class="btn btn-sm btn-outline-secondary mt-auto"
                :style="{
                  'border-top-left-radius': '0!important',
                  'border-top-right-radius': '0!important',
                }"
                @click="copyHtml(filename)"
              >
                &lt;img&gt;
              </button>
            </div>
          </div>
          <div
            class="flex-fill mx-1 my-2 border rounded d-flex"
            :style="{
              'border-width': '3px!important',
              'border-color': 'rgb(0, 0, 0, 0.2)!important',
              'border-style': 'dashed!important',
              'border-radius': '15px!important'
            }"
          >
            <div class="flex-fill" />
            <em class="my-auto text-muted">
              <template v-if="draggedOver">
                Drop to upload now <b-icon-upload />
              </template>
              <template v-else>Drag your images here</template>
            </em>
            <div class="flex-fill" />
          </div>
          <div
            :style="{
              'height': '150px',
              'background-color': 'rgb(255, 255, 255, .3)',
              'visibility': processingImages ? 'visible' : 'hidden',
            }"
            class="position-absolute d-flex flex-column w-100"
          >
            <div class="flex-fill" />
            <div class="d-flex">
              <div class="flex-fill" />
              <b-spinner />
              <div class="flex-fill" />
            </div>
            <div class="flex-fill" />
          </div>
        </div>
        <div class="d-flex">
          <div class="flex-fill" />
          <button
            type="submit"
            class="btn btn-primary d-flex"
            :disabled="isSaving"
            @click="save"
          >
            Save
            <span
              v-if="isSaving"
              class="spinner-border text-light ml-2 align-self-center"
            />
          </button>
          <button
            class="btn btn-secondary ml-1"
            :disabled="isSaving"
            @click="cancelEditing"
          >
            Cancel
          </button>
        </div>
      </template>
      <a
        v-else
        :class="{'text-decoration-none': true, 'h-100': true, 'clickable': deployAll}"
        @click="edit"
      >
        <p class="text-dark text-justify text-decoration-none d-flex h-100 position-relative overflow-auto">
          <!-- eslint-disable vue/no-v-html -->
          <span
            :class="{'flex-fill': true, 'text-muted': !serverReportCode}"
            v-html="renderedMd(serverReportCode || '_No report introduced yet. Click here to start editing._')"
          />
          <!-- eslint-enable -->
          <span class="d-flex flex-column ml-2">
            <a v-if="deployAll"><b-icon icon="pencil" /></a>
          </span>
        </p>
      </a>
    </div>
  </section>
</template>

<script>
import showdown from 'showdown';

export default {
  props: { initReportCode: {type: String, default: null} },
  data() {
    return {
      mdConverter: new showdown.Converter({tables: true}),
      localReportCode: this.initReportCode,
      serverReportCode: this.initReportCode,
      isEditing: false, isSaving: false, error: null,
      uploadedImages: [],
      processingImages: false,
      draggedOver: false,
    };
  },
  computed: {
    deployAll: () => ["true", "t", "yes", "y", "on"].includes(
      process.env.VUE_APP_DEPLOY_ALL.toLowerCase()
    ),
    backendUrl: () => process.env.VUE_APP_VISUALIZER_BACKEND_URL,
    backendToken: () => process.env.VUE_APP_BACKEND_TOKEN,
    visualizerBackendUrl: () => process.env.VUE_APP_VISUALIZER_NONADMIN_BACKEND_URL,
    visualizerBackendToken: () => process.env.VUE_APP_VISUALIZER_NONADMIN_BACKEND_TOKEN,
    taskId() { return this.$route.params.taskId; },
  },
  async mounted() {
    const urlBase = `image/${this.taskId}`;
    const url = `${this.backendUrl}/${urlBase}/?token=${this.backendToken}`;
    const response = await fetch(url, { method: "GET" });
    if (response.ok) {
      this.uploadedImages = (await response.json() || {}).filenames || [];
    }
  },
  methods: {
    onDragOver(event) {
      event.dataTransfer.dropEffect = 'copy';
      this.draggedOver = true;
    },
    onDragLeave() { this.draggedOver = false; },
    async onDrop(event) {
      this.error = null;
      this.draggedOver = false;
      this.processingImages = true;
      const files = event.dataTransfer.files;
      if (files.length > 0) {
        const nonImageFiles = [];
        await Promise.all(
          Array.from(files).map(
            async file => {
              if (file.type.split('/')[0] === 'image') {
                await this.uploadImage(file.name, file);
                return;
              }
              nonImageFiles.push(file.name);
            }
          )
        );
        if (nonImageFiles.length > 0) {
          this.error = `The following files are not images: ${nonImageFiles.join(', ')}. Only images can be uploaded.`;
        }
      }
      this.processingImages = false;
      if (this.error) {
        this.$bvModal.show('image-failed');
      }
    },
    renderedMd(mdCode) {
      return this.mdConverter.makeHtml(mdCode || '').replace(
        '<table>',
        '<table class="table table-sm" style="white-space: nowrap; width: 1%">'
      );
    },
    edit() { this.isEditing = true; },
    async uploadImage(filename, data) {
      const urlBase = `image/${this.taskId}/${filename}`;
      const url = `${this.backendUrl}/${urlBase}/?token=${this.backendToken}`;
      const body = new FormData();
      body.append('image', data);
      const response = await fetch(url, { method: "POST", body });
      if (response.ok) {
        this.uploadedImages.push(filename);
        this.copyHtml(filename);
        return;
      }
      const responseData = await response.json();
      if (responseData.errors) {
        this.error = `The following error occurred while uploading image ${filename}: ${responseData.errors[0]}`;
        return;
      }
      this.error = `Unknown error occurred while uploading image ${filename}.`;
    },
    async removeImage(filename) {
      this.processingImages = true;
      const urlBase = `image/delete/${this.taskId}/${filename}`;
      const url = `${this.backendUrl}/${urlBase}/?token=${this.backendToken}`;
      const response = await fetch(url, { method: "POST" });
      if (response.ok) {
        this.uploadedImages = this.uploadedImages.filter(
          element => element !== filename
        );
      } else {
        const responseData = await response.json();
        if (responseData.errors) {
          this.error = (
            `The following error occurred while removing image ${filename}: ${responseData.errors[0]}`
          );
        } else {
          this.error = `Unknown error occurred while removing image ${filename}.`;
        }
        this.$bvModal.show('image-failed');
      }
      this.processingImages = false;
    },
    imageReference(filename) {
      const urlBase = `image/${this.taskId}/${filename}`;
      const urlArgs = `token=${this.visualizerBackendToken}`;
      return `${this.visualizerBackendUrl}/${urlBase}/?${urlArgs}`;
    },
    copyMd(filename) {
      this.localReportCode += `![](${this.imageReference(filename)})`;
    },
    copyHtml(filename) {
      this.localReportCode += (
        `<img src="${this.imageReference(filename)}" width="100%">`
      );
    },
    async save() {
      this.isSaving = true;
      const urlBase = `save-custom-report/${this.taskId}`;
      const url = `${this.backendUrl}/${urlBase}/?token=${this.backendToken}`;
      const body = JSON.stringify({customReportMd: this.localReportCode});
      const response = await fetch(url, { method: "POST", body });
      this.isSaving = false;
      if (!response.ok) {
        this.error = 'Error occurred while processing request.';
        this.$bvModal.show("custom-report-save-failed");
        return;
      }
      this.error = null;
      this.serverReportCode = this.localReportCode;
      this.$bvModal.show("custom-report-save-succeeded");
      this.isEditing = false;
    },
    cancelEditing() {
      this.localReportCode = this.serverReportCode;
      this.isEditing = false;
    }
  }
};
</script>

<style scoped>
  .text-justify {text-align: justify!important;}
  a {color: #d12239!important;}
  a:hover {color: #8f1727!important;}
</style>
