<script>

  import chroma from "chroma-js"
  import L from 'leaflet';
  import {ChoroplethLayer} from 'vue-choropleth';

  const validNumber = num => !(
    isNaN(num) || num == null || num == undefined ||
    num == Infinity || num == -Infinity
  );

  function mouseover({ target }) {
    target.setStyle(
      {
        weight: this.currentStrokeWidth,
        color: `#${this.currentStrokeColor}`,
        dashArray: ""
      }
    );
    if (!L.Browser.ie && !L.Browser.opera) {
      target.bringToFront();
    }
    let geojsonItem = target.feature.properties;
    let item = this.geojsonData.data.find(
      x => x[this.idKey] == geojsonItem[this.geojsonIdKey]
    );
    if (!item) {
      this.currentItem = { name: "", value: 0 };
      return;
    }
    let tempItem = { name: item[this.titleKey], value: item[this.value.key] };
    if (this.extraValues) {
      let tempValues = [];
      for (let x of this.extraValues) {
        tempValues.push({value: item[x.key], metric: x.metric});
      }
      tempItem = { ...tempItem, extraValues: tempValues };
    }
    this.currentItem = tempItem;
  }

  function mouseout({ target }) {
    target.setStyle(
      {
        weight: this.strokeWidth,
        color: `#${this.strokeColor}`,
        dashArray: ""
      }
    );
    this.currentItem = { name: "", value: 0 };
  }

  function mouseclick({ target }) {
    this.$emit('countryClicked', target.feature.properties.iso_a2);
  }

  function normalizeValue(value, min, max) {
    return ((value - min) / (max - min)) ** 0.1;
  }

  const getColor = (param, colorScale, min, max) => ( 
    chroma.scale(colorScale).mode("lch")(normalizeValue(param, min, max)).hex()
  );

  export default {
    extends: ChoroplethLayer,
    data() {
      return {
        currentItem: { name: "", value: 0 },
        geojsonOptions: {
          style: feature => {
            let itemGeoJSONID = feature.properties[this.geojsonIdKey];
            const {data} = this.geojsonData;
            let item = data.find(x => x[this.idKey] == itemGeoJSONID);
            if (!item) {
              return {color: "white", weight: this.strokeWidth};
            }
            let valueParam = Number(item[this.value.key]);
            if (!validNumber(valueParam)) {
              return {color: "white", weight: this.strokeWidth};
            }
            const { min, max } = this;
            return {
              weight: this.strokeWidth,
              opacity: 1,
              color: `#${this.strokeColor}`,
              dashArray: "3",
              fillOpacity: 0.7,
              fillColor: getColor(valueParam, this.colorScale, min, max)
            };
          },
          onEachFeature: (feature, layer) => layer.on(
            {
              mouseover: mouseover.bind(this),
              mouseout: mouseout.bind(this),
              click: mouseclick.bind(this),
            }
          )
        }
      };
    },
  };

</script>
