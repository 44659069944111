<template>
  <main class="container">
    <div
      v-if="isLoading"
      class="row"
    >
      <h1>Loading collection of reports...</h1>
      <b-spinner class="ml-3" />
    </div>
    <template v-else-if="data">
      <h1>{{ data.name }}</h1>
      <ul>
        <li
          v-for="report in data.reports"
          :key="report.id"
        >
          <a :href="`/#/visualizer/${report.id}`">
            {{ report.filename }}
          </a>
        </li>
      </ul>
    </template>
  </main>
</template>

<script>
import httpHelper from '../../modules/httpHelper';
export default {
  data: () => ({isLoading: true, data: null}),
  computed: {
    backendUrl: () => process.env.VUE_APP_VISUALIZER_BACKEND_URL,
    backendToken: () => process.env.VUE_APP_BACKEND_TOKEN,
  },
  async mounted() {
    this.isLoading = true;
    try {
      this.data = null;
      const deliverableId = this.$route.params.deliverableId;
      const args = `token=${this.backendToken}`;
      const url = `${this.backendUrl}/deliverable/${deliverableId}/?${args}`;
      const response = await fetch(url);
      this.data = await httpHelper.safeJson(response);
    } finally {
      this.isLoading = false;
    }
  },
}
</script>

<style scoped>
</style>
