var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    { staticClass: "navbar navbar-expand-lg navbar-dark bg-dark fixed-top" },
    [
      _c("div", { staticClass: "container" }, [
        _vm._m(0),
        _c("div", { staticClass: "text-light h3" }, [
          _vm._v(" " + _vm._s(_vm.filename) + " ")
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "navbar-brand" }, [
      _c("img", {
        staticClass: "hidden-xs",
        attrs: { src: require("../images/logo.png"), height: "60px" }
      }),
      _vm._v(" CSV Analyzer ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }