function hsv2rgb(h, s, v) {
  var r, g, b, i, f, p, q, t;
  if (arguments.length === 1) {
      s = h.s, v = h.v, h = h.h;
  }
  i = Math.floor(h * 6);
  f = h * 6 - i;
  p = v * (1 - s);
  q = v * (1 - f * s);
  t = v * (1 - (1 - f) * s);
  switch (i % 6) {
      case 0: r = v, g = t, b = p; break;
      case 1: r = q, g = v, b = p; break;
      case 2: r = p, g = v, b = t; break;
      case 3: r = p, g = q, b = v; break;
      case 4: r = t, g = p, b = v; break;
      case 5: r = v, g = p, b = q; break;
  }
  r = Math.round(r * 255);
  g = Math.round(g * 255);
  b = Math.round(b * 255);
  return `rgb(${r}, ${g}, ${b})`;
}

const fmod = (a, b) => Number((a - (Math.floor(a / b) * b)).toPrecision(8));

function distinctColor(i) {
  const rad = 0.618033988749895;
  return hsv2rgb(fmod(i * rad, 1.0), 1, Math.sqrt(1.0 - fmod(i * rad, 0.5)));
}

export default {
  distinctColors: n => {
    if (n === 1) {
      return ['rgb(209, 34, 57)'];
    }
    if (n === 2) {
      return ['rgb(209, 34, 57)', 'rgb(62, 76, 81)'];
    }
    return [...Array(n).keys()].map(distinctColor);
  }
}
