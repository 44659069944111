<template>
  <LMap
    :center="[20, 0]"
    :zoom="2"
    style="height: 500px"
    :options="mapOptions"
  >
    <ChoroplethLayer
      :data="data"
      id-key="countryCode"
      title-key="countryName"
      :value="{key: 'count', metric: 'entries in total'}"
      geojson-id-key="iso_a2"
      :geojson="worldMap"
      :color-scale="mapColorScale"
      :stroke-width="1"
      :current-stroke-width="2"
      current-stroke-color="D12239"
      @countryClicked="countryClicked"
    >
      <template slot-scope="props">
        <InfoControl
          :item="infoControlItem(props.currentItem)"
          :unit="props.unit"
          title="World Countries"
          placeholder="Hover over a country"
          position="bottomleft"
        />
      </template>
    </ChoroplethLayer>
  </LMap>
</template>

<script>

  import Humanize from 'humanize-plus';
  import {LMap} from 'vue2-leaflet';
  import {InfoControl} from 'vue-choropleth';
  import worldMap from '../../geodata/world.json';
  import iso2CountryCodes from '../../geodata/iso2-country-codes.json';
  import ChoroplethLayer from './ChoroplethLayer.vue';

  export default {
    components: {LMap, InfoControl, ChoroplethLayer},
    props: {correlations: {type: Object, default: null},},
    data() {
      return {
        worldMap,
        mapColorScale: ['#FDC9C4', '#D12239'],
        mapOptions: {
          attributionControl: false,
          zoomControl: false,
          scrollWheelZoom: false,
          dragging: false
        },
      };
    },
    computed: {
      data() {
        return (this.correlations.data || []).map(
          correlation => {
            if ((typeof correlation.correlationValue) !== 'string') {
              return null;
            }
            const split = correlation.correlationValue.split('-');
            const countryCode = split[split.length - 1].toUpperCase();
            const countryName = iso2CountryCodes[countryCode];
            return {
              countryCode, countryName, ...correlation,
              count: correlation.correlationTotal
            };
          }
        ).filter(
          correlation => Object.prototype.hasOwnProperty.call(
            iso2CountryCodes, correlation.countryCode
          )
        );
      },
    },
    methods: {
      humanize: Humanize.intComma,
      countryClicked(countryCode) {
        this.$emit('countryClicked', countryCode);
      },
      infoControlItem(item) {
        return {...item, value: this.humanize(item.value)};
      }
    }
  };
</script>
