var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _c("img", {
      staticClass: "border",
      attrs: {
        src:
          _vm.backendUrl +
          "/hilbert/" +
          _vm.taskId +
          "/" +
          _vm.columnName +
          "/?token=" +
          _vm.backendToken,
        width: "512",
        height: "512"
      },
      on: {
        mousemove: _vm.mapHovered,
        mouseout: _vm.mapOut,
        click: _vm.mapChoose
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }