var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    { staticClass: "navbar navbar-expand-lg navbar-dark bg-dark fixed-top" },
    [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c(
            "router-link",
            {
              staticClass: "navbar-brand",
              attrs: { to: { name: "index" }, "data-test": "navbar-brand" }
            },
            [
              _c("img", {
                staticClass: "hidden-xs",
                attrs: { src: require("../images/logo.png"), height: "60px" }
              }),
              _vm._v(" CSV Analyzer ")
            ]
          ),
          _c("div", { staticClass: "collapse navbar-collapse" }, [
            _c("ul", { staticClass: "navbar-nav" }, [
              _c(
                "li",
                { staticClass: "nav-item active" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: { name: "newJobFileForm" } }
                    },
                    [_vm._v(" Start New Job ")]
                  )
                ],
                1
              ),
              _c(
                "li",
                { staticClass: "nav-item active" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: { name: "importJobResults" } }
                    },
                    [_vm._v(" Import Existing Report ")]
                  )
                ],
                1
              ),
              _c(
                "li",
                { staticClass: "nav-item active" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: { name: "presets" } }
                    },
                    [_vm._v(" Presets ")]
                  )
                ],
                1
              )
            ])
          ]),
          _c("div", { staticClass: "text-light h3" }, [
            _vm._v(" " + _vm._s(_vm.filename) + " ")
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }