var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "row ml-5" },
      [
        _c(
          "b-form-checkbox",
          {
            on: { change: _vm.refresh },
            model: {
              value: _vm.showEmpty,
              callback: function($$v) {
                _vm.showEmpty = $$v
              },
              expression: "showEmpty"
            }
          },
          [_vm._v(" Show Empty ")]
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "row" },
      [
        _c("VueApexCharts", {
          ref: "chart",
          staticClass: "col",
          attrs: {
            type: "pie",
            height: "500px",
            options: _vm.options,
            series: _vm.series
          }
        }),
        _c(
          "table",
          {
            staticClass:
              "legend col table table-sm table-responsive table-borderless"
          },
          [
            _c(
              "tbody",
              _vm._l(_vm.legend, function(legendItem, index) {
                return _c(
                  "tr",
                  {
                    key: legendItem.label,
                    class: {
                      "font-weight-bold": legendItem.isSelected,
                      "bg-secondary": legendItem.isHovered,
                      "text-light": legendItem.isHovered
                    },
                    on: {
                      click: function($event) {
                        return _vm.selectData(index)
                      },
                      mouseover: function($event) {
                        return _vm.hoverData(legendItem)
                      },
                      mouseout: function($event) {
                        return _vm.unhoverData(legendItem)
                      }
                    }
                  },
                  [
                    _c("td", { staticClass: "d-flex" }, [
                      _c("span", {
                        staticClass: "legend-color",
                        style: { "background-color": legendItem.color }
                      })
                    ]),
                    _c(
                      "td",
                      { staticClass: "legend-label text-truncate" },
                      [
                        _vm.type === "url" &&
                        legendItem.label &&
                        !_vm.isOther(legendItem)
                          ? _c(
                              "a",
                              {
                                class: { "text-light": legendItem.isHovered },
                                attrs: {
                                  href: legendItem.label,
                                  target: "_blank"
                                }
                              },
                              [_vm._v(" " + _vm._s(legendItem.label) + " ")]
                            )
                          : legendItem.label
                          ? [_vm._v(" " + _vm._s(legendItem.label) + " ")]
                          : _c("em", [_vm._v("empty")])
                      ],
                      2
                    ),
                    _c("td", { staticClass: "text-right" }, [
                      _vm._v(" " + _vm._s(_vm.humanize(legendItem.value)) + " ")
                    ]),
                    _vm.type === "domain" || _vm.type === "domain-key"
                      ? _c("td", { staticClass: "w-fit" }, [
                          !_vm.isOther(legendItem)
                            ? _c("img", {
                                attrs: {
                                  src:
                                    _vm.backendUrl +
                                    "/favicon/" +
                                    legendItem.label +
                                    "/?token=" +
                                    _vm.backendToken,
                                  width: "20",
                                  height: "20",
                                  onerror: "this.classList.add('d-none')"
                                }
                              })
                            : _vm._e()
                        ])
                      : _vm.type === "country"
                      ? _c("td", [
                          !_vm.isOther(legendItem)
                            ? _c("span", {
                                class:
                                  "flag-icon flag-icon-" +
                                  _vm.countryCode(legendItem.label) +
                                  " border"
                              })
                            : _vm._e()
                        ])
                      : _vm._e()
                  ]
                )
              }),
              0
            )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }