var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("VueApexCharts", {
    attrs: {
      type: "line",
      options: _vm.options(),
      series: _vm.series(),
      height: "550"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }