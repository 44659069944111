var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "LMap",
    {
      staticStyle: { height: "550px" },
      attrs: { center: [20, 0], zoom: 2, options: _vm.mapOptions }
    },
    [
      _c("ChoroplethLayer", {
        attrs: {
          data: _vm.data,
          "id-key": "countryCode",
          "title-key": "countryName",
          value: { key: "count", metric: "entries in total" },
          "extra-values": [{ key: "rate", metric: " of all entries" }],
          "geojson-id-key": "iso_a2",
          geojson: _vm.worldMap,
          "color-scale": _vm.mapColorScale,
          "stroke-width": 1,
          "current-stroke-width": 2,
          "current-stroke-color": "D12239"
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(props) {
              return [
                _c("InfoControl", {
                  attrs: {
                    item: _vm.infoControlItem(props.currentItem),
                    unit: props.unit,
                    title: "World Countries",
                    placeholder: "Hover over a country",
                    position: "bottomleft"
                  }
                })
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }