var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    { staticClass: "container report-tabs" },
    [
      _c("b-modal", {
        attrs: {
          id: "change-column-order",
          size: "lg",
          centered: "",
          "hide-header": "",
          "hide-footer": "",
          "no-close-on-backdrop": "",
          "no-close-on-esc": ""
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var hide = ref.hide
              return [
                _vm.orderedColumns
                  ? [
                      _c("h3", [
                        _vm._v(" Change Order of Columns in "),
                        _c("em", {
                          domProps: {
                            textContent: _vm._s(
                              _vm.groupForColumnsOrdering.title
                            )
                          }
                        }),
                        _vm._v(" Group ")
                      ]),
                      _c(
                        "draggable",
                        {
                          staticClass: "overflow-auto p-2 m-2",
                          staticStyle: { "max-height": "500px" },
                          model: {
                            value: _vm.orderedColumns,
                            callback: function($$v) {
                              _vm.orderedColumns = $$v
                            },
                            expression: "orderedColumns"
                          }
                        },
                        _vm._l(_vm.orderedColumns, function(column) {
                          return _c(
                            "div",
                            {
                              key: column.name,
                              class: {
                                "text-muted": _vm.savingColumnOrder,
                                border: true,
                                "my-1": true,
                                "p-1": true,
                                draggable: true
                              },
                              attrs: {
                                "data-test": "column-reorderer-" + column.name
                              },
                              on: {
                                dragstart: _vm.dragStart,
                                dragend: _vm.dragEnd
                              }
                            },
                            [
                              _c(
                                "strong",
                                [
                                  _c("b-icon-grip-vertical"),
                                  _vm._v(" " + _vm._s(column.title) + " ")
                                ],
                                1
                              )
                            ]
                          )
                        }),
                        0
                      ),
                      _vm.orderColumnError
                        ? _c("div", { staticClass: "text-danger ml-2 my-2" }, [
                            _vm._v(" " + _vm._s(_vm.orderColumnError) + " ")
                          ])
                        : _vm._e(),
                      _c(
                        "b-button",
                        {
                          staticClass: "ml-1",
                          attrs: {
                            variant: "primary",
                            disabled: _vm.savingColumnOrder,
                            "data-test": "save-column-order"
                          },
                          on: {
                            click: function($event) {
                              return _vm.saveColumnOrder()
                            }
                          }
                        },
                        [
                          _vm.savingColumnOrder
                            ? [
                                _vm._v(" Saving... "),
                                _c("b-spinner", { attrs: { small: "" } })
                              ]
                            : [_vm._v(" Save ")]
                        ],
                        2
                      ),
                      _c("b-button", {
                        staticClass: "ml-1",
                        attrs: {
                          disabled: _vm.savingColumnOrder,
                          variant: "secondary"
                        },
                        domProps: { textContent: _vm._s("Cancel") },
                        on: {
                          click: function($event) {
                            return hide()
                          }
                        }
                      })
                    ]
                  : _vm._e()
              ]
            }
          }
        ])
      }),
      _c(
        "b-modal",
        {
          attrs: {
            id: "column-order-successfuly-saved-message",
            size: "lg",
            centered: "",
            "hide-header": "",
            "hide-footer": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": ""
          }
        },
        [
          _vm.groupForColumnsOrdering
            ? _c("h3", [
                _vm._v(
                  " Order of Columns in " +
                    _vm._s(_vm.groupForColumnsOrdering.title) +
                    " Group Successfuly Saved! "
                )
              ])
            : _vm._e(),
          _c("b-button", {
            staticClass: "ml-1 float-right",
            attrs: { disabled: _vm.savingColumnOrder, variant: "primary" },
            domProps: { textContent: _vm._s("OK") },
            on: {
              click: function($event) {
                return _vm.reload()
              }
            }
          })
        ],
        1
      ),
      _vm.isLoading
        ? _c(
            "div",
            { staticClass: "spinner-border ml-5", attrs: { role: "status" } },
            [_c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])]
          )
        : _vm.error
        ? _c(
            "b-alert",
            { attrs: { show: "", variant: "danger" } },
            [
              _vm.error === "Access denied."
                ? [
                    _vm._v(
                      " This report is unavailable at the moment. In order to access the data, please revisit site that contained link to this report or "
                    ),
                    _c("a", { attrs: { href: "mailto:blake@profound.net" } }, [
                      _vm._v("contact us")
                    ]),
                    _vm._v(". ")
                  ]
                : [_vm._v(" Unexpected server error occurred. ")]
            ],
            2
          )
        : _vm.showData
        ? _c(
            "b-tabs",
            {
              ref: "tabs",
              attrs: {
                "content-class":
                  "p-3 bg-light border-left border-right border-bottom rounded-bottom",
                "active-nav-item-class":
                  "bg-light border-left border-right border-top"
              },
              model: {
                value: _vm.tab,
                callback: function($$v) {
                  _vm.tab = $$v
                },
                expression: "tab"
              }
            },
            [
              _c(
                "b-tab",
                {
                  attrs: { active: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function() {
                        return [
                          _c(
                            "span",
                            { attrs: { "data-test": "groups-tab-title" } },
                            [_vm._v("Groups")]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c("FileColumns", {
                    ref: "columns",
                    attrs: { data: _vm.data },
                    on: {
                      groupLeftClicked: function($event) {
                        return _vm.loadGroupData(arguments[0], true)
                      },
                      groupMiddleClicked: function($event) {
                        return _vm.loadGroupData(arguments[0], false)
                      },
                      columnClicked: _vm.columnClicked,
                      groupTitleChanged: function($event) {
                        return _vm.groupTitleChanged.apply(void 0, arguments)
                      },
                      changeColumnOrder: function($event) {
                        return _vm.initializeChangeColumnOrder.apply(
                          void 0,
                          arguments
                        )
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "b-tab",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function() {
                        return [
                          _c(
                            "span",
                            {
                              attrs: { "data-test": "file-overview-tab-title" }
                            },
                            [_vm._v("File Overview")]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c("FileMetadata", {
                    ref: "metadata",
                    attrs: { "task-id": _vm.taskId, data: _vm.data },
                    on: {
                      columnClicked: _vm.columnClicked,
                      columnShown: _vm.columnShown,
                      columnHidden: _vm.columnHidden
                    }
                  })
                ],
                1
              ),
              _vm.deployAll || _vm.data.customReportMd
                ? _c(
                    "b-tab",
                    { attrs: { title: "Custom Report" } },
                    [
                      _c("CustomReport", {
                        attrs: { "init-report-code": _vm.data.customReportMd }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.deployAll
                ? _c(
                    "b-tab",
                    {
                      staticClass: "px-2 h-100 overflow-auto",
                      attrs: { title: "Visitor Statistics", lazy: "" }
                    },
                    [_c("VisitorStatistics")],
                    1
                  )
                : _vm._e(),
              _vm._l(_vm.visualizedGroups, function(group) {
                return _c(
                  "b-tab",
                  {
                    key: group.name,
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _vm._v(" Data: "),
                              _c(
                                "strong",
                                {
                                  attrs: {
                                    "data-test": "visualization-tab-title"
                                  },
                                  domProps: { textContent: _vm._s(group.title) }
                                },
                                [_vm._v(" " + _vm._s(group.title) + " ")]
                              ),
                              group.isLoading
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "spinner-border spinner-border-sm ml-2 mb-1",
                                      attrs: { role: "status" }
                                    },
                                    [
                                      _c("span", { staticClass: "sr-only" }, [
                                        _vm._v("Loading...")
                                      ])
                                    ]
                                  )
                                : _vm._e(),
                              _c("b-icon-x", {
                                on: {
                                  click: function($event) {
                                    return _vm.closeVisualization(group)
                                  }
                                }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [
                    group.data
                      ? _c("FileColumnMostCommonValues", {
                          ref: group.name + "MostCommon",
                          refInFor: true,
                          attrs: {
                            group: group,
                            "total-rows": _vm.totalRows()
                          },
                          on: {
                            groupTitleChanged: function($event) {
                              return _vm.groupTitleChanged(group)
                            },
                            changeColumnOrder: function($event) {
                              return _vm.initializeChangeColumnOrder.apply(
                                void 0,
                                arguments
                              )
                            }
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              })
            ],
            2
          )
        : _c(
            "section",
            [
              _c(
                "b-form",
                {
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.forceShowingReport($event)
                    }
                  }
                },
                [
                  _c(
                    "b-alert",
                    { attrs: { show: "", variant: "warning" } },
                    [
                      _c("h1", [
                        _vm._v("Configuration consistency problems detected")
                      ]),
                      _c(
                        "big",
                        [
                          _c("p", [
                            _vm._v(
                              " Analysis results are inconsistent with applied configuration. "
                            )
                          ]),
                          _vm.data.problems.multigroupColumns
                            ? [
                                _c("p", [
                                  _vm._v(
                                    "The following columns appear in multiple groups:"
                                  )
                                ]),
                                _c("p", [
                                  _c(
                                    "ul",
                                    _vm._l(
                                      _vm.data.problems.multigroupColumns,
                                      function(ref) {
                                        var columnName = ref[0]
                                        var groups = ref[1]
                                        return _c("li", { key: columnName }, [
                                          _vm._v(
                                            " " + _vm._s(columnName) + " "
                                          ),
                                          _c(
                                            "ul",
                                            _vm._l(groups, function(group) {
                                              return _c("li", { key: group }, [
                                                _vm._v(
                                                  " " + _vm._s(group) + " "
                                                )
                                              ])
                                            }),
                                            0
                                          )
                                        ])
                                      }
                                    ),
                                    0
                                  )
                                ])
                              ]
                            : _vm._e(),
                          _vm.data.problems.emptyGroups
                            ? [
                                _c("p", [
                                  _vm._v(
                                    " The following groups do not have any column found in the report: "
                                  )
                                ]),
                                _c("p", [
                                  _c(
                                    "ul",
                                    _vm._l(
                                      _vm.data.problems.emptyGroups,
                                      function(group) {
                                        return _c("li", { key: group.name }, [
                                          _vm._v(" " + _vm._s(group.name) + " ")
                                        ])
                                      }
                                    ),
                                    0
                                  )
                                ])
                              ]
                            : _vm._e(),
                          _vm.data.problems.undefinedGroups
                            ? [
                                _c("p", [
                                  _vm._v(
                                    " The following group names appear in groups ordering, but aren't defined in grouping setup: "
                                  )
                                ]),
                                _c("p", [
                                  _c(
                                    "ul",
                                    _vm._l(
                                      _vm.data.problems.undefinedGroups,
                                      function(groupName) {
                                        return _c("li", { key: groupName }, [
                                          _vm._v(" " + _vm._s(groupName) + " ")
                                        ])
                                      }
                                    ),
                                    0
                                  )
                                ])
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    [
                      _c(
                        "b-form-checkbox-group",
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              model: {
                                value: _vm.dontShowAgain,
                                callback: function($$v) {
                                  _vm.dontShowAgain = $$v
                                },
                                expression: "dontShowAgain"
                              }
                            },
                            [_vm._v(" Don't show this message again ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-button",
                    { attrs: { type: "submit", variant: "primary" } },
                    [_vm._v(" Show the report anyway ")]
                  )
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }