<template>
  <div>
    <b-form-group
      :label="`Correlation totals by ${correlations.label}`"
      label-cols="auto"
    >
      <b-form-select
        v-model="valueChoice"
        :options="valueOptions()"
      />
    </b-form-group>
    <table class="table table-sm table-striped table-hover">
      <thead>
        <tr>
          <th
            v-if="correlations.type === 'domain'"
            class="w-fit"
          >
            Icon
          </th>
          <th
            v-else-if="correlations.type === 'country'"
            class="w-fit"
          >
            Flag
          </th>
          <th>Value</th>
          <th class="text-right w-fit">
            Total
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-for="correlation in correlations.data">
          <tr
            v-if="correlation.correlationValue !== '<all>'"
            :key="correlation.correlationValue"
            class="pe-pointer"
            @click="correlationChosen(correlation)"
          >
            <td
              v-if="correlations.type === 'domain'"
              class="w-fit"
            >
              <img
                :src="`${backendUrl}/favicon/${correlation.correlationValue}`"
                width="20"
                height="20"
                onerror="this.classList.add('d-none')"
              >
            </td>
            <td v-else-if="correlations.type === 'country'">
              <span
                :class="`flag-icon flag-icon-${countryCode(correlation.correlationValue)} border`"
              />
            </td>
            <td
              v-if="correlations.type === 'url' && correlation.correlationValue"
              class="text-break"
            >
              <a
                :href="correlation.correlationValue"
                target="_blank"
              >{{ correlation.correlationValue }}</a>
            </td>
            <td
              v-else-if="correlation.correlationValue"
              class="text-break"
            >
              {{ correlation.correlationValue }}
            </td>
            <td
              v-else
              class="text-break"
            >
              <em>empty</em>
            </td>
            <td class="text-right w-fit">
              {{ humanize(total(correlation)) }}
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
  import Humanize from 'humanize-plus';
  export default {
    props: {correlations: {type: Object, default: null},},
    data: () => ({valueChoice: 0}),
    computed: {backendUrl: () => process.env.VUE_APP_VISUALIZER_BACKEND_URL},
    methods: {
      humanize: Humanize.intComma,
      valueOptions() {
        return [
          '<all>', ...new Set(
            this.correlations.data.map(
              correlation => correlation.correlation.map(
                entry => entry.value || '<empty>'
              )
            ).flat()
          )
        ].map((entry, index) => ({value: index, text: entry}));
      },
      countryCode(value) {
        if (!this.correlations.label.toLowerCase().includes('lang')) {
          return value.toLowerCase().substr(0, 2);
        }
        const split = value.toLowerCase().split('-');
        return split[split.length - 1];
      },
      parseIntValue(value) {
        if (value === 'inf') {
          return Infinity;
        }
        return parseInt(value);
      },
      correlationChosen(correlation) {
        this.$emit('correlationChosen', correlation.correlationValue);
      },
      total(correlation) {
        let valueChoice = this.valueOptions().find(
          entry => entry.value === this.valueChoice
        ).text;
        if (valueChoice === '<empty>') {
          valueChoice = '';
        }
        if (valueChoice === '<all>') {
          return correlation.correlationTotal;
        }
        return (
          correlation.correlation.find(
            entry => entry.value === valueChoice
          ) || {count: 0}
        ).count;
      }
    },
  };
</script>
