var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "groups-listing" }, [
    _c("div", { staticClass: "d-flex" }, [
      _c(
        "div",
        { staticClass: "flex-fill" },
        [
          _c("SearchBox", {
            ref: "searchBox",
            staticClass: "mb-3",
            attrs: {
              placeholder: "Search groups by name",
              data: _vm.groups,
              "features-to-query": ["title"],
              filtered: _vm.filteredGroups
            },
            on: {
              "update:filtered": function($event) {
                _vm.filteredGroups = $event
              }
            }
          })
        ],
        1
      ),
      _c(
        "button",
        {
          staticClass: "btn btn-sm btn-primary ml-2",
          staticStyle: { height: "37px" },
          on: {
            click: function($event) {
              return _vm.openAllGroupsInNewTabs()
            }
          }
        },
        [_vm._v(" Open All Groups in New Tabs ")]
      )
    ]),
    _vm.groupsToShow().length > 0
      ? _c(
          "div",
          { staticClass: "scroller-container" },
          _vm._l(_vm.groupsToShow(), function(item) {
            return _c(
              "div",
              {
                key: item.entry.name,
                on: {
                  click: function($event) {
                    return _vm.$emit("groupLeftClicked", item.entry)
                  },
                  mouseup: function($event) {
                    if ("button" in $event && $event.button !== 1) {
                      return null
                    }
                    return _vm.$emit("groupMiddleClicked", item.entry)
                  }
                }
              },
              [
                _c("GroupSummary", {
                  staticClass: "border-bottom",
                  attrs: {
                    "data-test": "group-summary",
                    "initial-title": item.queried.title,
                    group: item.entry,
                    "total-rows": _vm.totalRows()
                  },
                  on: {
                    columnClicked: _vm.columnClicked,
                    titleChanged: function($event) {
                      var i = arguments.length,
                        argsArray = Array(i)
                      while (i--) argsArray[i] = arguments[i]
                      return _vm.groupTitleChanged.apply(
                        void 0,
                        [item].concat(argsArray)
                      )
                    },
                    changeColumnOrder: function($event) {
                      return _vm.$emit("changeColumnOrder", item.entry)
                    }
                  }
                })
              ],
              1
            )
          }),
          0
        )
      : _c("em", { staticClass: "ml-5 mt-2" }, [
          _vm._v(" No group meets requested search query. ")
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }