var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "h-100" },
    [
      _c(
        "b-modal",
        {
          attrs: {
            visible: _vm.isSaving,
            size: "lg",
            centered: "",
            "hide-header": "",
            "hide-footer": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": ""
          }
        },
        [
          _c(
            "div",
            { staticClass: "d-flex justify-content-around my-2" },
            [
              _c("h3", { staticClass: "flex-fill" }, [_vm._v(" Saving... ")]),
              _c("b-spinner", { staticClass: "border-1" })
            ],
            1
          )
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "custom-report-save-failed",
            size: "lg",
            centered: "",
            "hide-header": "",
            "hide-footer": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": ""
          }
        },
        [
          _c("h3", [_vm._v("Error occurred while saving!")]),
          _c("div", { domProps: { textContent: _vm._s(_vm.error) } }),
          _c("b-button", {
            staticClass: "ml-1 float-right",
            attrs: { variant: "primary" },
            domProps: { textContent: _vm._s("OK") },
            on: {
              click: function($event) {
                return _vm.$bvModal.hide("custom-report-save-failed")
              }
            }
          })
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "custom-report-save-succeeded",
            size: "lg",
            centered: "",
            "hide-header": "",
            "hide-footer": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": ""
          }
        },
        [
          _c("h3", [_vm._v("Saved successfuly!")]),
          _c("b-button", {
            staticClass: "ml-1 float-right",
            attrs: { variant: "primary" },
            domProps: { textContent: _vm._s("OK") },
            on: {
              click: function($event) {
                return _vm.$bvModal.hide("custom-report-save-succeeded")
              }
            }
          })
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "image-failed",
            size: "lg",
            centered: "",
            "hide-header": "",
            "hide-footer": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": ""
          }
        },
        [
          _c("h3", [_vm._v("Error occurred while processing image!")]),
          _c("div", { domProps: { textContent: _vm._s(_vm.error) } }),
          _c("b-button", {
            staticClass: "ml-1 float-right",
            attrs: { variant: "primary" },
            domProps: { textContent: _vm._s("OK") },
            on: {
              click: function($event) {
                return _vm.$bvModal.hide("image-failed")
              }
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "h-100" },
        [
          _vm.deployAll && _vm.isEditing
            ? [
                _vm.isEditing
                  ? _c(
                      "div",
                      {
                        staticClass: "d-flex mb-1",
                        style: { height: "calc(100% - 190px)" }
                      },
                      [
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.localReportCode,
                              expression: "localReportCode"
                            },
                            {
                              name: "focus",
                              rawName: "v-focus",
                              value: _vm.isEditing,
                              expression: "isEditing"
                            }
                          ],
                          staticClass: "form-control",
                          style: { width: "50%", resize: "none" },
                          domProps: { value: _vm.localReportCode },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.localReportCode = $event.target.value
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "w-50 ml-2 p-2 border rounded-3 d-flex bg-light position-relative"
                          },
                          [
                            _c("div", {
                              staticClass:
                                "flex-fill text-justify overflow-auto mb-4",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.renderedMd(_vm.localReportCode)
                                )
                              }
                            }),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "text-muted mb-1 mr-2 position-absolute",
                                style: { bottom: 0, right: 0 }
                              },
                              [
                                _c("small", { staticClass: "mr-1" }, [
                                  _vm._v("Preview")
                                ]),
                                _c("b-icon", { attrs: { icon: "markdown" } })
                              ],
                              1
                            )
                          ]
                        )
                      ]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass:
                      "border rounded mb-1 px-1 d-flex position-relative",
                    style: { height: "150px" },
                    on: {
                      dragover: function($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.onDragOver($event)
                      },
                      dragleave: _vm.onDragLeave,
                      drop: function($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.onDrop($event)
                      }
                    }
                  },
                  [
                    _vm._l(_vm.uploadedImages, function(filename) {
                      return _c(
                        "div",
                        {
                          key: filename,
                          staticClass:
                            "my-2 mx-1 border rounded d-flex align-items-start flex-column text-truncate position-relative",
                          style: { width: "150px" },
                          attrs: { title: _vm.imageReference(filename) }
                        },
                        [
                          _c("img", {
                            staticClass: "rounded-top",
                            style: {
                              width: "152px",
                              height: "99px",
                              "object-fit": "cover"
                            },
                            attrs: { src: _vm.imageReference(filename) }
                          }),
                          _c("b-icon-x-circle-fill", {
                            staticClass:
                              "position-absolute text-danger rounded-circle bg-light",
                            style: {
                              right: "3px",
                              top: "3px",
                              cursor: "pointer"
                            },
                            attrs: { title: "Remove this image from server" },
                            on: {
                              click: function($event) {
                                return _vm.removeImage(filename)
                              }
                            }
                          }),
                          _c("div", { staticClass: "btn-group w-100" }, [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-sm btn-outline-secondary mt-auto",
                                style: {
                                  "border-top-left-radius": "0!important",
                                  "border-top-right-radius": "0!important"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.copyMd(filename)
                                  }
                                }
                              },
                              [_c("b-icon-markdown")],
                              1
                            ),
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-sm btn-outline-secondary mt-auto",
                                style: {
                                  "border-top-left-radius": "0!important",
                                  "border-top-right-radius": "0!important"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.copyHtml(filename)
                                  }
                                }
                              },
                              [_vm._v(" <img> ")]
                            )
                          ])
                        ],
                        1
                      )
                    }),
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex-fill mx-1 my-2 border rounded d-flex",
                        style: {
                          "border-width": "3px!important",
                          "border-color": "rgb(0, 0, 0, 0.2)!important",
                          "border-style": "dashed!important",
                          "border-radius": "15px!important"
                        }
                      },
                      [
                        _c("div", { staticClass: "flex-fill" }),
                        _c(
                          "em",
                          { staticClass: "my-auto text-muted" },
                          [
                            _vm.draggedOver
                              ? [
                                  _vm._v(" Drop to upload now "),
                                  _c("b-icon-upload")
                                ]
                              : [_vm._v("Drag your images here")]
                          ],
                          2
                        ),
                        _c("div", { staticClass: "flex-fill" })
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "position-absolute d-flex flex-column w-100",
                        style: {
                          height: "150px",
                          "background-color": "rgb(255, 255, 255, .3)",
                          visibility: _vm.processingImages
                            ? "visible"
                            : "hidden"
                        }
                      },
                      [
                        _c("div", { staticClass: "flex-fill" }),
                        _c(
                          "div",
                          { staticClass: "d-flex" },
                          [
                            _c("div", { staticClass: "flex-fill" }),
                            _c("b-spinner"),
                            _c("div", { staticClass: "flex-fill" })
                          ],
                          1
                        ),
                        _c("div", { staticClass: "flex-fill" })
                      ]
                    )
                  ],
                  2
                ),
                _c("div", { staticClass: "d-flex" }, [
                  _c("div", { staticClass: "flex-fill" }),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary d-flex",
                      attrs: { type: "submit", disabled: _vm.isSaving },
                      on: { click: _vm.save }
                    },
                    [
                      _vm._v(" Save "),
                      _vm.isSaving
                        ? _c("span", {
                            staticClass:
                              "spinner-border text-light ml-2 align-self-center"
                          })
                        : _vm._e()
                    ]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary ml-1",
                      attrs: { disabled: _vm.isSaving },
                      on: { click: _vm.cancelEditing }
                    },
                    [_vm._v(" Cancel ")]
                  )
                ])
              ]
            : _c(
                "a",
                {
                  class: {
                    "text-decoration-none": true,
                    "h-100": true,
                    clickable: _vm.deployAll
                  },
                  on: { click: _vm.edit }
                },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-dark text-justify text-decoration-none d-flex h-100 position-relative overflow-auto"
                    },
                    [
                      _c("span", {
                        class: {
                          "flex-fill": true,
                          "text-muted": !_vm.serverReportCode
                        },
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.renderedMd(
                              _vm.serverReportCode ||
                                "_No report introduced yet. Click here to start editing._"
                            )
                          )
                        }
                      }),
                      _c("span", { staticClass: "d-flex flex-column ml-2" }, [
                        _vm.deployAll
                          ? _c(
                              "a",
                              [_c("b-icon", { attrs: { icon: "pencil" } })],
                              1
                            )
                          : _vm._e()
                      ])
                    ]
                  )
                ]
              )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }