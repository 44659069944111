var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-input-group",
    {
      scopedSlots: _vm._u([
        {
          key: "prepend",
          fn: function() {
            return [_c("b-input-group-text", [_c("b-icon-search")], 1)]
          },
          proxy: true
        },
        {
          key: "append",
          fn: function() {
            return [
              _c(
                "b-input-group-text",
                [
                  _c("b-checkbox", {
                    on: { input: _vm.filter },
                    model: {
                      value: _vm.caseSensitive,
                      callback: function($$v) {
                        _vm.caseSensitive = $$v
                      },
                      expression: "caseSensitive"
                    }
                  }),
                  _vm._v(" Case Sensitive ")
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-form-input", {
        attrs: { placeholder: _vm.placeholder },
        on: { input: _vm.filter },
        model: {
          value: _vm.query,
          callback: function($$v) {
            _vm.query = $$v
          },
          expression: "query"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }