var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visits
    ? _c("table", { staticClass: "table table-sm table-hover header-fixed" }, [
        _vm._m(0),
        _c(
          "tbody",
          _vm._l(_vm.visits, function(visit) {
            return _c("tr", { key: visit.id }, [
              _c("td", { staticClass: "ip-col" }, [
                _vm._v(" " + _vm._s(visit.visitor.ip) + " ")
              ]),
              _c(
                "td",
                { staticClass: "check-col" },
                [
                  visit.visitor.rdns || visit.visitor.as
                    ? _c("b-icon-check", {
                        attrs: { title: _vm.asDescription(visit.visitor) }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c("td", { staticClass: "flag-col" }, [
                visit.visitor.cc
                  ? _c("span", {
                      class:
                        "flag-icon flag-icon-" +
                        visit.visitor.cc.toLowerCase() +
                        " border mr-1"
                    })
                  : _vm._e()
              ]),
              _c("td", { staticClass: "country-col" }, [
                _vm._v(" " + _vm._s(visit.visitor.country) + " ")
              ]),
              _c(
                "td",
                { staticClass: "text-col" },
                [
                  _vm._v(" " + _vm._s(visit.userAgent.browser.family) + " "),
                  visit.userAgent.browser.version
                    ? [
                        _vm._v(
                          " (v" + _vm._s(visit.userAgent.browser.version) + ") "
                        )
                      ]
                    : _vm._e()
                ],
                2
              ),
              _c(
                "td",
                { staticClass: "text-col" },
                [
                  _vm._v(
                    " " + _vm._s(visit.userAgent.operatingSystem.family) + " "
                  ),
                  visit.userAgent.operatingSystem.version
                    ? [
                        _vm._v(
                          " (v" +
                            _vm._s(visit.userAgent.operatingSystem.version) +
                            ") "
                        )
                      ]
                    : _vm._e()
                ],
                2
              ),
              _c(
                "td",
                { staticClass: "check-col" },
                [visit.userAgent.isMobile ? _c("b-icon-check") : _vm._e()],
                1
              ),
              _c(
                "td",
                { staticClass: "check-col" },
                [visit.userAgent.isTablet ? _c("b-icon-check") : _vm._e()],
                1
              ),
              _c(
                "td",
                { staticClass: "check-col" },
                [
                  visit.userAgent.isTouchCapable ? _c("b-icon-check") : _vm._e()
                ],
                1
              ),
              _c(
                "td",
                { staticClass: "check-col" },
                [visit.userAgent.isPc ? _c("b-icon-check") : _vm._e()],
                1
              ),
              _c(
                "td",
                { staticClass: "check-col" },
                [visit.userAgent.isBot ? _c("b-icon-check") : _vm._e()],
                1
              ),
              _c("td", { staticClass: "timestamp-col" }, [
                _vm._v(
                  " " +
                    _vm._s(visit.timestamp.date) +
                    " " +
                    _vm._s(visit.timestamp.time) +
                    " "
                )
              ])
            ])
          }),
          0
        )
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "ip-col" }, [_vm._v(" IPv4 ")]),
        _c("th", { staticClass: "check-col" }, [_vm._v(" AS ")]),
        _c("th", { staticClass: "text-col", attrs: { colspan: "2" } }, [
          _vm._v(" Country ")
        ]),
        _c("th", { staticClass: "text-col" }, [_vm._v(" Browser ")]),
        _c("th", { staticClass: "text-col" }, [_vm._v(" OS ")]),
        _c("th", { staticClass: "check-col" }, [_vm._v(" Mobile ")]),
        _c("th", { staticClass: "check-col" }, [_vm._v(" Tablet ")]),
        _c("th", { staticClass: "check-col" }, [_vm._v(" Touch ")]),
        _c("th", { staticClass: "check-col" }, [_vm._v(" PC ")]),
        _c("th", { staticClass: "check-col" }, [_vm._v(" Bot ")]),
        _c("th", { staticClass: "timestamp-col" }, [_vm._v(" Timestamp ")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }