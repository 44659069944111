var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "visualizations-tabs-container" },
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "image-failed",
            size: "lg",
            centered: "",
            "hide-header": "",
            "hide-footer": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": ""
          }
        },
        [
          _c("h3", [_vm._v("Error occurred while processing image!")]),
          _c("div", { domProps: { textContent: _vm._s(_vm.imageError) } }),
          _c("b-button", {
            staticClass: "ml-1 float-right",
            attrs: { variant: "primary" },
            domProps: { textContent: _vm._s("OK") },
            on: {
              click: function($event) {
                return _vm.$bvModal.hide("image-failed")
              }
            }
          })
        ],
        1
      ),
      _c(
        "b-card",
        {
          staticClass: "visualizations-tabs-card pb-2",
          attrs: { "no-body": "" }
        },
        [
          _c(
            "b-tabs",
            {
              staticClass: "visualizations-tabs",
              attrs: { card: "", lazy: "" },
              model: {
                value: _vm.activeTab,
                callback: function($$v) {
                  _vm.activeTab = $$v
                },
                expression: "activeTab"
              }
            },
            [
              _c("GroupSummary", {
                staticClass: "mx-3",
                attrs: {
                  "data-test": "visualization-summary",
                  "initial-title": _vm.group.title,
                  group: _vm.group,
                  "total-rows": _vm.totalRows
                },
                on: {
                  titleChanged: function($event) {
                    var i = arguments.length,
                      argsArray = Array(i)
                    while (i--) argsArray[i] = arguments[i]
                    return _vm.groupTitleChanged.apply(
                      void 0,
                      [_vm.group].concat(argsArray)
                    )
                  },
                  columnClicked: _vm.columnClicked,
                  changeColumnOrder: function($event) {
                    return _vm.$emit("changeColumnOrder", _vm.group)
                  }
                }
              }),
              _c(
                "div",
                { staticClass: "ml-5" },
                [
                  _vm._l(_vm.columnsWithDefinitions(), function(ref) {
                    var label = ref[0]
                    var definition = ref[1]
                    return _c("div", { key: label }, [
                      _c("h5", [_vm._v(_vm._s(label))]),
                      _c("div", {
                        staticClass: "text-muted mr-4 text-justify",
                        domProps: { innerHTML: _vm._s(definition) }
                      })
                    ])
                  }),
                  _c("div", { staticClass: "my-3 row" })
                ],
                2
              ),
              _vm._l(_vm.attachedImages, function(attachedImage) {
                return _c("img", {
                  key: attachedImage,
                  staticClass: "mx-5 my-2",
                  attrs: { src: _vm.imageReference(attachedImage) }
                })
              }),
              _vm.deployAll
                ? _c("div", { style: { display: "block" } }, [
                    _c(
                      "div",
                      {
                        staticClass: "border rounded mb-1 px-1 ml-2",
                        style: {
                          height: "150px",
                          position: "absolute",
                          width: "calc(100% - 20px)"
                        },
                        on: {
                          dragover: function($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.onDragOver($event)
                          },
                          dragleave: _vm.onDragLeave,
                          drop: function($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.onDrop($event)
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "d-flex w-100" },
                          [
                            _vm._l(_vm.uploadedImages, function(filename) {
                              return _c(
                                "div",
                                {
                                  key: filename,
                                  staticClass:
                                    "my-2 mx-1 border rounded d-flex align-items-start flex-column text-truncate position-relative",
                                  style: { width: "150px" },
                                  attrs: { title: _vm.imageReference(filename) }
                                },
                                [
                                  _c("img", {
                                    staticClass: "rounded-top",
                                    style: {
                                      width: "152px",
                                      height: "99px",
                                      "object-fit": "cover"
                                    },
                                    attrs: { src: _vm.imageReference(filename) }
                                  }),
                                  _c("b-icon-x-circle-fill", {
                                    staticClass:
                                      "position-absolute text-danger rounded-circle bg-light",
                                    style: {
                                      right: "3px",
                                      top: "3px",
                                      cursor: "pointer"
                                    },
                                    attrs: {
                                      title: "Remove this image from server"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.removeImage(filename)
                                      }
                                    }
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "input-group d-flex",
                                      style: { height: "31px" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "input-group-text flex-fill py-0",
                                          style: {
                                            "border-top-left-radius":
                                              "0!important",
                                            "border-top-right-radius":
                                              "0!important"
                                          }
                                        },
                                        [
                                          _c("div", {
                                            staticClass: "flex-fill"
                                          }),
                                          _c(
                                            "label",
                                            { staticClass: "form-check-label" },
                                            [_vm._v(" Attach ")]
                                          ),
                                          _c("input", {
                                            ref: "attach-check-" + filename,
                                            refInFor: true,
                                            staticClass: "ml-2",
                                            attrs: { type: "checkbox" },
                                            on: {
                                              input: function($event) {
                                                return _vm.saveImageAttachmentStatus(
                                                  filename,
                                                  $event.target
                                                )
                                              }
                                            }
                                          }),
                                          _c("div", {
                                            staticClass: "flex-fill"
                                          })
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            }),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex-fill mx-1 my-2 border rounded d-flex",
                                style: {
                                  "border-width": "3px!important",
                                  "border-color": "rgb(0, 0, 0, 0.2)!important",
                                  "border-style": "dashed!important",
                                  "border-radius": "15px!important",
                                  height: "132px"
                                }
                              },
                              [
                                _c("div", { staticClass: "flex-fill" }),
                                _c(
                                  "em",
                                  { staticClass: "my-auto text-muted" },
                                  [
                                    _vm.draggedOver
                                      ? [
                                          _vm._v(" Drop to upload now "),
                                          _c("b-icon-upload")
                                        ]
                                      : [_vm._v("Drag your images here")]
                                  ],
                                  2
                                ),
                                _c("div", { staticClass: "flex-fill" })
                              ]
                            )
                          ],
                          2
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "position-relative d-flex flex-column",
                        style: {
                          height: "150px",
                          "background-color": "rgb(255, 255, 255, .3)",
                          visibility: _vm.processingImages
                            ? "visible"
                            : "hidden"
                        }
                      },
                      [
                        _c("div", { staticClass: "flex-fill" }),
                        _c(
                          "div",
                          { staticClass: "d-flex" },
                          [
                            _c("div", { staticClass: "flex-fill" }),
                            _c("b-spinner"),
                            _c("div", { staticClass: "flex-fill" })
                          ],
                          1
                        ),
                        _c("div", { staticClass: "flex-fill" })
                      ]
                    )
                  ])
                : _vm._e(),
              _c(
                "div",
                { ref: "visualizations" },
                [
                  _c(
                    "b-tab",
                    { attrs: { title: "Table" } },
                    [
                      _c(
                        "b-tabs",
                        {
                          staticClass: "visualization-tabs",
                          attrs: { pills: "", lazy: "", align: "right" },
                          model: {
                            value: _vm.activeColumn,
                            callback: function($$v) {
                              _vm.activeColumn = $$v
                            },
                            expression: "activeColumn"
                          }
                        },
                        _vm._l(_vm.allColumns(), function(ref) {
                          var columnName = ref[0]
                          var column = ref[1]
                          return _c(
                            "b-tab",
                            { key: columnName, attrs: { title: column.label } },
                            [
                              _vm.correlationValues[columnName].length > 1
                                ? _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label:
                                          "Correlated with " +
                                          _vm.correlations[columnName].key,
                                        "label-cols": "auto"
                                      }
                                    },
                                    [
                                      _c("b-form-select", {
                                        attrs: {
                                          options:
                                            _vm.correlationValues[columnName]
                                        },
                                        model: {
                                          value:
                                            _vm.correlationValue[columnName],
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.correlationValue,
                                              columnName,
                                              $$v
                                            )
                                          },
                                          expression:
                                            "correlationValue[columnName]"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c("FileColumnMostCommonValuesTable", {
                                attrs: {
                                  label: column.label,
                                  type: column.type,
                                  "most-common-values-checked":
                                    _vm.mostCommonValuesChecked[columnName],
                                  "most-common-values": _vm.allMostCommonValuesToShowBy(
                                    columnName
                                  ),
                                  ranges: _vm.group.data[columnName].ranges,
                                  "value-tags":
                                    _vm.group.data[columnName].valueTags
                                },
                                on: {
                                  checkedMostCommonValuesChanged: function(
                                    $event
                                  ) {
                                    _vm
                                      .checkedMostCommonValuesChanged(
                                        columnName
                                      )
                                      .apply(void 0, arguments)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm.nonKeyColumns().length > 0
                    ? _c(
                        "b-tab",
                        { attrs: { title: "Bar Chart" } },
                        [
                          _vm.group.features.multiFeatureBarChart
                            ? _c("FileColumnMostCommonValuesGroupBarChart", {
                                attrs: {
                                  group: _vm.filteredMostCommonValuesToShow()
                                }
                              })
                            : _c(
                                "b-tabs",
                                {
                                  staticClass: "visualization-tabs",
                                  attrs: {
                                    pills: "",
                                    lazy: "",
                                    align: "right"
                                  },
                                  model: {
                                    value: _vm.activeColumn,
                                    callback: function($$v) {
                                      _vm.activeColumn = $$v
                                    },
                                    expression: "activeColumn"
                                  }
                                },
                                _vm._l(_vm.allColumns(), function(ref) {
                                  var columnName = ref[0]
                                  var column = ref[1]
                                  return _c(
                                    "b-tab",
                                    {
                                      key: columnName,
                                      attrs: {
                                        title: column.label,
                                        disabled: !_vm
                                          .nonKeyColumns()
                                          .includes(columnName)
                                      }
                                    },
                                    [
                                      _vm.correlationValues[columnName].length >
                                      1
                                        ? _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label:
                                                  "Correlated with " +
                                                  _vm.correlations[columnName]
                                                    .key,
                                                "label-cols": "auto"
                                              }
                                            },
                                            [
                                              _c("b-form-select", {
                                                attrs: {
                                                  options:
                                                    _vm.correlationValues[
                                                      columnName
                                                    ]
                                                },
                                                model: {
                                                  value:
                                                    _vm.correlationValue[
                                                      columnName
                                                    ],
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.correlationValue,
                                                      columnName,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "correlationValue[columnName]"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c("FileColumnMostCommonValuesBarChart", {
                                        attrs: {
                                          type: column.type,
                                          "most-common-values": _vm.mostCommonValuesToShowBy(
                                            columnName
                                          ),
                                          ranges:
                                            _vm.group.data[columnName].ranges
                                        }
                                      })
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.nonKeyColumns().length > 0
                    ? _c(
                        "b-tab",
                        { attrs: { title: "Pie Chart" } },
                        [
                          _c(
                            "b-tabs",
                            {
                              staticClass: "visualization-tabs",
                              attrs: { pills: "", lazy: "", align: "right" },
                              model: {
                                value: _vm.activeColumn,
                                callback: function($$v) {
                                  _vm.activeColumn = $$v
                                },
                                expression: "activeColumn"
                              }
                            },
                            _vm._l(_vm.allColumns(), function(ref) {
                              var columnName = ref[0]
                              var column = ref[1]
                              return _c(
                                "b-tab",
                                {
                                  key: columnName,
                                  attrs: {
                                    title: column.label,
                                    disabled: !_vm
                                      .nonKeyColumns()
                                      .includes(columnName)
                                  }
                                },
                                [
                                  _vm.correlationValues[columnName].length > 1
                                    ? _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label:
                                              "Correlated with " +
                                              _vm.correlations[columnName].key,
                                            "label-cols": "auto"
                                          }
                                        },
                                        [
                                          _c("b-form-select", {
                                            attrs: {
                                              options:
                                                _vm.correlationValues[
                                                  columnName
                                                ]
                                            },
                                            model: {
                                              value:
                                                _vm.correlationValue[
                                                  columnName
                                                ],
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.correlationValue,
                                                  columnName,
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "correlationValue[columnName]"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c("FileColumnMostCommonValuesPieChart", {
                                    attrs: {
                                      label: column.label,
                                      type: column.type,
                                      "total-count": column.numValues,
                                      "most-common-values": _vm.allMostCommonValuesToShowBy(
                                        columnName
                                      ),
                                      ranges: _vm.group.data[columnName].ranges
                                    }
                                  })
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.mapColumns().length > 0
                    ? _c(
                        "b-tab",
                        { attrs: { title: "Map" } },
                        [
                          _c(
                            "b-tabs",
                            {
                              staticClass: "visualization-tabs",
                              attrs: { pills: "", lazy: "", align: "right" },
                              model: {
                                value: _vm.activeColumn,
                                callback: function($$v) {
                                  _vm.activeColumn = $$v
                                },
                                expression: "activeColumn"
                              }
                            },
                            _vm._l(_vm.allColumns(), function(ref) {
                              var columnName = ref[0]
                              var column = ref[1]
                              return _c(
                                "b-tab",
                                {
                                  key: columnName,
                                  attrs: {
                                    title: column.label,
                                    disabled: !_vm
                                      .mapColumns()
                                      .includes(columnName)
                                  }
                                },
                                [
                                  _vm.correlationValues[columnName].length > 1
                                    ? _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label:
                                              "Correlated with " +
                                              _vm.correlations[columnName].key,
                                            "label-cols": "auto"
                                          }
                                        },
                                        [
                                          _c("b-form-select", {
                                            attrs: {
                                              options:
                                                _vm.correlationValues[
                                                  columnName
                                                ]
                                            },
                                            model: {
                                              value:
                                                _vm.correlationValue[
                                                  columnName
                                                ],
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.correlationValue,
                                                  columnName,
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "correlationValue[columnName]"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  column.type === "ip"
                                    ? _c("FileColumnMostCommonValuesIpMap", {
                                        attrs: {
                                          "most-common-values": _vm.allMostCommonValuesToShowBy(
                                            columnName
                                          )
                                        }
                                      })
                                    : column.type === "country"
                                    ? _c(
                                        "FileColumnMostCommonValuesCountryMap",
                                        {
                                          attrs: {
                                            "most-common-values": _vm.allMostCommonValuesToShowBy(
                                              columnName
                                            ),
                                            label: column.label
                                          }
                                        }
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.hilbertMapColumns().length > 0
                    ? _c(
                        "b-tab",
                        { attrs: { title: "Hilbert Map" } },
                        [
                          _c(
                            "b-tabs",
                            {
                              staticClass: "visualization-tabs",
                              attrs: { pills: "", lazy: "", align: "right" },
                              model: {
                                value: _vm.activeColumn,
                                callback: function($$v) {
                                  _vm.activeColumn = $$v
                                },
                                expression: "activeColumn"
                              }
                            },
                            _vm._l(_vm.allColumns(), function(ref) {
                              var columnName = ref[0]
                              var column = ref[1]
                              return _c(
                                "b-tab",
                                {
                                  key: columnName,
                                  attrs: {
                                    title: column.label,
                                    disabled: !_vm
                                      .hilbertMapColumns()
                                      .includes(columnName)
                                  }
                                },
                                [
                                  _vm.correlationValues[columnName].length > 1
                                    ? _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label:
                                              "Correlated with " +
                                              _vm.correlations[columnName].key,
                                            "label-cols": "auto"
                                          }
                                        },
                                        [
                                          _c("b-form-select", {
                                            attrs: {
                                              options:
                                                _vm.correlationValues[
                                                  columnName
                                                ]
                                            },
                                            model: {
                                              value:
                                                _vm.correlationValue[
                                                  columnName
                                                ],
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.correlationValue,
                                                  columnName,
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "correlationValue[columnName]"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c("FileColumnMostCommonValuesHilbert", {
                                    attrs: { "column-name": columnName }
                                  })
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.hasCorrelations
                    ? _c(
                        "b-tab",
                        { attrs: { title: "Correlation Table" } },
                        [
                          _c(
                            "b-tabs",
                            {
                              staticClass: "visualization-tabs",
                              attrs: { pills: "", lazy: "", align: "right" },
                              model: {
                                value: _vm.activeColumn,
                                callback: function($$v) {
                                  _vm.activeColumn = $$v
                                },
                                expression: "activeColumn"
                              }
                            },
                            _vm._l(_vm.correlationsArray, function(ref) {
                              var columnName = ref[0]
                              var correlation = ref[1]
                              return _c(
                                "b-tab",
                                {
                                  key: columnName,
                                  attrs: {
                                    title: correlation.label,
                                    disabled: !_vm.isCorrelationEnabled(
                                      correlation
                                    )
                                  }
                                },
                                [
                                  _c(
                                    "FileColumnMostCommonValuesCorrelationTable",
                                    {
                                      attrs: { correlations: correlation },
                                      on: {
                                        correlationChosen: function($event) {
                                          var i = arguments.length,
                                            argsArray = Array(i)
                                          while (i--)
                                            argsArray[i] = arguments[i]
                                          return _vm.changeCorrelationChoice.apply(
                                            void 0,
                                            [columnName].concat(argsArray)
                                          )
                                        }
                                      }
                                    }
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.hasMapCorrelations
                    ? _c(
                        "b-tab",
                        { attrs: { title: "Correlation Map" } },
                        [
                          _c(
                            "b-tabs",
                            {
                              staticClass: "visualization-tabs",
                              attrs: { pills: "", lazy: "", align: "right" },
                              model: {
                                value: _vm.activeColumn,
                                callback: function($$v) {
                                  _vm.activeColumn = $$v
                                },
                                expression: "activeColumn"
                              }
                            },
                            _vm._l(_vm.correlationsArray, function(ref) {
                              var columnName = ref[0]
                              var correlation = ref[1]
                              return _c(
                                "b-tab",
                                {
                                  key: columnName,
                                  attrs: {
                                    title: correlation.label,
                                    disabled: !_vm.isCorrelationEnabled(
                                      correlation
                                    )
                                  }
                                },
                                [
                                  _c(
                                    "FileColumnMostCommonValuesCorrelationCountryMap",
                                    {
                                      attrs: { correlations: correlation },
                                      on: {
                                        countryClicked: function($event) {
                                          var i = arguments.length,
                                            argsArray = Array(i)
                                          while (i--)
                                            argsArray[i] = arguments[i]
                                          return _vm.changeCorrelationChoice.apply(
                                            void 0,
                                            [columnName].concat(argsArray)
                                          )
                                        }
                                      }
                                    }
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.hasTimelineCorrelations
                    ? _c(
                        "b-tab",
                        { attrs: { title: "Timeline" } },
                        [
                          _c(
                            "b-tabs",
                            {
                              staticClass: "visualization-tabs",
                              attrs: { pills: "", lazy: "", align: "right" },
                              model: {
                                value: _vm.activeColumn,
                                callback: function($$v) {
                                  _vm.activeColumn = $$v
                                },
                                expression: "activeColumn"
                              }
                            },
                            _vm._l(_vm.timelineCorrelationsArray, function(
                              ref
                            ) {
                              var columnName = ref[0]
                              var correlation = ref[1]
                              return _c(
                                "b-tab",
                                {
                                  key: columnName,
                                  attrs: {
                                    title: correlation.label,
                                    disabled: !_vm.isCorrelationEnabled(
                                      correlation
                                    )
                                  }
                                },
                                [
                                  _c(
                                    "FileColumnMostCommonValuesCorrelationLineChart",
                                    {
                                      attrs: {
                                        correlations: _vm.filteredCorrelation(
                                          columnName,
                                          correlation
                                        )
                                      }
                                    }
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }