<template>
  <section>
    <b-modal
      :visible="refreshCacheState === 'ongoing'"
      size="lg"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
    >
      <div class="d-flex justify-content-around my-2">
        <h3 class="flex-fill">
          Refreshing cache...
        </h3>
        <b-spinner class="border-1" />
      </div>
    </b-modal>
    <b-modal
      :visible="refreshCacheState === 'done'"
      size="lg"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
    >
      <h3>Cache refreshed successfuly!</h3>
      <b-button
        class="ml-1 float-right"
        variant="primary"
        @click="reload()"
        v-text="`OK`"
      />
    </b-modal>
    <b-modal
      :visible="refreshCacheState === 'error'"
      size="lg"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
    >
      <template #default="{ hide }">
        <h3>Refreshing cache failed</h3>
        <p>Error occurred while refreshing cache.</p>
        <p
          v-if="refreshCacheError"
          class="text-danger"
          v-text="refreshCacheError"
        />
        <b-button
          class="ml-1 float-right"
          variant="primary"
          @click="hide()"
          v-text="`OK`"
        />
      </template>
    </b-modal>
    <b-modal
      id="save-as-preset"
      size="lg"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
    >
      <template #default="{ hide }">
        <h3>Save Current Report Setup as a Preset</h3>
        <b-form-radio-group
          v-model="chosenPreset"
          class="mb-2"
          style="max-height: 500px"
          stacked
        >
          <div
            class="d-flex border rounded m-1 p-1"
            :title="preset ? null : `Couldn't locate preset file.`"
          >
            <b-form-radio
              value="overwrite"
              :disabled="savingPreset || !preset"
            >
              Overwrite Used Preset
              <strong v-if="preset">
                {{ preset.name }}
                <em class="text-muted"> ({{ preset.filename }}) </em>
              </strong>
            </b-form-radio>
          </div>
          <div class="border rounded m-1 p-1">
            <b-form-radio
              value="new"
              :disabled="savingPreset"
            >
              Create New Preset:
            </b-form-radio>
            <table>
              <tr>
                <th>Filename</th>
                <td class="pl-2 pb-1">
                  <b-input
                    v-model="newPresetToCreate.filename"
                    :disabled="savingPreset"
                    :placeholder="
                      preset ? `e.g. '${preset.key}_forked.json'` : ''
                    "
                    size="sm"
                    @click="chosenPreset = 'new'"
                  />
                </td>
              </tr>
              <tr>
                <th>Name</th>
                <td class="pl-2">
                  <b-input
                    v-model="newPresetToCreate.name"
                    :disabled="savingPreset"
                    :placeholder="preset ? `e.g. '${preset.name}_forked'` : ''"
                    size="sm"
                    @click="chosenPreset = 'new'"
                  />
                </td>
              </tr>
            </table>
          </div>
        </b-form-radio-group>
        <b-alert
          v-if="presetSaveErrors"
          show
          variant="danger"
        >
          <div
            v-for="presetSaveError in presetSaveErrors"
            :key="presetSaveError"
            v-text="presetSaveError"
          />
        </b-alert>
        <b-alert
          v-if="presetSaveWarnings"
          show
          variant="warning"
        >
          <div
            v-for="presetSaveWarning in presetSaveWarnings"
            :key="presetSaveWarning"
            v-text="presetSaveWarning"
          />
        </b-alert>
        <b-button
          class="ml-1"
          variant="primary"
          :disabled="savingPreset"
          data-test="save-as-preset-submit"
          @click="savePreset()"
        >
          <template v-if="savingPreset">
            Saving... <b-spinner small />
          </template>
          <template v-else>
            Save
          </template>
        </b-button>
        <b-button
          :disabled="savingPreset"
          class="ml-1"
          variant="secondary"
          @click="hide()"
          v-text="`Cancel`"
        />
      </template>
    </b-modal>
    <b-modal
      id="preset-successfuly-saved-message"
      size="lg"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
    >
      <h3>Preset Saved Successfuly!</h3>
      <b-button
        class="ml-1 float-right"
        variant="primary"
        @click="reload()"
        v-text="`OK`"
      />
    </b-modal>
    <b-modal
      id="load-current-preset"
      size="lg"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
    >
      <template #default="{ hide }">
        <h3>Load Current Preset as a Layout for this Report</h3>
        <b-alert
          show
          variant="warning"
        >
          <strong>
            <u>WARNING:</u> This action results with overriding all
            changes made to current layout.
          </strong>
        </b-alert>
        <div class="lead mb-4">
          Are you sure you want to load current preset
          and entirely override your current layout of this report?
        </div>
        <b-button
          class="ml-1"
          variant="primary"
          :disabled="loadingPreset"
          @click="loadPreset()"
        >
          <template v-if="loadingPreset">
            Loading... <b-spinner small />
          </template>
          <template v-else>
            Load
          </template>
        </b-button>
        <b-button
          :disabled="loadingPreset"
          class="ml-1"
          variant="secondary"
          @click="hide()"
          v-text="`Cancel`"
        />
      </template>
    </b-modal>
    <b-modal
      id="preset-successfuly-loaded-message"
      size="lg"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
    >
      <h3>Preset Loaded Successfuly!</h3>
      <b-button
        class="ml-1 float-right"
        variant="primary"
        @click="reload()"
        v-text="`OK`"
      />
    </b-modal>
    <b-modal
      id="preset-failed-to-load-message"
      size="lg"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
    >
      <template #default="{ hide }">
        <h3>Preset Loading Failure</h3>
        <div class="lead">
          {{ presetLoadErrors[0] }}
        </div>
        <b-button
          class="ml-1 float-right"
          variant="primary"
          @click="hide()"
          v-text="`OK`"
        />
      </template>
    </b-modal>
    <b-modal
      id="select-new-group"
      size="lg"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
    >
      <template #default="{ hide }">
        <h3>Select New Group for Selected Column</h3>
        <b-form-radio-group
          v-model="chosenGroup"
          class="overflow-auto px-2 mb-2"
          style="max-height: 500px"
          stacked
        >
          <div>
            <div class="d-flex my-2">
              <b-form-radio
                value=""
                :disabled="savingGroupChange"
                class="my-2"
              >
                <strong
                  class="text-nowrap"
                  v-text="`Create New Group:`"
                />
              </b-form-radio>
              <b-input
                v-model="newGroupToCreate"
                placeholder="Group Title"
                small
                class="ml-2"
                @click="chosenGroup = ''"
              />
            </div>
          </div>
          <div
            v-for="option in allGroups()"
            :key="option.name"
          >
            <div class="d-flex">
              <b-form-radio
                :value="option.name"
                class="flex-fill"
                :disabled="savingGroupChange"
                :data-test="`column-group-choice-${option.name}`"
              >
                <strong v-text="option.title" />
              </b-form-radio>
              <div v-b-toggle="`group-columns-${option.name}`">
                <span class="mr-2"> {{ option.columns.length }} Columns </span>
                <b-icon-chevron-down />
              </div>
            </div>
            <b-collapse :id="`group-columns-${option.name}`">
              <ul>
                <li
                  v-for="column in option.columns"
                  :key="column.name"
                  v-text="column.title"
                />
              </ul>
            </b-collapse>
          </div>
        </b-form-radio-group>
        <div
          v-if="changeGroupError"
          class="text-danger ml-2 my-2"
        >
          {{ changeGroupError }}
        </div>
        <b-button
          class="ml-1"
          variant="primary"
          :disabled="savingGroupChange"
          data-test="save-group-change"
          @click="saveGroupChange()"
        >
          <template v-if="savingGroupChange">
            Saving... <b-spinner small />
          </template>
          <template v-else>
            Save
          </template>
        </b-button>
        <b-button
          :disabled="savingGroupChange"
          class="ml-1"
          variant="secondary"
          @click="hide()"
          v-text="`Cancel`"
        />
      </template>
    </b-modal>
    <b-modal
      id="column-successfuly-reassigned-message"
      size="lg"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
    >
      <h3>Group Assigned Successfuly!</h3>
      <div>
        Selected columns has been successfuly assigned to
        <strong v-text="chosenGroup" /> group.
      </div>
      <b-button
        :disabled="savingGroupChange"
        class="ml-1 float-right"
        variant="primary"
        @click="reload()"
        v-text="`OK`"
      />
    </b-modal>
    <b-modal
      id="change-group-order"
      size="lg"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
    >
      <template #default="{ hide }">
        <h3>Change Order of Groups</h3>
        <draggable
          v-model="orderedGroups"
          class="overflow-auto p-2 m-2"
          style="max-height: 500px"
        >
          <div
            v-for="group in orderedGroups"
            :key="group.name"
            :class="{
              'bg-light': savingGroupOrder,
              'text-muted': isGroupHidden(group.columns),
              border: true,
              'my-1': true,
              'p-1': true,
              draggable: true,
            }"
            :data-test="`group-reorderer-${group.name}`"
            @dragstart="dragStart"
            @dragend="dragEnd"
          >
            <div class="d-flex">
              <div class="flex-fill">
                <strong class="mr-2"><b-icon-grip-vertical /> {{ group.title }}</strong>
                <small
                  v-if="isGroupHidden(group.columns)"
                  class="text-muted font-italic"
                >(group hidden)</small>
                <small
                  v-else-if="isGroupIndeterminate(group.columns)"
                  class="text-muted font-italic"
                >(group partially hidden)</small>
              </div>
              <div v-b-toggle="`group-order-columns-${group.name}`">
                <span class="mr-2"> {{ group.columns.length }} Columns </span>
                <b-icon-chevron-down />
              </div>
            </div>
            <b-collapse :id="`group-order-columns-${group.name}`">
              <ul>
                <li
                  v-for="column in group.columns"
                  :key="column.name"
                  :class="{'text-muted': isColumnHidden(column.name)}"
                >
                  {{ column.title }}
                  <small
                    v-if="isColumnHidden(column.name)"
                    class="font-italic"
                    v-text="`(column hidden)`"
                  />
                </li>
              </ul>
            </b-collapse>
          </div>
        </draggable>
        <div
          v-if="orderGroupError"
          class="text-danger ml-2 my-2"
        >
          {{ orderGroupError }}
        </div>
        <b-button
          class="ml-1"
          variant="primary"
          :disabled="savingGroupOrder"
          data-test="save-group-order"
          @click="saveGroupOrder()"
        >
          <template v-if="savingGroupOrder">
            Saving... <b-spinner small />
          </template>
          <template v-else>
            Save
          </template>
        </b-button>
        <b-button
          :disabled="savingGroupOrder"
          class="ml-1"
          variant="secondary"
          @click="hide()"
          v-text="`Cancel`"
        />
      </template>
    </b-modal>
    <b-modal
      id="group-order-successfuly-saved-message"
      size="lg"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
    >
      <h3>Group Order Successfuly Saved!</h3>
      <b-button
        :disabled="savingGroupOrder"
        class="ml-1 float-right"
        variant="primary"
        @click="reload()"
        v-text="`OK`"
      />
    </b-modal>
    <b-modal
      id="column-row-count"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
    >
      <template #default="{ hide }">
        <h3>Full Column Row Count</h3>
        <table
          class="table table-sm table-borderless table-responsive mb-1"
          style="max-height: 500px"
        >
          <tbody>
            <tr
              v-for="histogramEntry in histogram"
              :key="histogramEntry.columnCount"
              :class="`text-${
                isHistogramEntryFailure(histogramEntry) ? 'danger' : 'success'
              }`"
            >
              <th class="pl-0 pt-0">
                {{ histogramEntry.columnCount }}:
              </th>
              <td class="pl-0 pt-0">
                <strong v-if="!isHistogramEntryFailure(histogramEntry)">
                  {{ histogramEntry.rowCount }}
                </strong>
                <template v-else>
                  {{ histogramEntry.rowCount }}
                </template>
              </td>
            </tr>
          </tbody>
        </table>
        <b-button
          class="ml-1"
          size="lg"
          variant="primary"
          @click="hide()"
        >
          OK
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="apply-changes"
      size="lg"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
    >
      <template #default="{ hide }">
        <h3>{{ applyChangesTitle }}</h3>
        <p>
          The following changes were introduced to the visualization setup, but
          not saved in the database yet.
        </p>
        <ul>
          <li
            v-for="columnToHide in visualizationChanges.columnsToHide"
            :key="`columnToHide-${columnToHide}`"
          >
            Hide column
            <strong>{{ columnsWithGroups[columnToHide].title }}</strong>
          </li>
          <li
            v-for="columnToShow in visualizationChanges.columnsToShow"
            :key="`columnToShow-${columnToShow}`"
          >
            Show column
            <strong>{{ columnsWithGroups[columnToShow].title }}</strong>
          </li>
          <li
            v-for="supplierMigration in visualizationChanges.supplierMigrations"
            :key="`supplierMigration-${supplierMigration.name}`"
          >
            <strong>
              {{ columnsWithGroups[supplierMigration.name].title }}
            </strong>
            marked as
            <strong>
              supplied by
              <span
                class="text-capitalize"
                v-text="supplierMigration.supplier"
              />
            </strong>
          </li>
        </ul>
        <b-button
          class="mr-1"
          variant="primary"
          :disabled="isApplyingChanges"
          data-test="apply-changes"
          @click="applyChanges"
        >
          <template v-if="isApplyingChanges">
            Saving... <b-spinner small />
          </template>
          <template v-else>
            Save
          </template>
        </b-button>
        <b-button
          class="ml-1"
          variant="secondary"
          @click="unapplyChanges"
        >
          Discard
        </b-button>
        <b-button
          class="float-right"
          variant="secondary"
          :disabled="isApplyingChanges"
          @click="
            hide();
            applyChangesTitle = 'Changes to apply';
          "
        >
          Cancel
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="applying-changes-succeeded"
      size="lg"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
    >
      <template #default="{ hide }">
        <h3>Changes were applied succesfully</h3>
        <b-button
          class="ml-1"
          variant="primary"
          :disabled="isApplyingChanges"
          @click="hide()"
        >
          OK
        </b-button>
      </template>
    </b-modal>
    <div class="visualizations-tabs-container">
      <b-tabs
        pills
        class="visualizations-tabs"
      >
        <b-tab>
          <template #title>
            <span data-test="file-metadata-tab-title">Metadata</span>
          </template>
          <div class="d-flex align-items-start">
            <div class="meta-data px-2">
              <div class="meta-section">
                <div class="meta-feature">
                  <div class="meta-key">
                    Original Filename:
                  </div>
                  <div
                    class="meta-value"
                    v-text="data.originalFilename"
                  />
                </div>
                <div class="meta-feature">
                  <div class="meta-key">
                    Report Creation Date:
                  </div>
                  <div class="meta-value">
                    {{ data.finished }}
                  </div>
                </div>
                <template v-if="deployAll || description">
                  <div class="meta-feature">
                    <div class="meta-key">
                      Description:
                    </div>
                    <div class="meta-value">
                      <div v-if="isDescriptionEditing">
                        <b-form-textarea
                          v-model="newDescription"
                          size="sm"
                          class="mb-2"
                          :disabled="savingDescriptionChange"
                        />
                        <div class="float-right">
                          <b-button
                            variant="primary"
                            size="sm"
                            class="mr-1"
                            :disabled="savingDescriptionChange"
                            @click="saveDescriptionChange"
                          >
                            Save
                            <div
                              v-if="savingDescriptionChange"
                              class="spinner-border spinner-border-sm"
                              role="status"
                            >
                              <span class="sr-only">Saving...</span>
                            </div>
                          </b-button>
                          <b-button
                            size="sm"
                            :disabled="savingDescriptionChange"
                            @click="cancelDescriptionEdition"
                          >
                            Cancel
                          </b-button>
                        </div>
                      </div>
                      <div
                        v-else
                        class="d-flex"
                      >
                        <div
                          v-if="description"
                          class="description-field"
                        >
                          {{ description }}
                        </div>
                        <em v-else>No description.</em>
                        <a
                          v-if="deployAll"
                          class="text-no-decoration cursor-pointer ml-2"
                          @click="editDescription"
                        ><b-icon-pencil /></a>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
              <div
                v-if="deployAll"
                id="meta-admin"
                class="meta-section admin-section"
              >
                <h4 v-b-toggle="`meta-admin`">
                  Report Setup <b-icon-chevron-down />
                </h4>
                <b-collapse
                  id="meta-admin"
                  visible
                  class="meta-collapse"
                >
                  <h6 class="text-muted">
                    This section is invisible and unavailable for regular users.
                  </h6>
                  <div class="meta-feature">
                    <div class="meta-key">
                      Original URL:
                    </div>
                    <div
                      class="meta-value"
                      :title="data.url"
                    >
                      <a
                        :href="data.url"
                        target="_blank"
                      >{{ data.url }}</a>
                    </div>
                  </div>
                  <div class="meta-feature">
                    <div class="meta-key">
                      Report Label:
                    </div>
                    <div
                      class="meta-value"
                      data-test="file-name"
                    >
                      <div
                        v-if="newFilename"
                        class="row"
                      >
                        <b-input
                          v-model="newFilename"
                          size="sm"
                          class="w-50 ml-3"
                          :disabled="savingFilenameChange"
                        />
                        <b-button
                          variant="primary"
                          size="sm"
                          class="mx-1"
                          :disabled="savingFilenameChange"
                          @click="saveFilenameChange"
                        >
                          Save
                          <div
                            v-if="savingFilenameChange"
                            class="spinner-border spinner-border-sm"
                            role="status"
                          >
                            <span class="sr-only">Saving...</span>
                          </div>
                        </b-button>
                        <b-button
                          size="sm"
                          :disabled="savingFilenameChange"
                          @click="cancelFilenameEdition"
                        >
                          Cancel
                        </b-button>
                      </div>
                      <template v-else>
                        {{ filename }}
                        <a
                          v-if="deployAll"
                          class="text-no-decoration cursor-pointer ml-2"
                          @click="editFilename"
                        ><b-icon-pencil /></a>
                      </template>
                    </div>
                  </div>
                  <div class="meta-feature">
                    <div class="meta-key">
                      Collection of Definitions:
                    </div>
                    <div class="meta-value">
                      <b-form-select
                        v-if="collection.options"
                        v-model="collection.choice"
                        :options="collection.options"
                        :disabled="
                          Boolean(
                            collection.isLoading ||
                              collection.isSaving ||
                              collection.error
                          )
                        "
                        size="sm"
                        @change="changeCollection"
                      />
                      <b-spinner
                        v-else-if="collection.isLoading || collection.isSaving"
                        class="ml-2"
                        style="width: 23px; height: 23px"
                      />
                      <div
                        v-if="collection.error"
                        class="text-danger"
                        v-text="collection.error"
                      />
                    </div>
                  </div>
                  <div class="meta-feature">
                    <div class="meta-key">
                      Availability:
                    </div>
                    <div class="meta-value">
                      <div
                        v-if="isIrrelevant"
                        class="d-flex"
                      >
                        <div class="mr-3 text-danger">
                          <strong>Private <b-icon-lock-fill /></strong>
                          <small class="text-muted">
                            <ul class="mb-0 pl-3">
                              <li>unavailable for regular users</li>
                              <li>invisible on the Job List page by default</li>
                            </ul>
                          </small>
                        </div>
                        <div>
                          <b-button
                            v-b-tooltip.hover
                            variant="outline-secondary"
                            size="sm"
                            title="Will be available for regular users and visible on the Job List page by default."
                            :disabled="isSavingRelevancy"
                            @click="saveRelevancy"
                          >
                            Make Public
                            <b-spinner
                              v-if="isSavingRelevancy"
                              small
                              class="ml-2"
                            />
                          </b-button>
                        </div>
                      </div>
                      <div
                        v-else
                        class="d-flex"
                      >
                        <div class="mr-3 text-success">
                          <strong>Public <b-icon-unlock-fill /></strong>
                          <small class="text-muted">
                            <ul class="mb-0 pl-3">
                              <li>available for regular users</li>
                              <li>visible on the Job List page by default</li>
                            </ul>
                          </small>
                        </div>
                        <div>
                          <b-button
                            v-b-tooltip.hover
                            variant="outline-secondary"
                            size="sm"
                            title="Will be unavailable for regular users and invisible on the Job List page by default."
                            :disabled="isSavingRelevancy"
                            @click="saveRelevancy"
                          >
                            Make Private
                            <b-spinner
                              v-if="isSavingRelevancy"
                              small
                              class="ml-2"
                            />
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="meta-feature mt-3">
                    <div class="meta-key">
                      Extra Actions:
                    </div>
                    <div class="meta-value">
                      <b-button
                        class="mr-1 text-nowrap"
                        variant="secondary"
                        size="sm"
                        :href="`${backendUrl}/export/output/${taskId()}/?token=${backendToken}`"
                      >
                        JSON File <b-icon-download />
                      </b-button>
                      <b-button
                        v-b-modal.save-as-preset
                        class="mr-1 text-nowrap"
                        variant="secondary"
                        size="sm"
                        data-test="save-as-preset"
                      >
                        Save as a Preset <b-icon-box-arrow-in-down />
                      </b-button>
                      <b-button
                        v-b-modal.load-current-preset
                        class="mr-1 text-nowrap"
                        variant="secondary"
                        size="sm"
                      >
                        Sync with Current Preset <b-icon-box-arrow-in-up />
                      </b-button>
                      <b-button
                        v-b-modal.refreshing-cache
                        class="mr-1 text-nowrap"
                        variant="secondary"
                        size="sm"
                        @click="refreshCache"
                      >
                        Refresh Cache <b-icon-arrow-repeat />
                      </b-button>
                      <b-button
                        v-if="failureRowsFromHistogram.length > 0"
                        class="mr-1 text-nowrap"
                        variant="secondary"
                        size="sm"
                        :href="`${backendUrl}/export/too-few-columns/${taskId()}/?token=${backendToken}`"
                      >
                        Anomalous Rows <b-icon-download />
                      </b-button>
                    </div>
                  </div>
                </b-collapse>
              </div>
              <div
                v-if="data.metadata"
                class="meta-section"
              >
                <h4 v-b-toggle="`meta-metadata`">
                  File Metadata <b-icon-chevron-down />
                </h4>
                <b-collapse
                  id="meta-metadata"
                  class="meta-collapse"
                >
                  <div
                    v-if="data.metadata.FileSize"
                    class="meta-feature"
                  >
                    <div class="meta-key">
                      Size:
                    </div>
                    <div class="meta-value">
                      {{ data.metadata.FileSize }}
                    </div>
                  </div>
                  <div
                    v-if="data.metadata.ModifyDate"
                    class="meta-feature"
                  >
                    <div class="meta-key">
                      Last Modification Date:
                    </div>
                    <div class="meta-value">
                      {{ data.metadata.ModifyDate }}
                    </div>
                  </div>
                  <div
                    v-if="data.metadata.FileTypeExtension"
                    class="meta-feature"
                  >
                    <div class="meta-key">
                      Compression:
                    </div>
                    <div class="meta-value">
                      {{ data.metadata.FileTypeExtension }}
                    </div>
                  </div>
                </b-collapse>
              </div>
              <div class="meta-section">
                <h4 v-b-toggle="`meta-csv-dialect`">
                  CSV Dialect <b-icon-chevron-down />
                </h4>
                <b-collapse
                  id="meta-csv-dialect"
                  class="meta-collapse"
                >
                  <div class="meta-feature">
                    <div class="meta-key">
                      Field Separator:
                      <b-link
                        disabled
                        class="cursor-help"
                      >
                        <b-icon-question-circle
                          id="help-popover-delimiter"
                          class="ml-2"
                        />
                        <b-popover
                          target="help-popover-delimiter"
                          triggers="hover"
                        >
                          <template #title>
                            <code> Dialect.<strong>delimiter</strong> </code>
                          </template>
                          <p>
                            A one-character string used to separate fields. It
                            defaults to <code>','</code>.
                          </p>
                        </b-popover>
                      </b-link>
                    </div>
                    <div class="meta-value">
                      <code>{{ stringify(data.dialect.delimiter) }}</code>
                    </div>
                  </div>
                  <div class="meta-feature">
                    <div class="meta-key">
                      Quoting Delimiter:
                      <b-link
                        disabled
                        class="cursor-help"
                      >
                        <b-icon-question-circle
                          id="help-popover-quotechar"
                          class="ml-2"
                        />
                        <b-popover
                          target="help-popover-quotechar"
                          triggers="hover"
                        >
                          <template #title>
                            <code> Dialect.<strong>quotechar</strong> </code>
                          </template>
                          <p>
                            A one-character string used to quote fields
                            containing special characters, such as the
                            <em>delimiter</em> or <em>quotechar</em>, or which
                            contain new-line characters. It defaults to
                            <code>'"'</code>.
                          </p>
                        </b-popover>
                      </b-link>
                    </div>
                    <div class="meta-value">
                      <code>{{ stringify(data.dialect.quotechar) }}</code>
                    </div>
                  </div>
                  <div class="meta-feature">
                    <div class="meta-key">
                      Escape Character:
                      <b-link
                        disabled
                        class="cursor-help"
                      >
                        <b-icon-question-circle
                          id="help-popover-escapechar"
                          class="ml-2"
                        />
                        <b-popover
                          target="help-popover-escapechar"
                          triggers="hover"
                        >
                          <template #title>
                            <code> Dialect.<strong>escapechar</strong> </code>
                          </template>
                          <p>
                            A one-character string used by the writer to escape
                            the <em>delimiter</em> if <em>quoting</em> is set to
                            <a
                              href="https://docs.python.org/3/library/csv.html#csv.QUOTE_NONE"
                              target="_blank"
                            >
                              <code>QUOTE_NONE</code>
                            </a>
                            and the <em>quotechar</em> if
                            <em>doublequote</em> is
                            <a
                              href="https://docs.python.org/3/library/csv.html#csv.QUOTE_NONE"
                              target="_blank"
                            >
                              <code>False</code> </a>. On reading, the <em>escapechar</em> removes any
                            special meaning from the following character. It
                            defaults to
                            <a
                              href="https://docs.python.org/3/library/constants.html#None"
                              target="_blank"
                            >
                              <code>None</code> </a>, which disables escaping.
                          </p>
                        </b-popover>
                      </b-link>
                    </div>
                    <div class="meta-value">
                      <code>{{ stringify(data.dialect.escapechar) }}</code>
                    </div>
                  </div>
                  <div class="meta-feature">
                    <div class="meta-key">
                      Line Terminator:
                      <b-link
                        disabled
                        class="cursor-help"
                      >
                        <b-icon-question-circle
                          id="help-popover-lineterminator"
                          class="ml-2"
                        />
                        <b-popover
                          target="help-popover-lineterminator"
                          triggers="hover"
                        >
                          <template #title>
                            <code>
                              Dialect.<strong>lineterminator</strong>
                            </code>
                          </template>
                          <p>
                            Controls when quotes should be generated by the
                            writer and recognised by the reader. It can take on
                            any of the <code>QUOTE_*</code> constants (see
                            section
                            <a
                              href="https://docs.python.org/3/library/csv.html#csv-contents"
                              target="_blank"
                            >
                              Module Contents </a>) and defaults to
                            <a
                              href="https://docs.python.org/3/library/csv.html#csv.QUOTE_MINIMAL"
                              target="_blank"
                            >
                              <code>QUOTE_MINIMAL</code> </a>.
                          </p>
                        </b-popover>
                      </b-link>
                    </div>
                    <div class="meta-value">
                      <code>{{ stringify(data.dialect.lineterminator) }}</code>
                    </div>
                  </div>
                  <div class="meta-feature">
                    <div class="meta-key">
                      Quoting:
                      <b-link
                        disabled
                        class="cursor-help"
                      >
                        <b-icon-question-circle
                          id="help-popover-quoting"
                          class="ml-2"
                        />
                        <b-popover
                          target="help-popover-quoting"
                          triggers="hover"
                        >
                          <template #title>
                            <code> Dialect.<strong>quoting</strong> </code>
                          </template>
                          <p>
                            Controls when quotes should be generated by the
                            writer and recognised by the reader. It can take on
                            any of the <code>QUOTE_*</code> constants (see
                            section
                            <a
                              href="https://docs.python.org/3/library/csv.html#csv-contents"
                              target="_blank"
                            >
                              Module Contents </a>) and defaults to
                            <a
                              href="https://docs.python.org/3/library/csv.html#csv.QUOTE_MINIMAL"
                              target="_blank"
                            >
                              <code>QUOTE_MINIMAL</code> </a>.
                          </p>
                        </b-popover>
                      </b-link>
                    </div>
                    <div class="meta-value">
                      <code>{{ csvQuotingMapping[data.dialect.quoting] }}</code>
                    </div>
                  </div>
                  <div class="meta-feature">
                    <div class="meta-key">
                      Double Quote:
                      <b-link
                        disabled
                        class="cursor-help"
                      >
                        <b-icon-question-circle
                          id="help-popover-doublequote"
                          class="ml-2"
                        />
                        <b-popover
                          target="help-popover-doublequote"
                          triggers="hover"
                        >
                          <template #title>
                            <code> Dialect.<strong>doublequote</strong> </code>
                          </template>
                          <p>
                            Controls how instances of
                            <em>quotechar</em> appearing inside a field should
                            themselves be quoted. When
                            <a
                              href="https://docs.python.org/3/library/constants.html#True"
                              target="_blank"
                            >
                              <code>True</code> </a>, the character is doubled. When
                            <a
                              href="https://docs.python.org/3/library/constants.html#False"
                              target="_blank"
                            >
                              <code>False</code> </a>, the <em>escapechar</em> is used as a prefix to
                            the <em>quotechar</em>. It defaults to
                            <a
                              href="https://docs.python.org/3/library/constants.html#True"
                              target="_blank"
                            >
                              <code>True</code> </a>.
                          </p>
                          <p>
                            On output, if <em>doublequote</em> is
                            <a
                              href="https://docs.python.org/3/library/constants.html#False"
                              target="_blank"
                            >
                              <code>False</code>
                            </a>
                            and no <em>escapechar</em> is set,
                            <a
                              href="https://docs.python.org/3/library/csv.html#csv.Error"
                              target="_blank"
                            >
                              <code>Error</code>
                            </a>
                            is raised if a <em>quotechar</em> is found in a
                            field.
                          </p>
                        </b-popover>
                      </b-link>
                    </div>
                    <div class="meta-value">
                      <code>{{ data.dialect.doublequote }}</code>
                    </div>
                  </div>
                  <div class="meta-feature">
                    <div class="meta-key">
                      Skip Initial Space:
                      <b-link
                        disabled
                        class="cursor-help"
                      >
                        <b-icon-question-circle
                          id="help-popover-skipinitialspace"
                          class="ml-2"
                        />
                        <b-popover
                          target="help-popover-skipinitialspace"
                          triggers="hover"
                        >
                          <template #title>
                            <code>
                              Dialect.<strong>skipinitialspace</strong>
                            </code>
                          </template>
                          <p>
                            When
                            <a
                              href="https://docs.python.org/3/library/constants.html#True"
                              target="_blank"
                            >
                              <code>True</code> </a>, whitespace immediately following the
                            <em>delimiter</em>
                            is ignored. The default is
                            <a
                              href="https://docs.python.org/3/library/constants.html#False"
                              target="_blank"
                            >
                              <code>False</code> </a>.
                          </p>
                        </b-popover>
                      </b-link>
                    </div>
                    <div class="meta-value">
                      <code>{{ data.dialect.skipinitialspace }}</code>
                    </div>
                  </div>
                </b-collapse>
              </div>
              <div
                id="meta-csv-summary"
                class="meta-section"
              >
                <h4 v-b-toggle="`meta-csv-summary`">
                  CSV Data Summary <b-icon-chevron-down />
                </h4>
                <b-collapse
                  id="meta-csv-summary"
                  class="meta-collapse"
                >
                  <div class="meta-feature">
                    <div class="meta-key">
                      Column Count:
                    </div>
                    <div
                      class="meta-value"
                      v-text="data.columnCount"
                    />
                  </div>
                  <div class="meta-feature">
                    <div class="meta-key">
                      Total Rows:
                    </div>
                    <div
                      class="meta-value"
                      data-test="total-rows"
                      v-text="data.totalRows"
                    />
                  </div>
                  <div class="meta-feature">
                    <div class="meta-key">
                      Column Row Count:
                      <b-icon-check-circle
                        v-if="failureRowsFromHistogram.length === 0"
                        class="text-success"
                      />
                    </div>
                    <div class="meta-value">
                      <table
                        class="
                          table table-sm table-borderless table-responsive
                          overflow-hidden
                          mb-1
                        "
                        style="max-height: 70px"
                      >
                        <tbody>
                          <tr
                            v-for="histogramEntry in histogram"
                            :key="histogramEntry.columnCount"
                            :class="`text-${
                              isHistogramEntryFailure(histogramEntry)
                                ? 'danger'
                                : 'success'
                            }`"
                          >
                            <th class="pl-0 pt-0">
                              {{ histogramEntry.columnCount }}:
                            </th>
                            <td class="pl-0 pt-0">
                              <strong
                                v-if="!isHistogramEntryFailure(histogramEntry)"
                              >
                                {{ histogramEntry.rowCount }}
                              </strong>
                              <template v-else>
                                {{ histogramEntry.rowCount }}
                              </template>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <b-button
                        v-if="histogram.length > 2"
                        v-b-modal.column-row-count
                        variant="outline-secondary"
                        size="sm"
                      >
                        Show All
                      </b-button>
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab class="px-2">
          <template #title>
            <span data-test="column-list-tab-title">List of Columns</span>
          </template>
          <div class="mb-2">
            <b-button
              v-if="deployAll"
              v-b-modal.change-group-order
              variant="outline-secondary"
              class="mr-2"
              size="sm"
              data-test="change-group-order"
            >
              Change Order of Groups <b-icon-list-ol class="ml-1" />
            </b-button>
            <b-button
              v-if="deployAll"
              v-b-modal.select-new-group
              variant="outline-secondary"
              class="mr-2"
              size="sm"
              data-test="change-columns-group"
            >
              Batch Group Assignment <b-icon-menu-button-wide-fill class="ml-1" />
            </b-button>
            <b-dropdown
              variant="primary"
              class="float-right"
              size="sm"
            >
              <template #button-content>
                Export as <b-icon-download class="ml-1" />
              </template>
              <b-dropdown-item
                :href="`${backendUrl}/export/report/${ordering.selected}-order/${taskId()}/rtf/?token=${backendToken}`"
              >
                <pre class="d-inline">.rtf</pre>
              </b-dropdown-item>
              <b-dropdown-item
                :href="`${backendUrl}/export/report/${ordering.selected}-order/${taskId()}/pdf/?token=${backendToken}`"
              >
                <pre class="d-inline">.pdf</pre>
              </b-dropdown-item>
              <b-dropdown-item
                :href="`${backendUrl}/export/report/${ordering.selected}-order/${taskId()}/csv/?token=${backendToken}`"
              >
                <pre class="d-inline">.csv</pre>
                (no definitions)
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="d-flex mb-2">
            <div class="flex-fill">
              <SearchBox
                ref="searchBox"
                placeholder="Search columns by name, label or group"
                class="mb-2"
                :data="originalHeaderToShow()"
                :features-to-query="['name', 'title', 'group.title']"
                :filtered.sync="filteredOriginalHeaderToShow"
              />
            </div>
            <b-form-select
              v-model="ordering.selected"
              :options="ordering.options"
              style="max-width: 300px"
              class="ml-2"
            />
          </div>
          <b-alert
            v-if="
              deployAll &&
                areDefinitionsLoaded() &&
                missingDefinitions().length > 0
            "
            :show="true"
            variant="warning"
            class="mb-1"
          >
            <strong class="mr-1">
              There are {{ missingDefinitions().length }}
              missing definitions in the report.
            </strong>
            <a
              :href="collectionUrl()"
              target="_blank"
              v-text="`Fill in the gaps.`"
            />
          </b-alert>
          <b-alert
            v-else-if="deployAll && !areDefinitionsLoaded()"
            :show="true"
            variant="warning"
            class="mb-1"
            v-text="`Couldn't fetch definitions.`"
          />
          <table
            v-if="orderedFilteredOriginalHeaderToShow().length > 0"
            class="table table-sm table-borderless table-hover header-fixed"
          >
            <thead>
              <tr v-if="deployAll">
                <th class="pl-0 pt-0 cell-column-no" />
                <th class="pl-0 pt-0 cell-column-name" />
                <th class="pl-0 pt-0 cell-column-label" />
                <th class="pl-0 pt-0 cell-column-group" />
                <th class="pl-0 pt-0 cell-column-group-edit" />
                <th class="pl-0 pt-0 cell-supplier" />
                <th class="pl-0 pt-0 cell-show-hide text-center">
                  Show
                </th>
              </tr>
              <tr>
                <th class="pl-2 pt-0 cell-column-no">
                  #
                </th>
                <th class="pl-0 pt-0 cell-column-name">
                  Column Name
                </th>
                <th class="pl-0 pt-0 cell-column-label">
                  Label
                </th>
                <th class="pl-0 pt-0 cell-column-group">
                  Group
                </th>
                <template v-if="deployAll">
                  <th class="pl-0 pt-0 cell-column-group-edit text-center">
                    <div>Batch</div>
                    <div>Group</div>
                  </th>
                  <th class="pl-0 pt-0 cell-supplier text-center">
                    Supplied By
                  </th>
                  <th class="pr-1 pt-0 cell-show-hide-entry text-right">
                    Column
                  </th>
                  <th class="pl-1 pt-0 cell-show-hide-entry text-left">
                    Group
                  </th>
                </template>
                <template v-else>
                  <th class="pl-0 pt-0 cell-column-type">
                    Data Type
                  </th>
                  <th class="pl-0 pt-0 cell-column-num">
                    Min.
                  </th>
                  <th class="pl-0 pt-0 cell-column-num">
                    Max.
                  </th>
                </template>
              </tr>
            </thead>
            <tbody>
              <RecycleScroller
                v-slot="{ item }"
                page-mode
                key-field="index"
                :items="orderedFilteredOriginalHeaderToShow()"
                :item-size="28.8"
                :emit-update="true"
                @update="updateScroller"
              >
                <tr
                  :class="
                    columnEntryClasses(item.entry.name, item.entry.supplier)
                  "
                  @click.left="
                    columnClicked('left', item.entry, item.entry.group)
                  "
                  @click.middle="
                    columnClicked('middle', item.entry, item.entry.group)
                  "
                >
                  <th class="pl-2 pt-0 cell-column-no">
                    {{ item.entry.no + 1 }}
                  </th>
                  <td
                    :id="`row-${item.entry.no}`"
                    class="pl-0 pt-0 cell-column-name d-flex"
                  >
                    <span
                      class="mr-2 flex-fill text-truncate"
                      v-html="item.queried.name"
                    />
                    <div v-if="deployAll">
                      <b-badge
                        v-if="
                          areDefinitionsLoaded() &&
                            !hasDefinition(item.entry.name)
                        "
                        variant="primary"
                        pill
                      >
                        no definition
                      </b-badge>
                    </div>
                  </td>
                  <td
                    class="pl-0 pt-0 text-truncate cell-column-label"
                    v-html="item.queried.title"
                  />
                  <td class="pl-0 pt-0 cell-column-group d-flex">
                    <span
                      class="flex-fill text-truncate"
                      :data-test="`column-group-name-${item.entry.name}`"
                      v-html="item.queried['group.title']"
                    />
                  </td>
                  <template v-if="deployAll">
                    <td
                      class="pl-0 pt-0 cell-column-group-edit text-center"
                      @click.stop
                    >
                      <b-form-checkbox
                        v-model="batchGroupEdit"
                        :value="item.entry.name"
                        :data-test="`batch-column-group-${item.entry.name}`"
                      />
                    </td>
                    <td
                      class="pl-0 pt-0 cell-supplier text-center"
                      @click.stop
                    >
                      <div class="btn-group-toggle">
                        <label
                          :class="{
                            btn: true,
                            'btn-outline-secondary': true,
                            'btn-sm': true,
                            'mr-1': true,
                            active: item.entry.supplier === 'customer',
                          }"
                          style="line-height: 1"
                        >
                          <input
                            v-model="item.entry.supplier"
                            type="radio"
                            value="customer"
                            @change="supplierChanged(item.entry)"
                          >
                          Customer
                        </label>
                        <label
                          :class="{
                            btn: true,
                            'btn-outline-primary': true,
                            'btn-sm': true,
                            active: item.entry.supplier === 'profound',
                          }"
                          style="line-height: 1"
                        >
                          <input
                            v-model="item.entry.supplier"
                            type="radio"
                            value="profound"
                            @change="supplierChanged(item.entry)"
                          >
                          Profound
                        </label>
                      </div>
                    </td>
                    <td
                      class="pr-1 pt-0 cell-show-hide-entry text-right"
                      @click.stop
                    >
                      <b-form-checkbox
                        v-model="columnsToShow"
                        :value="item.entry.name"
                        :data-test="`column-show-checkbox_${item.entry.name}`"
                        @change="showColumnSwitched(item.entry.name)"
                      />
                    </td>
                    <td
                      class="pl-3 pt-0 cell-show-hide-entry text-left"
                      @click.stop
                    >
                      <b-form-checkbox
                        :ref="`${item.entry.name}GroupCheckbox`"
                        v-model="columnsToShow"
                        :value="item.entry.name"
                        :data-test="`group-show-checkbox_${item.entry.name}`"
                        @change="showGroupSwitched(item.entry.name)"
                      />
                    </td>
                  </template>
                  <template v-else>
                    <td class="pl-0 pt-0 cell-column-type">
                      {{ item.entry.type }}
                    </td>
                    <td class="pl-0 pt-0 cell-column-num">
                      {{ item.entry.minLen }}
                    </td>
                    <td class="pl-0 pt-0 cell-column-num">
                      {{ item.entry.maxLen }}
                    </td>
                  </template>
                </tr>
                <b-popover
                  v-if="item.entry.supplier === 'customer'"
                  triggers="hover"
                  placement="right"
                  :target="`row-${item.entry.no}`"
                >
                  <template #title>
                    {{ item.entry.title }}
                    <b-badge variant="secondary">
                      Customer-supplied Data
                    </b-badge>
                  </template>
                </b-popover>
                <b-popover
                  v-else
                  triggers="hover"
                  placement="right"
                  :target="`row-${item.entry.no}`"
                  :title="item.entry.name"
                >
                  <template #title>
                    {{ labelFromDefinition(item.entry) }}
                    <b-badge variant="primary">
                      Supplied by Profound Networks
                    </b-badge>
                  </template>
                  <div v-html="renderedDefinition(item.entry.name)" />
                </b-popover>
              </RecycleScroller>
            </tbody>
          </table>
          <div
            v-else
            class="ml-5 mt-2"
          >
            <em>No column meets requested search query.</em>
          </div>
          <div v-if="showVisualizationChangesSummary">
            <h3>Changes were introduced to the layout of the report.</h3>
            <b-button
              v-b-modal.apply-changes
              class="mr-1"
              variant="primary"
              data-test="review-and-save"
            >
              Review and Save
            </b-button>
            <b-button
              class="ml-1"
              variant="secondary"
              @click="unapplyChanges"
            >
              Discard
            </b-button>
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </section>
</template>

<script>
import { BIconCheckCircle, BIconPencil } from "bootstrap-vue";
import { RecycleScroller } from "vue-virtual-scroller";
import draggable from "vuedraggable";
import SearchBox from "./SearchBox.vue";
import httpHelper from "../../modules/httpHelper.js";
import showdown from "showdown";

export default {
  components: {
    RecycleScroller,
    draggable,
    BIconCheckCircle,
    BIconPencil,
    SearchBox
  },
  props: { data: { type: Object, default: null } },
  data() {
    const collection = this.data.collection;
    const visualizationChanges = {
      columnsToHide: this.getLocalStorage("columnsToHide"),
      columnsToShow: this.getLocalStorage("columnsToShow"),
      supplierMigrations: this.getLocalStorage("supplierMigrations"),
    };
    const columnsToHide = [
      ...new Set([
        ...this.data.columnsToHide,
        ...visualizationChanges.columnsToHide,
      ]),
    ];
    return {
      refreshCacheState: null,
      refreshCacheError: null,
      applyChangesTitle: "You have left unsaved changes recently",
      preset: this.data.preset,
      chosenPreset: this.data.preset ? "overwrite" : "new",
      savingPreset: false,
      presetSaveErrors: null,
      presetSaveWarnings: null,
      loadingPreset: false,
      presetLoadErrors: null,
      newPresetToCreate: { name: null, filename: null },
      newGroupToCreate: null,
      chosenGroup: null,
      batchGroupEdit: [],
      changeGroupError: null,
      savingGroupChange: false,
      dragToken: false,
      draggingClasses: ["dragging", "bg-secondary", "text-light"],
      orderedGroups: [...this.allGroups()],
      orderGroupError: null,
      savingGroupOrder: false,
      isIrrelevant: this.data.isIrrelevant,
      collection: {
        choice: collection ? collection.pk : null,
        options: [
          {
            text: collection ? collection.name : "",
            value: collection ? collection.pk : null,
          },
        ],
        isLoading: true,
        isSaving: false,
        error: null,
      },
      isSavingRelevancy: false,
      isApplyingChanges: false,
      initialColumnsToShow: this.data.originalHeader.filter(
        (columnName) => !this.data.columnsToHide.includes(columnName)
      ),
      columnsToHide,
      columnsToShow: [
        ...new Set([
          ...this.data.originalHeader.filter(
            (columnName) =>
              !this.data.columnsToHide.includes(columnName) &&
              !columnsToHide.includes(columnName)
          ),
          ...visualizationChanges.columnsToShow,
        ]),
      ],
      initialSuppliers: this.getInitialSuppliers(),
      visualizationChanges,
      showVisualizationChangesSummary: false,
      filteredOriginalHeaderToShow: [],
      filename: this.data.filename,
      newFilename: null,
      savingFilenameChange: false,
      description: this.data.description,
      isDescriptionEditing: false,
      newDescription: null,
      savingDescriptionChange: false,
      mdConverter: new showdown.Converter({tables: true}),
      ordering: {
        selected: "original",
        options: [
          { text: "Sort by Original Input File Order", value: "original" },
          { text: "Sort by Grouping in the Report Order", value: "groups" },
        ],
      },
    };
  },
  computed: {
    deployAll: () =>
      ["true", "t", "yes", "y", "on"].includes(
        process.env.VUE_APP_DEPLOY_ALL.toLowerCase()
      ),
    backendUrl: () => process.env.VUE_APP_VISUALIZER_BACKEND_URL,
    backendToken: () => process.env.VUE_APP_BACKEND_TOKEN,
    csvQuotingMapping: () => [
      "QUOTE_MINIMAL",
      "QUOTE_ALL",
      "QUOTE_NONNUMERIC",
      "QUOTE_NONE",
    ],
    failureRowsFromHistogram() {
      return this.data.histogram.filter(this.isHistogramEntryFailure);
    },
    histogram() {
      return [...this.data.histogram].sort((element0, element1) => {
        const columnCount0 = parseInt(element0.columnCount);
        const columnCount1 = parseInt(element1.columnCount);
        if (columnCount1 === this.data.columnCount) {
          return Infinity;
        }
        return columnCount1 - columnCount0;
      });
    },
    columnsWithGroups() {
      return Object.fromEntries(
        this.data.groups
          .map((group) =>
            group.columns.map((column) => [column.name, { ...column, group }])
          )
          .flat()
      );
    },
  },
  async mounted() {
    this.setShowVisualizationChangesSummary();
    if (this.showVisualizationChangesSummary) {
      this.$bvModal.show("apply-changes");
    } else {
      this.applyChangesTitle = "Changes to apply";
    }
    this.columnsToHide.forEach((columnName) =>
      this.$emit("columnHidden", columnName)
    );
    const collections = await this.getCollections();
    if (collections.error || !collections.data) {
      this.collection.error = "Couldn't fetch collection list.";
    } else {
      this.collection.options = [
        { text: "<No collection>", value: null },
      ].concat(
        collections.data.map((collection) =>
          collection ? { text: collection.name, value: collection.pk } : {}
        )
      );
    }
    this.collection.isLoading = false;
  },
  methods: {
    isColumnHidden(columnName) {
      return this.columnsToHide.includes(columnName);
    },
    isGroupHidden(groupColumns) {
      return groupColumns.every(
        column => this.columnsToHide.includes(column.name)
      );
    },
    orderedFilteredOriginalHeaderToShow() {
      if (this.ordering.selected === 'original') {
        return [...this.filteredOriginalHeaderToShow];
      }
      const groupNames = this.data.groups.map(group => group.name);
      return [...this.filteredOriginalHeaderToShow].sort(
        (column0, column1) => {
          const group0Index = groupNames.indexOf(column0.entry.group.name);
          const group1Index = groupNames.indexOf(column1.entry.group.name);
          if (group0Index !== group1Index) {
            return group0Index - group1Index;
          }
          const group = column0.entry.group;
          const column0InGroupIndex = group.columns.indexOf(column0);
          const column1InGroupIndex = group.columns.indexOf(column1);
          return column0InGroupIndex - column1InGroupIndex;
        }
      );
    },
    stringify: (unsafe) => JSON.stringify(unsafe),
    taskId() {
      return this.$route.params.taskId;
    },
    async refreshCache() {
      this.refreshCacheState = "ongoing";
      let urlBase = `refresh-report-cache-by-task/${this.taskId()}`;
      const url = `${this.backendUrl}/${urlBase}/?token=${this.backendToken}`;
      const response = await fetch(url, { method: "POST" });
      if (!response.ok) {
        this.refreshCacheState = "error";
        return;
      }
      this.refreshCacheState = "done";
    },
    localStoragePrefix() {
      return `csvanalyzer-report-changes-${this.taskId()}`;
    },
    getLocalStorage(key) {
      const rawResult = localStorage[`${this.localStoragePrefix()}-${key}`];
      return JSON.parse(rawResult || "[]");
    },
    setLocalStorage(key, value) {
      const stringifiedValue = JSON.stringify(value);
      localStorage[`${this.localStoragePrefix()}-${key}`] = stringifiedValue;
    },
    deleteLocalStorage(key) {
      delete localStorage[`${this.localStoragePrefix()}-${key}`];
    },
    originalHeaderToShow() {
      const savedSupplierMigrations = Object.fromEntries(
        this.getLocalStorage("supplierMigrations").map(({ name, supplier }) => [
          name,
          supplier,
        ])
      );
      return (
        this.deployAll
          ? this.data.originalHeader
          : this.data.originalHeader.filter(
              (columnName) => !this.data.columnsToHide.includes(columnName)
            )
      ).map((columnName, index) => {
        const result = { no: index, ...this.columnsWithGroups[columnName] };
        if (columnName in savedSupplierMigrations) {
          result.supplier = savedSupplierMigrations[columnName];
        }
        return result;
      });
    },
    async savePreset() {
      this.savingPreset = true;
      this.presetSaveErrors = null;
      let urlBase = `save-as-preset/${this.taskId()}`;
      const url = `${this.backendUrl}/${urlBase}/?token=${this.backendToken}`;
      const body = JSON.stringify(
        this.chosenPreset === "new" ? this.newPresetToCreate : {}
      );
      const response = await fetch(url, { method: "POST", body });
      this.savingPreset = false;
      if (!response.ok) {
        const data = await httpHelper.safeJson(response);
        this.presetSaveErrors = data.errors || "Unknown error occurred.";
        if (data.warnings) {
          this.presetSaveWarnings = data.warnings;
        }
        return;
      }
      this.$bvModal.hide("save-as-preset");
      this.$bvModal.show("preset-successfuly-saved-message");
    },
    async loadPreset() {
      this.loadingPreset = true;
      this.presetLoadErrors = null;
      let urlBase = `load-current-preset/${this.taskId()}`;
      const url = `${this.backendUrl}/${urlBase}/?token=${this.backendToken}`;
      const response = await fetch(url, { method: "POST" });
      this.loadingPreset = false;
      this.$bvModal.hide("load-current-preset");
      if (response.ok) {
        this.$bvModal.show("preset-successfuly-loaded-message");
        return;
      }
      const data = await httpHelper.safeJson(response);
      this.presetLoadErrors = (data && data.errors) || ["Unknown error occurred."];
      this.$bvModal.show("preset-failed-to-load-message");
    },
    allGroups() {
      return this.data.groups.concat(
        ((this.data.problems || {}).emptyGroups || []).map((group) => ({
          ...group,
          columns: [],
        }))
      );
    },
    async saveGroupChange() {
      if (!this.chosenGroup) {
        if (!this.newGroupToCreate) {
          this.changeGroupError = "Group title required.";
          return;
        }
        const possibleGroupEntry = this.data.groups.find(
          (group) =>
            group.title === this.newGroupToCreate ||
            group.name ===
              this.newGroupToCreate
                .replace(/[^\d\w _-]/g, "")
                .toLowerCase()
                .replace(/[ -]/g, "_")
        );
        if (possibleGroupEntry) {
          this.changeGroupError = "Specified group already exists.";
          return;
        }
      }
      this.savingGroupChange = true;
      this.changeGroupError = null;
      let urlBase = `${
        this.chosenGroup ? "save-group-change" : "create-new-group"
      }/${this.taskId()}`;
      const url = `${this.backendUrl}/${urlBase}/?token=${this.backendToken}`;
      const columns = this.batchGroupEdit;
      const body = JSON.stringify({
        columns,
        group: this.chosenGroup || this.newGroupToCreate,
      });
      const response = await fetch(url, { method: "POST", body });
      this.savingGroupChange = false;
      if (!response.ok) {
        const data = await httpHelper.safeJson(response);
        if (data.error === "Specified group already exists.") {
          this.changeGroupError =
            "Specified group already exists. Try to reload.";
          return;
        }
        this.changeGroupError = "Unknown error occurred.";
        return;
      }
      this.$bvModal.hide("select-new-group");
      if (this.chosenGroup) {
        this.chosenGroup = (
          this.data.groups.find((group) => group.name === this.chosenGroup) ||
          this.data.problems.emptyGroups.find(
            (group) => group.name === this.chosenGroup
          )
        ).title;
      } else {
        this.chosenGroup = this.newGroupToCreate;
      }
      this.$bvModal.show("column-successfuly-reassigned-message");
    },
    dragStart(event) {
      event.target.classList.add(...this.draggingClasses);
    },
    dragEnd(event) {
      event.target.classList.remove(...this.draggingClasses);
    },
    async saveGroupOrder() {
      this.savingGroupOrder = true;
      this.orderGroupError = null;
      let urlBase = `save-group-order/${this.taskId()}`;
      const url = `${this.backendUrl}/${urlBase}/?token=${this.backendToken}`;
      const body = JSON.stringify({
        orderedGroups: this.orderedGroups.map((group) => group.name),
      });
      const response = await fetch(url, { method: "POST", body });
      this.savingGroupOrder = false;
      if (!response.ok) {
        this.orderGroupError = "Unknown error occurred.";
        return;
      }
      this.$bvModal.hide("change-group-order");
      this.$bvModal.show("group-order-successfuly-saved-message");
    },
    collectionUrl() {
      if (!this.data.collection) {
        return null;
      }
      const definitionsUrl = process.env.VUE_APP_DEFINITIONS_FRONTEND_URL;
      const collectionPk = this.data.collection.pk;
      return `${definitionsUrl}/fill-gaps/${collectionPk}`;
    },
    missingDefinitions() {
      const header = this.data.originalHeader;
      return header.filter((columnName) => !this.hasDefinition(columnName));
    },
    areDefinitionsLoaded() {
      if (!this.data.collection) {
        return false;
      }
      if (!this.data.collection.fields) {
        return false;
      }
      return true;
    },
    hasDefinition(columnName) {
      if (!this.data.collection) {
        return false;
      }
      if (!this.data.collection.fields) {
        return false;
      }
      return this.data.collection.fields.find(
        field => columnName.match(`^${field.name}$`)
      );
    },
    getInitialSuppliers() {
      return Object.fromEntries(
        this.data.groups
          .map((group) => group.columns)
          .flat()
          .map((column) => [column.name, column.supplier])
      );
    },
    supplierChanged(candidate) {
      const supplierMigrations = this.visualizationChanges.supplierMigrations;
      if (this.initialSuppliers[candidate.name] === candidate.supplier) {
        const supplierMigrationEntryIndex = supplierMigrations.findIndex(
          (item) => item.name === candidate.name
        );
        supplierMigrations.splice(supplierMigrationEntryIndex, 1);
        this.setLocalStorage("supplierMigrations", supplierMigrations);
        this.setShowVisualizationChangesSummary();
        return;
      }
      supplierMigrations.push({
        name: candidate.name,
        supplier: candidate.supplier,
      });
      this.setLocalStorage("supplierMigrations", supplierMigrations);
      this.setShowVisualizationChangesSummary();
    },
    labelFromDefinition(column) {
      if (!this.data.collection || !this.data.collection.fields) {
        return column.title;
      }
      return (
        this.data.collection.fields.find(
          field => column.name.match(`^${field.name}$`)
        ) || {label: column.title}
      ).label;
    },
    renderedDefinition(columnName) {
      if (!this.data.collection || !this.data.collection.fields) {
        return "";
      }
      return this.mdConverter.makeHtml(
        (
          this.data.collection.fields.find(
            field => columnName.match(`^${field.name}$`)
          ) || {description: ''}
        ).description || ''
      ).replace(
        '<table>',
        '<table class="table table-sm" style="white-space: nowrap; width: 1%">'
      );
    },
    async getCollections() {
      try {
        const args = `token=${this.backendToken}`;
        const url = `${this.backendUrl}/collections/?${args}`;
        const response = await fetch(url);
        const data = (await httpHelper.safeJson(response)).data;
        return { data, error: null };
      } catch {
        return { data: null, error: "Couldn't fetch collection list." };
      }
    },
    reload: () => location.reload(),
    async changeCollection() {
      this.collection.isSaving = true;
      try {
        const args = `token=${this.backendToken}`;
        const collection = this.collection.choice;
        const urlBase = `change-collection/${this.taskId()}/${collection}`;
        const url = `${this.backendUrl}/${urlBase}/?${args}`;
        const response = await fetch(url, { method: "POST" });
        if (response.status === 200) {
          this.collection.error = null;
          this.reload();
        } else {
          this.collection.error = "Couldn't save collection reassignment.";
        }
      } catch {
        this.collection.error = "Couldn't save collection reassignment.";
      } finally {
        this.collection.isSaving = false;
      }
    },
    editFilename() {
      this.newFilename = this.filename;
    },
    async saveFilenameChange() {
      this.savingFilenameChange = true;
      let urlBase = `save-filename-change/${this.taskId()}`;
      const url = `${this.backendUrl}/${urlBase}/?token=${this.backendToken}`;
      const body = JSON.stringify({ filename: this.newFilename });
      await fetch(url, { method: "POST", body });
      this.filename = this.newFilename;
      window.app.$refs.navbar.filename = this.newFilename;
      this.newFilename = null;
      this.savingFilenameChange = false;
    },
    cancelFilenameEdition() {
      this.newFilename = null;
    },
    editDescription() {
      this.newDescription = this.description;
      this.isDescriptionEditing = true;
    },
    async saveDescriptionChange() {
      this.savingDescriptionChange = true;
      let urlBase = `save-description-change/${this.taskId()}`;
      const url = `${this.backendUrl}/${urlBase}/?token=${this.backendToken}`;
      const body = JSON.stringify({ description: this.newDescription });
      await fetch(url, { method: "POST", body });
      this.description = this.newDescription;
      this.newDescription = null;
      this.savingDescriptionChange = false;
      this.isDescriptionEditing = false;
    },
    cancelDescriptionEdition() {
      this.isDescriptionEditing = false;
      this.newDescription = null;
    },
    async saveRelevancy() {
      this.isSavingRelevancy = true;
      let urlBase = `mark-as-irrelevant/${this.taskId()}`;
      if (this.isIrrelevant) {
        urlBase = `mark-as-relevant/${this.taskId()}`;
      }
      const url = `${this.backendUrl}/${urlBase}/?token=${this.backendToken}`;
      await fetch(url, { method: "POST" });
      this.isIrrelevant = !this.isIrrelevant;
      this.isSavingRelevancy = false;
    },
    columnEntryClasses(columnName, supplier) {
      return {
        "cursor-pointer": !this.columnsToHide.includes(columnName),
        "cursor-not-allowed": this.columnsToHide.includes(columnName),
        [`row-${supplier}`]: true,
        'row-hidden': this.columnsToHide.includes(columnName),
      };
    },
    columnClicked(clickType, column, group) {
      if (this.columnsToHide.includes(column.name)) {
        return;
      }
      this.$emit("columnClicked", clickType, column, group);
    },
    removeIfExists(array, item) {
      const indexToRemove = array.indexOf(item);
      if (indexToRemove !== -1) {
        array.splice(indexToRemove, 1);
      }
    },
    addIfNotExists(array, item) {
      if (!array.includes(item)) {
        array.push(item);
      }
    },
    isGroupIndeterminate(groupColumns) {
      const columnsToShow = groupColumns.filter((column) =>
        this.columnsToShow.includes(column.name)
      );
      if (columnsToShow.length === 0) {
        return false;
      }
      if (columnsToShow.length === groupColumns.length) {
        return false;
      }
      return true;
    },
    updateScroller(start, end) {
      this.data.originalHeader
        .filter((...[, index]) => index >= start && index <= end)
        .forEach(this.updateGroupIndeterminate);
    },
    updateGroupIndeterminate(columnName) {
      if (!this.deployAll) {
        return;
      }
      const groupColumns = this.columnsWithGroups[columnName].group.columns;
      const isIndeterminate = this.isGroupIndeterminate(groupColumns);
      groupColumns.forEach((column) => {
        const refKey = `${column.name}GroupCheckbox`;
        if (!(refKey in this.$refs)) {
          return;
        }
        const ref = this.$refs[refKey];
        if (!ref) {
          return;
        }
        const input = ref.$el.getElementsByTagName("input")[0];
        input.indeterminate = isIndeterminate;
      });
    },
    setShowVisualizationChangesSummary() {
      if (!this.deployAll) {
        this.showVisualizationChangesSummary = false;
        return;
      }
      if (this.visualizationChanges.columnsToHide.length > 0) {
        this.showVisualizationChangesSummary = true;
        return;
      }
      if (this.visualizationChanges.columnsToShow.length > 0) {
        this.showVisualizationChangesSummary = true;
        return;
      }
      if (this.visualizationChanges.supplierMigrations.length > 0) {
        this.showVisualizationChangesSummary = true;
        return;
      }
      this.showVisualizationChangesSummary = false;
    },
    showColumnSwitched(switchedColumnName) {
      this.visualizationChanges.columnsToHide =
        this.initialColumnsToShow.filter(
          (columnName) => !this.columnsToShow.includes(columnName)
        );
      this.setLocalStorage(
        "columnsToHide",
        this.visualizationChanges.columnsToHide
      );
      this.visualizationChanges.columnsToShow = this.columnsToShow.filter(
        (columnName) => !this.initialColumnsToShow.includes(columnName)
      );
      this.setLocalStorage(
        "columnsToShow",
        this.visualizationChanges.columnsToShow
      );
      if (this.columnsToShow.includes(switchedColumnName)) {
        this.removeIfExists(this.columnsToHide, switchedColumnName);
        this.$emit("columnShown", switchedColumnName);
      } else {
        this.addIfNotExists(this.columnsToHide, switchedColumnName);
        this.$emit("columnHidden", switchedColumnName);
      }
      this.updateGroupIndeterminate(switchedColumnName);
      this.setShowVisualizationChangesSummary();
    },
    showGroupSwitched(switchedColumnName) {
      if (this.columnsToShow.includes(switchedColumnName)) {
        this.columnsWithGroups[switchedColumnName].group.columns.forEach(
          (column) => {
            this.addIfNotExists(this.columnsToShow, column.name);
            this.showColumnSwitched(column.name);
          }
        );
        return;
      }
      this.columnsWithGroups[switchedColumnName].group.columns.forEach(
        (column) => {
          this.removeIfExists(this.columnsToShow, column.name);
          this.showColumnSwitched(column.name);
        }
      );
    },
    async applyChanges() {
      this.isApplyingChanges = true;
      const urlBase = `apply-visualization-changes/${this.taskId()}`;
      const url = `${this.backendUrl}/${urlBase}/?token=${this.backendToken}`;
      const body = JSON.stringify({ ...this.visualizationChanges });
      await fetch(url, { method: "POST", body });
      this.initialColumnsToShow = [...this.columnsToShow];
      this.visualizationChanges.columnsToHide = [];
      this.deleteLocalStorage("columnsToHide");
      this.visualizationChanges.columnsToShow = [];
      this.deleteLocalStorage("columnsToShow");
      this.visualizationChanges.supplierMigrations = [];
      this.deleteLocalStorage("supplierMigrations");
      this.setShowVisualizationChangesSummary();
      this.isApplyingChanges = false;
      this.$bvModal.hide("apply-changes");
      this.$bvModal.show("applying-changes-succeeded");
      this.applyChangesTitle = "Changes to apply";
    },
    unapplyChanges() {
      this.visualizationChanges.columnsToHide.forEach((columnName) =>
        this.$emit("columnShown", columnName)
      );
      this.visualizationChanges.columnsToHide = [];
      this.deleteLocalStorage("columnsToHide");
      this.visualizationChanges.columnsToShow.forEach((columnName) =>
        this.$emit("columnHidden", columnName)
      );
      this.visualizationChanges.columnsToShow = [];
      this.deleteLocalStorage("columnsToShow");
      const filteredHeaderMapping = Object.fromEntries(
        this.filteredOriginalHeaderToShow.map((column) => [
          column.entry.name,
          column.entry,
        ])
      );
      this.visualizationChanges.supplierMigrations.forEach(
        (migration) =>
          (filteredHeaderMapping[migration.name].supplier =
            migration.supplier === "customer" ? "profound" : "customer")
      );
      this.visualizationChanges.supplierMigrations = [];
      this.deleteLocalStorage("supplierMigrations");
      this.columnsToShow = [...this.initialColumnsToShow];
      this.columnsToHide = this.data.originalHeader.filter(
        (columnName) => !this.columnsToShow.includes(columnName)
      );
      this.data.originalHeader.forEach(this.updateGroupIndeterminate);
      this.setShowVisualizationChangesSummary();
      this.$bvModal.hide("apply-changes");
      this.applyChangesTitle = "Changes to apply";
    },
    isHistogramEntryFailure(entry) {
      return parseInt(entry.columnCount) !== this.data.columnCount;
    },
  },
};
</script>

<style scoped>
section {
  height: 100%;
  flex: 1;
  display: flex;
  flex-flow: column;
}

.cell-column-no {
  width: 45px;
  min-width: 45px;
  max-width: 45px;
}
.cell-column-name {
  width: 200px;
  min-width: 200px;
  max-width: 200px;
}
.cell-column-label {
  width: 200px;
  min-width: 200px;
  max-width: 200px;
}
.cell-column-group {
  width: 200px;
  min-width: 200px;
  max-width: 200px;
}
.cell-column-type {
  width: 120px;
  min-width: 120px;
  max-width: 120px;
}
.cell-column-num {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
}
.cell-column-group-edit {
  width: 75px;
  min-width: 75px;
  max-width: 75px;
  margin-top: -15px;
  height: 20px;
}
.cell-supplier {
  width: 170px;
  min-width: 170px;
  max-width: 170px;
}
.cell-show-hide {
  width: 150px;
  min-width: 150px;
  max-width: 150px;
}
.cell-show-hide-entry {
  width: 75px;
  min-width: 75px;
  max-width: 75px;
}

th {
  white-space: nowrap;
}

th {
  font-weight: 900;
}
.row-customer,
.row-customer:hover {
  color: #3e4c51;
  font-weight: bold;
}
.row-profound,
.row-profound:hover {
  color: #d12239;
  font-weight: bold;
}
.row-hidden,
.row-hidden:hover {
  text-decoration: line-through black;
}

.description-field {
  white-space: pre-wrap;
  text-align: justify;
  max-height: 100px;
  overflow-y: auto;
}

.draggable {
  cursor: move;
}
.dragging {
  opacity: 0.5;
}

.admin-section {
  background-color: #ffeeba;
}
.meta-data {
  width: 100%;
  overflow: hidden;
}
.meta-data .meta-section {
  border-bottom-color: rgb(62, 76, 81, 0.3);
  border-bottom-width: 1px;
  border-block-end-style: solid;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-left: 5px;
  padding-right: 5px;
}
.meta-data .meta-feature {
  margin-bottom: 2px;
}
.meta-data .meta-feature .meta-key {
  float: left;
  width: 23%;
  font-weight: bold;
}
.meta-data .meta-feature .meta-value {
  width: 77%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>

<style>
.dropdown-item:active > pre {
  color: white;
}
.radio-fill > label {
  width: 100%;
}
.radio-button-sm label {
  line-height: 1 !important;
}
</style>
