var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "LMap",
    {
      staticStyle: { height: "550px" },
      attrs: { center: [20, 0], zoom: 2, options: _vm.mapOptions }
    },
    [
      _c("LTileLayer", {
        attrs: { url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" }
      }),
      _vm._l(_vm.locations, function(entry) {
        return _c(
          "LMarker",
          {
            key: _vm.coordinatesFormatted(entry.location),
            attrs: { "lat-lng": _vm.coordinates(entry.location) }
          },
          [
            _c("LPopup", [
              _c(
                "div",
                [
                  _vm._v(" IPs: "),
                  _vm._l(entry.ips, function(ip) {
                    return _c("div", { key: ip }, [
                      _c("strong", [_vm._v(_vm._s(ip))])
                    ])
                  })
                ],
                2
              ),
              _c("div", [
                _vm._v(" Coordinates: "),
                _c("strong", [
                  _vm._v(_vm._s(_vm.coordinatesFormatted(entry.location)))
                ])
              ]),
              _c("div", [
                _vm._v(" Location: "),
                _c(
                  "strong",
                  [
                    entry.location.city
                      ? [_vm._v(" " + _vm._s(entry.location.city) + ", ")]
                      : _vm._e(),
                    _vm._v(" " + _vm._s(entry.location.country) + " ")
                  ],
                  2
                )
              ])
            ])
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }