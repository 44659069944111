var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "table",
      {
        staticClass:
          "table table-borderless table-sm table-hover justify-content-end"
      },
      [
        _vm._m(0),
        _c(
          "tbody",
          [
            _vm._l(_vm.group.columns, function(column) {
              return [
                !column.isHidden
                  ? _c(
                      "tr",
                      {
                        key: column.name,
                        on: {
                          click: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "left", 37, $event.key, [
                                "Left",
                                "ArrowLeft"
                              ])
                            ) {
                              return null
                            }
                            if ("button" in $event && $event.button !== 0) {
                              return null
                            }
                            $event.stopPropagation()
                            return _vm.columnClicked("left", column)
                          },
                          mouseup: function($event) {
                            if ("button" in $event && $event.button !== 1) {
                              return null
                            }
                            $event.stopPropagation()
                            return _vm.columnClicked("middle", column)
                          }
                        }
                      },
                      [
                        _c(
                          "td",
                          {
                            class: _vm.columnEntryClasses(column.supplier),
                            staticStyle: { "max-width": "300px" },
                            attrs: {
                              "data-test": "column-statistics_" + column.name,
                              title: column.title
                            }
                          },
                          [_vm._v(" " + _vm._s(column.title) + " ")]
                        ),
                        _c(
                          "td",
                          [
                            "trueValue" in column && "falseValue" in column
                              ? _c(
                                  "b-progress",
                                  {
                                    attrs: {
                                      id: "distribution-count-" + column.name
                                    }
                                  },
                                  [
                                    _c(
                                      "b-progress-bar",
                                      {
                                        attrs: {
                                          value: column.fillRate,
                                          variant: "success"
                                        }
                                      },
                                      [
                                        _c(
                                          "big",
                                          {
                                            staticClass:
                                              "progress-label font-weight-bold"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(column.numFills) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c("b-progress-bar", {
                                      staticStyle: {
                                        "background-color": "#57636f"
                                      },
                                      attrs: { value: 100 - column.fillRate }
                                    })
                                  ],
                                  1
                                )
                              : _vm.showNonZeroRate(column)
                              ? _c(
                                  "b-progress",
                                  [
                                    _c(
                                      "b-progress-bar",
                                      {
                                        attrs: {
                                          value: _vm.nonZeroRate(column),
                                          variant: "info"
                                        }
                                      },
                                      [
                                        _c(
                                          "big",
                                          {
                                            staticClass:
                                              "progress-label font-weight-bold"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.intComma(
                                                    _vm.nonZeroCount(column)
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c("b-progress-bar", {
                                      staticStyle: {
                                        "background-color": "#57636f"
                                      },
                                      attrs: {
                                        value: _vm.zeroRate(column),
                                        variant: "secondary"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _c(
                                  "b-progress",
                                  [
                                    _c(
                                      "b-progress-bar",
                                      {
                                        attrs: {
                                          value: column.fillRate,
                                          variant: "primary"
                                        }
                                      },
                                      [
                                        _c(
                                          "big",
                                          {
                                            staticClass:
                                              "progress-label font-weight-bold"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(column.numFills) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c("b-progress-bar", {
                                      attrs: {
                                        value: _vm.leftFillRate(
                                          column.fillRate
                                        ),
                                        variant: "secondary"
                                      }
                                    })
                                  ],
                                  1
                                ),
                            "trueValue" in column && "falseValue" in column
                              ? _c(
                                  "b-popover",
                                  {
                                    attrs: {
                                      target:
                                        "distribution-count-" + column.name,
                                      triggers: "hover",
                                      placement: "top"
                                    }
                                  },
                                  [
                                    _c("div", [
                                      _c("span", {
                                        staticClass: "px-2 mr-2 bg-success"
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(column.trueValue) +
                                          ": " +
                                          _vm._s(column.numFills) +
                                          " "
                                      )
                                    ]),
                                    _c("div", [
                                      _c("span", {
                                        staticClass: "px-2 mr-2 bg-secondary",
                                        staticStyle: {
                                          "background-color": "#57636f"
                                        }
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(column.falseValue) +
                                          ": " +
                                          _vm._s(
                                            _vm.intComma(
                                              _vm.leftFillValues(
                                                column.numFills
                                              )
                                            )
                                          ) +
                                          " "
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            "trueValue" in column && "falseValue" in column
                              ? [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        Number(
                                          Number(column.fillRate).toFixed(2)
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              : _vm.showNonZeroRate(column)
                              ? [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.nonZeroRate(column).toFixed(2)
                                      ) +
                                      " "
                                  )
                                ]
                              : [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        Number(
                                          Number(column.fillRate).toFixed(2)
                                        )
                                      ) +
                                      " "
                                  )
                                ],
                            "trueValue" in column && "falseValue" in column
                              ? _c(
                                  "b-popover",
                                  {
                                    attrs: {
                                      target:
                                        "distribution-fill-rate-" + column.name,
                                      triggers: "hover",
                                      placement: "top"
                                    }
                                  },
                                  [
                                    _c("div", [
                                      _c("span", {
                                        staticClass: "px-2 mr-2 bg-success"
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(column.trueValue) +
                                          ": " +
                                          _vm._s(column.numFills) +
                                          " (" +
                                          _vm._s(column.fillRate) +
                                          "%) "
                                      )
                                    ]),
                                    _c("div", [
                                      _c("span", {
                                        staticClass: "px-2 mr-2 bg-secondary",
                                        staticStyle: {
                                          "background-color": "#57636f"
                                        }
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(column.falseValue) +
                                          ": " +
                                          _vm._s(
                                            _vm.intComma(
                                              _vm.leftFillValues(
                                                column.numFills
                                              )
                                            )
                                          ) +
                                          " (" +
                                          _vm._s(
                                            _vm.leftFillRate(column.fillRate)
                                          ) +
                                          "%) "
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ],
                          2
                        ),
                        _c("td", { staticClass: "text-right text-nowrap" }, [
                          _vm._v(" " + _vm._s(column.numUniques) + " ")
                        ]),
                        column.minLen > 0
                          ? [
                              _c(
                                "td",
                                { staticClass: "text-right text-nowrap" },
                                [_vm._v(_vm._s(column.minLen))]
                              )
                            ]
                          : [
                              _c(
                                "th",
                                {
                                  staticClass:
                                    "text-right text-nowrap alert-danger text-danger"
                                },
                                [_vm._v(" " + _vm._s(column.minLen) + " ")]
                              )
                            ],
                        _c("td", { staticClass: "text-right text-nowrap" }, [
                          _vm._v(" " + _vm._s(column.maxLen) + " ")
                        ]),
                        _c("td", { staticClass: "text-right text-nowrap" }, [
                          _vm._v(
                            " " + _vm._s(Number(column.avgLen).toFixed(2)) + " "
                          )
                        ])
                      ],
                      2
                    )
                  : _vm._e()
              ]
            })
          ],
          2
        )
      ]
    ),
    _c("div", { staticClass: "mt-auto" })
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "text-right text-nowrap" }, [
          _vm._v(" Column Name ")
        ]),
        _c("th", { staticClass: "count-col" }, [_vm._v(" Count ")]),
        _c("th", { staticClass: "pct-col" }, [_vm._v(" % ")]),
        _c("th", { staticClass: "text-right uniques-col" }, [
          _vm._v(" Uniques ")
        ]),
        _c("th", { staticClass: "text-right min-col" }, [_vm._v(" Min. ")]),
        _c("th", { staticClass: "text-right max-col" }, [_vm._v(" Max. ")]),
        _c("th", { staticClass: "text-right avg-col" }, [_vm._v(" Avg. ")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }