<template>
  <LMap
    :center="[20, 0]"
    :zoom="2"
    style="height: 550px"
    :options="mapOptions"
  >
    <LTileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
    <LMarker
      v-for="entry in locations"
      :key="coordinatesFormatted(entry.location)"
      :lat-lng="coordinates(entry.location)"
    >
      <LPopup>
        <div>
          IPs:
          <div
            v-for="ip in entry.ips"
            :key="ip"
          >
            <strong>{{ ip }}</strong>
          </div>
        </div>
        <div>
          Coordinates:
          <strong>{{ coordinatesFormatted(entry.location) }}</strong>
        </div>
        <div>
          Location:
          <strong>
            <template v-if="entry.location.city">
              {{ entry.location.city }}, 
            </template>
            {{ entry.location.country }}
          </strong>
        </div>
      </LPopup>
    </LMarker>
  </LMap>
</template>


<script>
  import {LMap, LTileLayer, LMarker, LPopup} from 'vue2-leaflet';
  import httpHelper from '../../modules/httpHelper.js';
  export default {
    components: {LMap, LTileLayer, LMarker, LPopup},
    props: {mostCommonValues: {type: Array, default: null}},
    data() {
      return {
        locations: [],
        locationsIndexes: {},
        mapOptions: {
          attributionControl: false,
          zoomControl: false,
          scrollWheelZoom: false,
          dragging: false
        }
      };
    },
    async mounted() {
      const backendUrl = process.env.VUE_APP_VISUALIZER_BACKEND_URL;
      const backendToken = process.env.VUE_APP_BACKEND_TOKEN;
      this.mostCommonValues.forEach(
        element => setTimeout(
          async () => {
            const urlBase = `location/${element.value}`;
            const url = `${backendUrl}/${urlBase}/?token=${backendToken}`;
            const response = await fetch(url);
            if (response.status !== 200) {
              return;
            }
            const location = await httpHelper.safeJson(response);
            const key = [location.latitude, location.longitude].toString();
            if (!(key in this.locationsIndexes)) {
              this.locationsIndexes[key] = (
                this.locations.push({location, ips: [element.value]})
              ) - 1;
              return;
            }
            this.locations[this.locationsIndexes[key]].ips.push(element.value);
          }, 0
        )
      );
    },
    methods: {
      coordinates: location => [location.latitude, location.longitude],
      latitudeFormatted: location => (
        `${Math.abs(location.latitude)}° ${location.latitude > 0 ? 'N' : 'S'}`
      ),
      longitudeFormatted: location => (
        `${Math.abs(location.longitude)}° ${location.longitude > 0 ? 'E' : 'W'}`
      ),
      coordinatesFormatted(location) {
        const latitudeFormatted = this.latitudeFormatted(location);
        const longitudeFormatted = this.longitudeFormatted(location);
        return `${latitudeFormatted}, ${longitudeFormatted}`;
      }
    }
  };
</script>
