<template>
  <div class="visualizations-tabs-container">
    <b-modal
      id="image-failed"
      size="lg"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
    >
      <h3>Error occurred while processing image!</h3>
      <div v-text="imageError" />
      <b-button
        class="ml-1 float-right"
        variant="primary"
        @click="$bvModal.hide('image-failed')"
        v-text="`OK`"
      />
    </b-modal>
    <b-card
      no-body
      class="visualizations-tabs-card pb-2"
    >
      <b-tabs
        v-model="activeTab"
        card
        lazy
        class="visualizations-tabs"
      >
        <GroupSummary
          data-test="visualization-summary"
          :initial-title="group.title"
          :group="group"
          :total-rows="totalRows"
          class="mx-3"
          @titleChanged="groupTitleChanged(group, ...arguments)"
          @columnClicked="columnClicked"
          @changeColumnOrder="$emit('changeColumnOrder', group)"
        />
        <div class="ml-5">
          <div
            v-for="([label, definition]) in columnsWithDefinitions()"
            :key="label"
          >
            <h5>{{ label }}</h5>
            <div
              class="text-muted mr-4 text-justify"
              v-html="definition"
            />
            <!--
              It looks like links are not used right now. Links were used while
              we were using statically parsed fields.yml DBI file on backend
              before introducing Data Definitions project. Leaving code below
              commented out for a quick reference to the context of links idea.
            -->
            <!-- <div
              v-if="group.data[columnName].links"
              class="text-muted"
            >
              Links:
              <ul>
                <li
                  v-for="link in group.data[columnName].links"
                  :key="link"
                >
                  <a :href="link">
                    {{ link }}
                  </a>
                </li>
              </ul>
            </div> -->
          </div>
          <div class="my-3 row" />
        </div>
        <img
          v-for="attachedImage in attachedImages"
          :key="attachedImage"
          :src="imageReference(attachedImage)"
          class="mx-5 my-2"
        >
        <div
          v-if="deployAll"
          :style="{display: 'block'}"
        >
          <div
            :style="{'height': '150px', 'position': 'absolute', 'width': 'calc(100% - 20px)'}"
            class="border rounded mb-1 px-1 ml-2"
            @dragover.stop.prevent="onDragOver"
            @dragleave="onDragLeave"
            @drop.stop.prevent="onDrop"
          >
            <div class="d-flex w-100">
              <div
                v-for="filename in uploadedImages"
                :key="filename"
                :title="imageReference(filename)"
                class="my-2 mx-1 border rounded d-flex align-items-start flex-column text-truncate position-relative"
                :style="{'width': '150px'}"
              >
                <img
                  :src="imageReference(filename)"
                  :style="{'width': '152px', 'height': '99px', 'object-fit': 'cover'}"
                  class="rounded-top"
                >
                <b-icon-x-circle-fill
                  class="position-absolute text-danger rounded-circle bg-light"
                  :style="{'right': '3px', 'top': '3px', 'cursor': 'pointer'}"
                  title="Remove this image from server"
                  @click="removeImage(filename)"
                />
                <div
                  class="input-group d-flex"
                  :style="{'height': '31px'}"
                >
                  <div
                    class="input-group-text flex-fill py-0"
                    :style="{
                      'border-top-left-radius': '0!important',
                      'border-top-right-radius': '0!important',
                    }"
                  >
                    <div class="flex-fill" />
                    <label class="form-check-label">
                      Attach
                    </label>
                    <input
                      :ref="`attach-check-${filename}`"
                      type="checkbox"
                      class="ml-2"
                      @input="saveImageAttachmentStatus(filename, $event.target)"
                    >
                    <div class="flex-fill" />
                  </div>
                </div>
              </div>
              <div
                class="flex-fill mx-1 my-2 border rounded d-flex"
                :style="{
                  'border-width': '3px!important',
                  'border-color': 'rgb(0, 0, 0, 0.2)!important',
                  'border-style': 'dashed!important',
                  'border-radius': '15px!important',
                  'height': '132px'
                }"
              >
                <div class="flex-fill" />
                <em class="my-auto text-muted">
                  <template v-if="draggedOver">
                    Drop to upload now <b-icon-upload />
                  </template>
                  <template v-else>Drag your images here</template>
                </em>
                <div class="flex-fill" />
              </div>
            </div>
          </div>
          <div
            :style="{
              'height': '150px',
              'background-color': 'rgb(255, 255, 255, .3)',
              'visibility': processingImages ? 'visible' : 'hidden'
            }"
            class="position-relative d-flex flex-column"
          >
            <div class="flex-fill" />
            <div class="d-flex">
              <div class="flex-fill" />
              <b-spinner />
              <div class="flex-fill" />
            </div>
            <div class="flex-fill" />
          </div>
        </div>
        <div ref="visualizations">
          <b-tab title="Table">
            <b-tabs
              v-model="activeColumn"
              pills
              lazy
              align="right"
              class="visualization-tabs"
            >
              <b-tab
                v-for="([columnName, column]) in allColumns()"
                :key="columnName"
                :title="column.label"
              >
                <b-form-group
                  v-if="correlationValues[columnName].length > 1"
                  :label="`Correlated with ${correlations[columnName].key}`"
                  label-cols="auto"
                >
                  <b-form-select
                    v-model="correlationValue[columnName]"
                    :options="correlationValues[columnName]"
                  />
                </b-form-group>
                <FileColumnMostCommonValuesTable
                  :label="column.label"
                  :type="column.type"
                  :most-common-values-checked="mostCommonValuesChecked[columnName]"
                  :most-common-values="allMostCommonValuesToShowBy(columnName)"
                  :ranges="group.data[columnName].ranges"
                  :value-tags="group.data[columnName].valueTags"
                  @checkedMostCommonValuesChanged="checkedMostCommonValuesChanged(columnName)(...arguments)"
                />
              </b-tab>
            </b-tabs>
          </b-tab>
          <b-tab
            v-if="nonKeyColumns().length > 0"
            title="Bar Chart"
          >
            <FileColumnMostCommonValuesGroupBarChart
              v-if="group.features.multiFeatureBarChart"
              :group="filteredMostCommonValuesToShow()"
            />
            <b-tabs
              v-else
              v-model="activeColumn"
              pills
              lazy
              align="right"
              class="visualization-tabs"
            >
              <b-tab
                v-for="([columnName, column]) in allColumns()"
                :key="columnName"
                :title="column.label"
                :disabled="!nonKeyColumns().includes(columnName)"
              >
                <b-form-group
                  v-if="correlationValues[columnName].length > 1"
                  :label="`Correlated with ${correlations[columnName].key}`"
                  label-cols="auto"
                >
                  <b-form-select
                    v-model="correlationValue[columnName]"
                    :options="correlationValues[columnName]"
                  />
                </b-form-group>
                <FileColumnMostCommonValuesBarChart
                  :type="column.type"
                  :most-common-values="mostCommonValuesToShowBy(columnName)"
                  :ranges="group.data[columnName].ranges"
                />
              </b-tab>
            </b-tabs>
          </b-tab>
          <b-tab
            v-if="nonKeyColumns().length > 0"
            title="Pie Chart"
          >
            <b-tabs
              v-model="activeColumn"
              pills
              lazy
              align="right"
              class="visualization-tabs"
            >
              <b-tab
                v-for="([columnName, column]) in allColumns()"
                :key="columnName"
                :title="column.label"
                :disabled="!nonKeyColumns().includes(columnName)"
              >
                <b-form-group
                  v-if="correlationValues[columnName].length > 1"
                  :label="`Correlated with ${correlations[columnName].key}`"
                  label-cols="auto"
                >
                  <b-form-select
                    v-model="correlationValue[columnName]"
                    :options="correlationValues[columnName]"
                  />
                </b-form-group>
                <FileColumnMostCommonValuesPieChart
                  :label="column.label"
                  :type="column.type"
                  :total-count="column.numValues"
                  :most-common-values="allMostCommonValuesToShowBy(columnName)"
                  :ranges="group.data[columnName].ranges"
                />
              </b-tab>
            </b-tabs>
          </b-tab>
          <!-- <b-tab
            v-if="group.features.multiFeatureStackedAreaChart"
            title="Area Chart"
          >
            <div
              v-for="([columnName,]) in allColumns()"
              :key="columnName"
              class="px-5"
            >
              <b-form-group
                v-if="correlationValues[columnName].length > 1"
                :label="`Correlated with ${correlations[columnName].key}`"
                label-cols="auto"
              >
                <b-form-select
                  v-model="correlationValue[columnName]"
                  :options="correlationValues[columnName]"
                />
              </b-form-group>
            </div>
            <FileColumnMostCommonValuesGroupAreaChart
              :group="mostCommonValuesToShow()"
            />
          </b-tab> -->
          <b-tab
            v-if="mapColumns().length > 0"
            title="Map"
          >
            <b-tabs
              v-model="activeColumn"
              pills
              lazy
              align="right"
              class="visualization-tabs"
            >
              <b-tab
                v-for="([columnName, column]) in allColumns()"
                :key="columnName"
                :title="column.label"
                :disabled="!mapColumns().includes(columnName)"
              >
                <b-form-group
                  v-if="correlationValues[columnName].length > 1"
                  :label="`Correlated with ${correlations[columnName].key}`"
                  label-cols="auto"
                >
                  <b-form-select
                    v-model="correlationValue[columnName]"
                    :options="correlationValues[columnName]"
                  />
                </b-form-group>
                <FileColumnMostCommonValuesIpMap
                  v-if="column.type === 'ip'"
                  :most-common-values="allMostCommonValuesToShowBy(columnName)"
                />
                <FileColumnMostCommonValuesCountryMap
                  v-else-if="column.type === 'country'"
                  :most-common-values="allMostCommonValuesToShowBy(columnName)"
                  :label="column.label"
                />
              </b-tab>
            </b-tabs>
          </b-tab>
          <b-tab
            v-if="hilbertMapColumns().length > 0"
            title="Hilbert Map"
          >
            <b-tabs
              v-model="activeColumn"
              pills
              lazy
              align="right"
              class="visualization-tabs"
            >
              <b-tab
                v-for="([columnName, column]) in allColumns()"
                :key="columnName"
                :title="column.label"
                :disabled="!hilbertMapColumns().includes(columnName)"
              >
                <b-form-group
                  v-if="correlationValues[columnName].length > 1"
                  :label="`Correlated with ${correlations[columnName].key}`"
                  label-cols="auto"
                >
                  <b-form-select
                    v-model="correlationValue[columnName]"
                    :options="correlationValues[columnName]"
                  />
                </b-form-group>
                <FileColumnMostCommonValuesHilbert :column-name="columnName" />
              </b-tab>
            </b-tabs>
          </b-tab>
          <b-tab
            v-if="hasCorrelations"
            title="Correlation Table"
          >
            <b-tabs
              v-model="activeColumn"
              pills
              lazy
              align="right"
              class="visualization-tabs"
            >
              <b-tab
                v-for="([columnName, correlation]) in correlationsArray"
                :key="columnName"
                :title="correlation.label"
                :disabled="!isCorrelationEnabled(correlation)"
              >
                <FileColumnMostCommonValuesCorrelationTable
                  :correlations="correlation"
                  @correlationChosen="changeCorrelationChoice(columnName, ...arguments)"
                />
              </b-tab>
            </b-tabs>
          </b-tab>
          <b-tab
            v-if="hasMapCorrelations"
            title="Correlation Map"
          >
            <b-tabs
              v-model="activeColumn"
              pills
              lazy
              align="right"
              class="visualization-tabs"
            >
              <b-tab
                v-for="([columnName, correlation]) in correlationsArray"
                :key="columnName"
                :title="correlation.label"
                :disabled="!isCorrelationEnabled(correlation)"
              >
                <FileColumnMostCommonValuesCorrelationCountryMap
                  :correlations="correlation"
                  @countryClicked="changeCorrelationChoice(columnName, ...arguments)"
                />
              </b-tab>
            </b-tabs>
          </b-tab>
          <b-tab
            v-if="hasTimelineCorrelations"
            title="Timeline"
          >
            <b-tabs
              v-model="activeColumn"
              pills
              lazy
              align="right"
              class="visualization-tabs"
            >
              <b-tab
                v-for="([columnName, correlation]) in timelineCorrelationsArray"
                :key="columnName"
                :title="correlation.label"
                :disabled="!isCorrelationEnabled(correlation)"
              >
                <FileColumnMostCommonValuesCorrelationLineChart
                  :correlations="filteredCorrelation(columnName, correlation)"
                />
              </b-tab>
            </b-tabs>
          </b-tab>
        </div>
      </b-tabs>
    </b-card>
  </div>
</template>


<script>

  import Humanize from 'humanize-plus';
  import showdown from 'showdown';
  import FileColumnMostCommonValuesBarChart from './FileColumnMostCommonValuesBarChart.vue';
  import FileColumnMostCommonValuesGroupBarChart from './FileColumnMostCommonValuesGroupBarChart.vue';
  import FileColumnMostCommonValuesPieChart from './FileColumnMostCommonValuesPieChart.vue';
  // import FileColumnMostCommonValuesGroupAreaChart from './FileColumnMostCommonValuesGroupAreaChart.vue';
  import FileColumnMostCommonValuesIpMap from './FileColumnMostCommonValuesIpMap.vue';
  import FileColumnMostCommonValuesCountryMap from './FileColumnMostCommonValuesCountryMap.vue';
  import FileColumnMostCommonValuesHilbert from './FileColumnMostCommonValuesHilbert.vue';
  import FileColumnMostCommonValuesTable from './FileColumnMostCommonValuesTable.vue';
  import FileColumnMostCommonValuesCorrelationTable from './FileColumnMostCommonValuesCorrelationTable.vue';
  import FileColumnMostCommonValuesCorrelationLineChart from './FileColumnMostCommonValuesCorrelationLineChart.vue';
  import FileColumnMostCommonValuesCorrelationCountryMap from './FileColumnMostCommonValuesCorrelationCountryMap.vue';
  import GroupSummary from './GroupSummary.vue'

  export default {
    components: {
      GroupSummary,
      FileColumnMostCommonValuesBarChart,
      FileColumnMostCommonValuesGroupBarChart,
      FileColumnMostCommonValuesPieChart,
      // FileColumnMostCommonValuesGroupAreaChart,
      FileColumnMostCommonValuesCountryMap, FileColumnMostCommonValuesIpMap,
      FileColumnMostCommonValuesHilbert, FileColumnMostCommonValuesTable,
      FileColumnMostCommonValuesCorrelationTable,
      FileColumnMostCommonValuesCorrelationLineChart,
      FileColumnMostCommonValuesCorrelationCountryMap
    },
    props: {
      group: {type: Object, default: null},
      totalRows: {type: Number, default: null}
    },
    data() {
      const mostCommonValuesChecked = Object.fromEntries(
        this.group.columns.map(
          column => [
            column.name, Array.from(
              new Set(
                this.group.data[column.name].mostCommon.map(
                  mostCommon => Array.from(
                    new Set(
                      mostCommon.correlation.map(
                        correlation => correlation.value
                      )
                    )
                  )
                ).flat()
              )
            )
          ]
        )
      );
      return {
        activeTab: 0,
        activeColumn: 0,
        correlationValue: null,
        mdConverter: new showdown.Converter({tables: true}),
        mostCommonValuesChecked,
        uploadedImages: [],
        draggedOver: false,
        imageError: null,
        attachedImages: [...this.group.attachedImages],
        processingImages: false,
      };
    },
    computed: {
      deployAll: () => ["true", "t", "yes", "y", "on"].includes(
        process.env.VUE_APP_DEPLOY_ALL.toLowerCase()
      ),
      backendUrl: () => process.env.VUE_APP_VISUALIZER_BACKEND_URL,
      backendToken: () => process.env.VUE_APP_BACKEND_TOKEN,
      taskId() { return this.$route.params.taskId; },
      correlationsArray() {
        return Object.entries(this.group.data).map(
          ([columnName, column]) => [
            columnName, {
              label: column.label,
              type: column.correlation.type,
              key: column.correlation.key,
              data: column.mostCommon
            }
          ]
        );
      },
      timelineCorrelationsArray() {
        return this.correlationsArray.filter(
          ([, correlation]) => 'Quarter' === correlation.key
        );
      },
      mapCorrelationsArray() {
        return this.correlationsArray.filter(
          ([, correlation]) => 'country' === correlation.type
        );
      },
      correlations() {
        return Object.fromEntries(this.correlationsArray);
      },
      correlationValues() {
        return Object.fromEntries(
          Object.entries(this.group.data).map(
            ([columnName, column]) => [
              columnName, column.mostCommon.map(
                (correlation, index) => {
                  const total = correlation.correlationTotal;
                  const humanizedTotal = Humanize.compactInteger(total, 1);
                  return {
                    value: index,
                    text: (
                      `${correlation.correlationValue} ` +
                      `(${humanizedTotal} total)`
                    ),
                    key: correlation.correlationValue,
                  };
                }
              )
            ]
          )
        );
      },
      hasCorrelations() {
        return this.correlationsArray.some(
          ([, correlation]) => this.isCorrelationEnabled(correlation)
        );
      },
      hasTimelineCorrelations() {
        return this.timelineCorrelationsArray.some(
          ([, correlation]) => this.isCorrelationEnabled(correlation)
        );
      },
      hasMapCorrelations() {
        return this.mapCorrelationsArray.some(
          ([, correlation]) => this.isCorrelationEnabled(correlation)
        );
      },
    },
    created() {
      this.correlationValue = Object.fromEntries(
        Object.keys(this.group.data).map(
          column => [
            column, this.correlationValues[column].findIndex(
              correlationValue => correlationValue.key === '<all>'
            )
          ]
        )
      );
    },
    async mounted() {
      if (!this.deployAll) {
        return;
      }
      const urlBase = `image/${this.taskId}`;
      const url = `${this.backendUrl}/${urlBase}/?token=${this.backendToken}`;
      const response = await fetch(url, { method: "GET" });
      if (response.ok) {
        this.uploadedImages = (await response.json() || {}).filenames || [];
        this.$nextTick(
          () => this.attachedImages.forEach(
            filename => this.$refs[`attach-check-${filename}`][0].checked = true
          )
        );
      }
    },
    methods: {
      onDragOver(event) {
        event.dataTransfer.dropEffect = 'copy';
        this.draggedOver = true;
      },
      onDragLeave() { this.draggedOver = false; },
      async onDrop(event) {
        this.imageError = null;
        this.draggedOver = false;
        this.processingImages = true;
        const files = event.dataTransfer.files;
        if (files.length > 0) {
          const nonImageFiles = [];
          await Promise.all(
            Array.from(files).map(
              file => {
                if (file.type.split('/')[0] === 'image') {
                  this.uploadImage(file.name, file);
                  return;
                }
                nonImageFiles.push(file.name);
              }
            )
          );
          if (nonImageFiles.length > 0) {
            this.imageError = (
              `The following files are not images: ${nonImageFiles.join(', ')}. Only images can be uploaded.`
            );
          }
        }
        this.processingImages = false;
        if (this.imageError) {
          this.$bvModal.show('image-failed');
        }
      },
      async uploadImage(filename, data) {
        this.processingImages = true;
        const urlBase = `image/${this.taskId}/${filename}`;
        const url = `${this.backendUrl}/${urlBase}/?token=${this.backendToken}`;
        const body = new FormData();
        body.append('image', data);
        const response = await fetch(url, { method: "POST", body });
        if (response.ok) {
          this.uploadedImages.push(filename);
        } else {
          const responseData = await response.json();
          if (responseData.errors) {
            this.imageError = `The following error occurred while uploading image ${filename}: ${responseData.errors[0]}`;
          } else {
            this.imageError = `Unknown error occurred while uploading image ${filename}.`;
          }
        }
        this.$nextTick(
          () => {
            const element = this.$refs[`attach-check-${filename}`][0];
            element.checked = true;
            this.saveImageAttachmentStatus(filename, element);
          }
        );
      },
      async removeImage(filename) {
        this.processingImages = true;
        const urlBase = `image/delete/${this.taskId}/${filename}`;
        const url = `${this.backendUrl}/${urlBase}/?token=${this.backendToken}`;
        const response = await fetch(url, { method: "POST" });
        if (response.ok) {
          this.uploadedImages = this.uploadedImages.filter(
            element => element !== filename
          );
        } else {
          const responseData = await response.json();
          if (responseData.errors) {
            this.imageError = (
              `The following error occurred while removing image ${filename}: ${responseData.errors[0]}`
            );
          } else {
            this.imageError = `Unknown error occurred while removing image ${filename}.`;
          }
          this.$bvModal.show('image-failed');
        }
        this.processingImages = false;
      },
      async saveImageAttachmentStatus(filename, target) {
        this.processingImages = true;
        let urlBase = `${target.checked ? 'at' : 'de'}tach-image/${this.taskId}`;
        const url = `${this.backendUrl}/${urlBase}/?token=${this.backendToken}`;
        const body = JSON.stringify({filename, groupName: this.group.name});
        const response = await fetch(url, { method: "POST", body });
        if (response.ok) {
          if (target.checked) {
            this.attachedImages.push(filename);
          } else {
            this.attachedImages = this.attachedImages.filter(
              element => element !== filename
            );
          }
        } else {
          target.checked = !target.checked;
        }
        this.processingImages = false;
      },
      imageReference(filename) {
        const urlBase = `image/${this.taskId}/${filename}`;
        return `${this.backendUrl}/${urlBase}/?token=${this.backendToken}`;
      },
      groupTitleChanged(group, title) {
        group.title = title;
        this.$emit('groupTitleChanged');
      },
      checkedMostCommonValuesChanged(columnName) {
        return (mostCommonValuesChecked, value) => {
          if (mostCommonValuesChecked.includes(value)) {
            this.mostCommonValuesChecked[columnName].push(value);
            return;
          }
          this.mostCommonValuesChecked[columnName].splice(
            this.mostCommonValuesChecked[columnName].indexOf(value), 1
          );
        };
      },
      filteredCorrelation(column, correlations) {
        const mostCommonValuesChecked = this.mostCommonValuesChecked[column];
        return correlations.data.map(
          correlationsEntry => (
            {
              ...correlationsEntry,
              correlation: correlationsEntry.correlation.filter(
                correlation => mostCommonValuesChecked.includes(
                  correlation.value
                )
              )
            }
          )
        );
      },
      definitionLabel(column) {
        return column.label || this.group.data[column.name].definition.label;
      },
      renderedDefinition(columnName) {
        const definition = this.group.data[columnName].definition;
        const mdCode = definition.description || '';
        return {
          pk: definition.pk,
          label: definition.label,
          description: this.mdConverter.makeHtml(mdCode).replace(
            '<table>',
            '<table class="table table-sm" style="white-space: nowrap; width: 1%">'
          )
        };
      },
      columnClicked(...[, clickedColumn]) {
        this.activeColumn = this.allColumns().findIndex(
          column => column[0] === clickedColumn.name
        );
        this.$refs.visualizations.scrollIntoView();
      },
      allColumns() {
        return Object.entries(this.group.data).filter(
          ([, column]) => !column.isHidden
        );
      },
      nonKeyColumns() {
        return this.allColumns().filter(
          ([, column]) => column.type !== 'domain-key'
        ).map(([columnName,]) => columnName);
      },
      columnsWithDefinitions() {
        const result = [];
        this.allColumns().filter(
          ([, column]) => column.definition.description
        ).forEach(
          ([columnName,]) => {
            const rendered = this.renderedDefinition(columnName);
            if (result.map(element => element.pk).includes(rendered.pk)) {
              if (!rendered.label) {
                result.find(
                  element => element.pk === rendered.pk
                ).label += `, ${columnName}`;
              }
              return;
            }
            if (!rendered.label) {
              rendered.label = columnName;
            }
            result.push(rendered);
          }
        );
        return result.map(element => [element.label, element.description]);
      },
      mapColumns() {
        return this.allColumns().filter(
          ([, column]) => ['ip', 'country'].includes(column.type)
        ).map(([columnName,]) => columnName);
      },
      hilbertMapColumns() {
        return this.allColumns().filter(
          ([, column]) => column.type === 'ip'
        ).map(([columnName,]) => columnName);
      },
      mostCommonValuesToShow() {
        const allMostCommonValues = Object.values(this.group.data).map(
          columnEntry => [
            columnEntry.label, columnEntry.mostCommon[0].correlation
          ]
        );
        return Object.fromEntries(allMostCommonValues);
      },
      allMostCommonValuesToShowBy(column) {
        const allMostCommonValues = this.group.data[column].mostCommon;
        const correlationValue = this.correlationValue[column];
        return allMostCommonValues[correlationValue].correlation;
      },
      mostCommonValuesToShowBy(column) {
        const allMostCommonValues = this.group.data[column].mostCommon;
        const correlationValue = this.correlationValue[column];
        const correlation = allMostCommonValues[correlationValue].correlation;
        const mostCommonValuesChecked = this.mostCommonValuesChecked[column];
        return correlation.filter(
          element => mostCommonValuesChecked.includes(element.value)
        );
      },
      totalCount(columnName, column) {
        const allMostCommonValues = this.group.data[columnName].mostCommon;
        const correlationValue = this.correlationValue[columnName];
        const correlation = allMostCommonValues[correlationValue].correlation;
        const mostCommonValuesChecked = this.mostCommonValuesChecked[columnName];
        return column.numValues - correlation.filter(
          element => !mostCommonValuesChecked.includes(element.value)
        ).reduce((accumulator, element) => accumulator + element.count, 0);
      },
      filteredMostCommonValuesToShow() {
        var result = Object.entries(this.mostCommonValuesToShow());
        const showOnly = this.group.features.showOnly;
        if (!showOnly) {
          return result;
        }
        return Object.fromEntries(
          result.map(
            ([label, mostCommon]) => [
              label, mostCommon.filter(entry => showOnly.includes(entry.value))
            ]
          )
        );
      },
      isCorrelationEnabled: correlation => correlation.data.length > 1,
      changeCorrelationChoice(column, correlationValue) {
        this.activeTab = 0;
        const correlationValues = this.correlationValues[column];
        this.correlationValue[column] = correlationValues.findIndex(
          element => element.key === correlationValue
        );
      }
    },
  };
</script>

<style>
  .visualizations-tabs {
    min-height: 600px;
    max-height: calc(100vh - 13rem);
  }
  .visualizations-tabs-container,
  .visualizations-tabs-card,
  .visualizations-tabs .tabs {
    flex: 1;
    display: flex;
    flex-flow: column;
  }
  .visualizations-tabs .tab-content {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    contain: strict;
  }
  .visualizations-tabs .tab-pane {
    min-height: initial!important;
  }
  .visualization-tabs .tabs,
  .visualization-tabs .tab-content,
  .visualization-tabs .tab-pane {
    flex: initial!important;
    display: initial!important;
    flex-flow: initial!important;
    overflow-y: initial!important;
    overflow-x: initial!important;
    height: initial!important;
    contain: initial!important;
  }
  .visualization-tabs .tab-pane {
    min-height: initial!important;
  }
  .visualizations-tabs .tab-content {
    padding-top: 16px;
  }
</style>
