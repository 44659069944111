<template>
  <table class="table table-sm table-striped table-hover">
    <thead>
      <tr>
        <th class="col-show-on-charts text-center">
          Show<br>on Charts
        </th>
        <th
          v-if="type === 'domain' || type === 'domain-key'"
          class="w-fit"
        >
          Icon
        </th>
        <th
          v-else-if="type === 'country'"
          class="w-fit"
        >
          Flag
        </th>
        <th v-if="type === 'domain-key'">
          Value
        </th>
        <th v-else>
          Most common value
        </th>
        <th
          v-if="type !== 'domain-key'"
          class="text-right w-fit"
        >
          Count
        </th>
        <th class="text-right w-fit">
          <template v-if="type === 'domain-key'">
            Rank by DomainRank™
          </template>
          <template v-else>
            Share
          </template>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="entry in mostCommonValues"
        :key="entry.value"
      >
        <td class="col-show-on-charts text-center">
          <b-form-checkbox
            v-model="checkedMostCommonValues"
            :value="entry.value"
            @change="$emit('checkedMostCommonValuesChanged', checkedMostCommonValues, entry.value)"
          />
        </td>
        <td
          v-if="type === 'domain' || type === 'domain-key'"
          class="w-fit"
        >
          <img
            :src="`${backendUrl}/favicon/${entry.value}/?token=${backendToken}`"
            class="symbol"
            onerror="this.classList.add('d-none')"
          >
        </td>
        <td v-else-if="type === 'country'">
          <span
            :class="`flag-icon flag-icon-${countryCode(entry.value)} border`"
          />
        </td>
        <td
          v-if="type === 'url' && entry.value"
          class="text-break"
        >
          <div
            v-if="valueTags && valueTags[entry.value] && valueTags[entry.value].text"
            class="badge badge-primary mr-2"
            :style="{'background-color': valueTags[entry.value].color || '#3e4c51'}"
            v-text="valueTags[entry.value].text"
          />
          <a
            :href="entry.value"
            target="_blank"
          >{{ entry.value }}</a>
        </td>
        <td
          v-if="type === 'vulnerability' && entry.value"
          class="text-break"
        >
          <div
            v-if="valueTags && valueTags[entry.value] && valueTags[entry.value].text"
            class="badge badge-primary mr-2"
            :style="{'background-color': valueTags[entry.value].color || '#3e4c51'}"
            v-text="valueTags[entry.value].text"
          />
          <a
            :href="`https://nvd.nist.gov/vuln/detail/${entry.value}`"
            target="_blank"
          >{{ entry.value }}</a>
        </td>
        <td
          v-else-if="entry.value"
          class="text-break"
        >
          <div
            v-if="type === 'color'"
            class="symbol"
            :style="color(entry.value)"
          />
          <div
            v-else-if="valueTags && valueTags[entry.value] && valueTags[entry.value].text"
            class="badge badge-primary mr-2"
            :style="{'background-color': valueTags[entry.value].color || '#3e4c51'}"
            v-text="valueTags[entry.value].text"
          />
          {{ entry.value }}
        </td>
        <td
          v-else
          class="text-break"
        >
          <div
            v-if="valueTags && valueTags[entry.value] && valueTags[entry.value].text"
            class="badge badge-primary mr-2"
            :style="{'background-color': valueTags[entry.value].color || '#3e4c51'}"
            v-text="valueTags[entry.value].text"
          />
          <em>empty</em>
        </td>
        <td
          v-if="type !== 'domain-key'"
          class="text-right w-fit"
        >
          {{ humanize(entry.count) }}
        </td>
        <td
          class="text-right w-fit"
        >
          <template v-if="type === 'domain-key'">
            {{ -entry.count }}
          </template>
          <template v-else>
            {{ entry.rate }}
          </template>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
  import Humanize from 'humanize-plus';
  export default {
    props: {
      label: {type: String, default: null},
      type: {type: String, default: 'string'},
      mostCommonValues: {type: Array, default: null},
      ranges: {type: Boolean, default: false},
      mostCommonValuesChecked: {type: Array, default: null},
      valueTags: {type: Object, default: null},
    },
    data() {
      const iconList = this.mostCommonValues.map(entry => [entry.value, null]);
      const checkedMostCommonValues = this.mostCommonValuesChecked;
      return {icons: Object.fromEntries(iconList), checkedMostCommonValues};
    },
    computed: {
      backendUrl: () => process.env.VUE_APP_VISUALIZER_BACKEND_URL,
      backendToken: () => process.env.VUE_APP_BACKEND_TOKEN,
    },
    methods: {
      humanize: Humanize.intComma,
      countryCode(value) {
        if (!this.label.toLowerCase().includes('lang')) {
          return value.toLowerCase().substr(0, 2);
        }
        const split = value.toLowerCase().split('-');
        return split[split.length - 1];
      },
      parseIntValue(value) {
        if (value === 'inf') {
          return Infinity;
        }
        return parseInt(value);
      },
      color(value) {
        const colors = {
          Y: {'background-color': '#FF0'},
          R: {'background-color': '#F00'},
          G: {'background-color': '#649600'},
          VG: {'background-color': '#0F0'}
        };
        if (value in colors) {
          return colors[value];
        }
        return {opacity: 0};
      },
    }
  };
</script>

<style scoped>
  .col-show-on-charts { width: 80px; }
  .symbol {
    width: 20px;
    height: 20px;
    float: left;
    margin-right: 10px;
  }
</style>
