var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      ref: "container",
      staticClass: "row text-decoration-none text-primary cursor-pointer"
    },
    [
      _vm.editingTitle
        ? _c(
            "form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.changeTitle($event)
                }
              }
            },
            [
              _c(
                "b-input-group",
                {
                  ref: "titleInput",
                  staticClass: "col ml-1 mt-2 mb-2",
                  style: { height: "38px" },
                  attrs: { disabled: _vm.saving },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                    }
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "append",
                        fn: function() {
                          return [
                            _c("b-button", {
                              attrs: {
                                variant: "outline-secondary",
                                disabled: _vm.saving
                              },
                              domProps: { textContent: _vm._s("Cancel") },
                              on: {
                                click: function($event) {
                                  _vm.editingTitle = false
                                }
                              }
                            }),
                            _c(
                              "b-button",
                              {
                                attrs: {
                                  variant: "primary",
                                  type: "submit",
                                  "data-test":
                                    "change-group-title-submit_" +
                                    _vm.group.name,
                                  disabled: _vm.saving
                                }
                              },
                              [
                                _vm.saving
                                  ? [
                                      _vm._v(" Saving... "),
                                      _c("b-spinner", {
                                        staticClass: "mb-1",
                                        attrs: { small: "" }
                                      })
                                    ]
                                  : [_vm._v(" Save ")]
                              ],
                              2
                            )
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    4112193557
                  )
                },
                [
                  _c("b-input", {
                    attrs: {
                      "data-test":
                        "change-group-title-text-input_" + _vm.group.name,
                      placeholder: "Group Title",
                      disabled: _vm.saving
                    },
                    model: {
                      value: _vm.title,
                      callback: function($$v) {
                        _vm.title = $$v
                      },
                      expression: "title"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "col mb-2" },
            [
              _c(
                "h2",
                { attrs: { "data-test": "group-title" } },
                [
                  _c("span", {
                    attrs: { "data-test": "group-title_" + _vm.group.name },
                    domProps: { innerHTML: _vm._s(_vm.initialTitle) }
                  }),
                  _vm.deployAll
                    ? _c("b-icon-pencil", {
                        staticClass: "ml-3",
                        attrs: {
                          "data-test":
                            "change-group-title-link_" + _vm.group.name
                        },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.editTitle($event)
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm.deployAll
                ? _c(
                    "b-button",
                    {
                      staticClass: "ml-1",
                      attrs: {
                        size: "sm",
                        variant: "outline-secondary",
                        "data-test": "change-column-order-" + _vm.group.name
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.$emit("changeColumnOrder")
                        }
                      }
                    },
                    [_vm._v(" Change Order of Columns in this Group ")]
                  )
                : _vm._e()
            ],
            1
          ),
      _c("FileColumnStatistics", {
        staticClass: "col mr-3",
        attrs: { "total-rows": _vm.totalRows, group: _vm.group },
        on: { columnClicked: _vm.columnClicked }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }