<template>
  <div>
    <table class="table table-borderless table-sm table-hover justify-content-end">
      <thead>
        <tr>
          <th class="text-right text-nowrap">
            Column Name
          </th>
          <th class="count-col">
            Count
          </th>
          <th class="pct-col">
            %
          </th>
          <th class="text-right uniques-col">
            Uniques
          </th>
          <th class="text-right min-col">
            Min.
          </th>
          <th class="text-right max-col">
            Max.
          </th>
          <th class="text-right avg-col">
            Avg.
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-for="column in group.columns">
          <tr v-if="!column.isHidden" :key="column.name" @click.left.stop="columnClicked('left', column)"
            @click.middle.stop="columnClicked('middle', column)">
            <td :class="columnEntryClasses(column.supplier)" :data-test="`column-statistics_${column.name}`"
              :title="column.title" style="max-width: 300px">
              {{ column.title }}
            </td>
            <td>
              <b-progress v-if="'trueValue' in column && 'falseValue' in column"
                :id="`distribution-count-${column.name}`">
                <b-progress-bar :value="column.fillRate" variant="success">
                  <big class="progress-label font-weight-bold">
                    {{ column.numFills }}
                  </big>
                </b-progress-bar>
                <b-progress-bar :value="100 - column.fillRate" style="background-color: #57636f" />
              </b-progress>
              <b-progress v-else-if="showNonZeroRate(column)">
                <b-progress-bar :value="nonZeroRate(column)" variant="info">
                  <big class="progress-label font-weight-bold">
                    {{ intComma(nonZeroCount(column)) }}
                  </big>
                </b-progress-bar>
                <b-progress-bar :value="zeroRate(column)" variant="secondary" style="background-color: #57636f" />
              </b-progress>
              <b-progress v-else>
                <b-progress-bar :value="column.fillRate" variant="primary">
                  <big class="progress-label font-weight-bold">
                    {{ column.numFills }}
                  </big>
                </b-progress-bar>
                <b-progress-bar :value="leftFillRate(column.fillRate)" variant="secondary" />
              </b-progress>
              <b-popover v-if="'trueValue' in column && 'falseValue' in column"
                :target="`distribution-count-${column.name}`" triggers="hover" placement="top">
                <div>
                  <span class="px-2 mr-2 bg-success" />
                  {{ column.trueValue }}:
                  {{ column.numFills }}
                </div>
                <div>
                  <span class="px-2 mr-2 bg-secondary" style="background-color: #57636f" />
                  {{ column.falseValue }}:
                  {{ intComma(leftFillValues(column.numFills)) }}
                </div>
              </b-popover>
            </td>
            <td>
              <template v-if="'trueValue' in column && 'falseValue' in column"
                :id="`distribution-fill-rate-${column.name}`">
                {{ Number(Number(column.fillRate).toFixed(2)) }}
              </template>
              <template v-else-if="showNonZeroRate(column)">
                {{ nonZeroRate(column).toFixed(2) }}
              </template>
              <template v-else>
                {{ Number(Number(column.fillRate).toFixed(2)) }}
              </template>
              <b-popover v-if="'trueValue' in column && 'falseValue' in column"
                :target="`distribution-fill-rate-${column.name}`" triggers="hover" placement="top">
                <div>
                  <span class="px-2 mr-2 bg-success" />
                  {{ column.trueValue }}:
                  {{ column.numFills }}
                  ({{ column.fillRate }}%)
                </div>
                <div>
                  <span class="px-2 mr-2 bg-secondary" style="background-color: #57636f" />
                  {{ column.falseValue }}:
                  {{ intComma(leftFillValues(column.numFills)) }}
                  ({{ leftFillRate(column.fillRate) }}%)
                </div>
              </b-popover>
            </td>
            <td class="text-right text-nowrap">
              {{ column.numUniques }}
            </td>
            <template v-if="column.minLen > 0">
              <td class="text-right text-nowrap">{{ column.minLen }}</td>
            </template>
            <template v-else>
              <th class="text-right text-nowrap alert-danger text-danger">
                {{ column.minLen }}
              </th>
            </template>
            <td class="text-right text-nowrap">
              {{ column.maxLen }}
            </td>
            <td class="text-right text-nowrap">
              {{ Number(column.avgLen).toFixed(2) }}
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <div class="mt-auto" />
  </div>
</template>

<script>
import Humanize from "humanize-plus";

export default {
  props: {
    group: { type: Object, default: null },
    totalRows: { type: Number, default: null },
  },
  data: () => ({
    booleanPairs: [
      ["yes", "no"],
      ["y", "n"],
      ["true", "false"],
      ["t", "f"],
      ["+", "-"],
      [1, 0],
      ["1", "0"],
    ],
  }),
  methods: {
    intComma: Humanize.intComma,
    zeroRate(column) {
      const numZero = parseInt(column.numZero.replaceAll(",", ""));
      return parseInt((numZero / this.totalRows) * 100);
    },
    nonZeroRate(column) {
      return 100 - this.zeroRate(column);
    },
    nonZeroCount(column) {
      return this.totalRows - parseInt(column.numZero.replaceAll(",", ""));
    },
    columnEntryClasses(supplier) {
      return {
        [`row-${supplier}`]: true,
        "text-nowrap": true,
        "text-right": true,
        "text-truncate": true,
      };
    },
    leftFillRate: (value) => 100 - parseInt(value),
    leftFillValues(value) {
      const leftValues = value.replaceAll(",", "");
      return this.totalRows - parseInt(leftValues);
    },
    columnClicked(clickType, column) {
      this.$emit("columnClicked", clickType, column, this.group);
    },
    showNonZeroRate(column) {
      return column.type === "int-non-zero" && column.numZero !== null;
    },
  },
};
</script>

<style scoped>
th {
  font-weight: 900;
}

td,
th {
  padding-top: 0;
  padding-bottom: 0;
}

.count-col {
  min-width: 70px;
  max-width: 70px;
}

.pct-col {
  min-width: 20px;
  max-width: 20px;
}

.uniques-col {
  min-width: 100px;
  max-width: 100px;
}

.min-col {
  min-width: 70px;
  max-width: 70px;
}

.max-col {
  min-width: 70px;
  max-width: 70px;
}

.avg-col {
  min-width: 60px;
  max-width: 60px;
}

.row-customer,
.row-customer:hover {
  color: #3e4c51;
  font-weight: bold;
}

.row-profound,
.row-profound:hover {
  color: #d12239;
  font-weight: bold;
}
</style>
