<template>
  <div class="groups-listing">
    <div class="d-flex" >
      <div class="flex-fill">
        <SearchBox
          ref="searchBox"
          placeholder="Search groups by name"
          class="mb-3"
          :data="groups"
          :features-to-query="['title']"
          :filtered.sync="filteredGroups"
        />
      </div>
      <button
        class="btn btn-sm btn-primary ml-2"
        style="height: 37px"
        @click="openAllGroupsInNewTabs()"
      >
        Open All Groups in New Tabs
      </button>
    </div>
    <div
      v-if="groupsToShow().length > 0"
      class="scroller-container"
    >
      <div
        v-for="item in groupsToShow()"
        :key="item.entry.name"
        @click="$emit('groupLeftClicked', item.entry)"
        @click.middle="$emit('groupMiddleClicked', item.entry)"
      >
        <GroupSummary
          data-test="group-summary"
          class="border-bottom"
          :initial-title="item.queried.title"
          :group="item.entry"
          :total-rows="totalRows()"
          @columnClicked="columnClicked"
          @titleChanged="groupTitleChanged(item, ...arguments)"
          @changeColumnOrder="$emit('changeColumnOrder', item.entry)"
        />
      </div>
    </div>
    <em
      v-else
      class="ml-5 mt-2"
    >
      No group meets requested search query.
    </em>
  </div>
</template>

<script>
  import GroupSummary from './GroupSummary.vue'
  import SearchBox from './SearchBox.vue'
  export default {
    components: {SearchBox, GroupSummary},
    props: {data: {type: Object, default: null}},
    data() {
      const groups = this.data.groups.map(
        group => (
          {
            ...group, isHidden: false,
            columns: group.columns.map(
              column => ({...column, isHidden: false})
            )
          }
        )
      );
      return {isLoading: false, groups, filteredGroups: []};
    },
    methods: {
      totalRows() {return parseInt(this.data.totalRows.replaceAll(',', ''));},
      groupTitleChanged(group, title) {
        group.queried.title = title;
        group.entry.title = title;
        this.$emit('groupTitleChanged', group.entry);
      },
      groupsToShow() {
        return this.filteredGroups.filter(item => !item.entry.isHidden).map(
          group => (
            {
              index: group.index,
              entry: group.entry,
              queried: group.queried
            }
          )
        );
      },
      openAllGroupsInNewTabs() {
        this.groupsToShow().forEach(
          group => this.$emit('groupMiddleClicked', group.entry)
        );
      },
      columnClicked(clickType, column, group) {
        this.$emit('columnClicked', clickType, column, group);
      },
      isColumnInGroup(group, columnName) {
        return group.columns.map(column => column.name).includes(columnName);
      },
      groupByColumnName(columnName) {
        return this.groups.find(
          group => this.isColumnInGroup(group, columnName)
        );
      },
      columnEntry(group, columnName) {
        return group.columns.find(column => column.name === columnName);
      },
      setIsHidden(columnName, value) {
        const group = this.groupByColumnName(columnName);
        if (!group) {
          return;
        }
        const column = this.columnEntry(group, columnName);
        column.isHidden = value;
        if (group.data) {
          group.data[columnName].isHidden = value;
        }
        group.isHidden = group.columns.every(column => column.isHidden);
      },
      show(columnName) {
        this.setIsHidden(columnName, false);
      },
      hide(columnName) {
        this.setIsHidden(columnName, true);
      },
    }
  };
</script>

<style>
  .scroller-container {
    height: 100%;
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    contain: strict;
  }
  .groups-listing {
    height: 100%;
    flex: 1;
    display: flex;
    flex-flow: column;
  }
</style>
