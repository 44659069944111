<template>
  <table
    v-if="visits"
    class="table table-sm table-hover header-fixed"
  >
    <thead>
      <tr>
        <th class="ip-col">
          IPv4
        </th>
        <th class="check-col">
          AS
        </th>
        <th
          class="text-col"
          colspan="2"
        >
          Country
        </th>
        <th class="text-col">
          Browser
        </th>
        <th class="text-col">
          OS
        </th>
        <th class="check-col">
          Mobile
        </th>
        <th class="check-col">
          Tablet
        </th>
        <th class="check-col">
          Touch
        </th>
        <th class="check-col">
          PC
        </th>
        <th class="check-col">
          Bot
        </th>
        <th class="timestamp-col">
          Timestamp
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="visit in visits"
        :key="visit.id"
      >
        <td class="ip-col">
          {{ visit.visitor.ip }}
        </td>
        <td class="check-col">
          <b-icon-check
            v-if="visit.visitor.rdns || visit.visitor.as"
            :title="asDescription(visit.visitor)"
          />
        </td>
        <td class="flag-col">
          <span
            v-if="visit.visitor.cc"
            :class="`flag-icon flag-icon-${visit.visitor.cc.toLowerCase()} border mr-1`"
          />
        </td>
        <td class="country-col">
          {{ visit.visitor.country }}
        </td>
        <td class="text-col">
          {{ visit.userAgent.browser.family }}
          <template v-if="visit.userAgent.browser.version">
            (v{{ visit.userAgent.browser.version }})
          </template>
        </td>
        <td class="text-col">
          {{ visit.userAgent.operatingSystem.family }}
          <template v-if="visit.userAgent.operatingSystem.version">
            (v{{ visit.userAgent.operatingSystem.version }})
          </template>
        </td>
        <td class="check-col">
          <b-icon-check v-if="visit.userAgent.isMobile" />
        </td>
        <td class="check-col">
          <b-icon-check v-if="visit.userAgent.isTablet" />
        </td>
        <td class="check-col">
          <b-icon-check v-if="visit.userAgent.isTouchCapable" />
        </td>
        <td class="check-col">
          <b-icon-check v-if="visit.userAgent.isPc" />
        </td>
        <td class="check-col">
          <b-icon-check v-if="visit.userAgent.isBot" />
        </td>
        <td class="timestamp-col">
          {{ visit.timestamp.date }} {{ visit.timestamp.time }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
  export default {
    data: () => ({visits: []}),
    computed: {
      deployAll: () => ["true", "t", "yes", "y", "on"].includes(
        process.env.VUE_APP_DEPLOY_ALL.toLowerCase()
      ),
      backendUrl: () => process.env.VUE_APP_VISUALIZER_BACKEND_URL,
      backendToken: () => process.env.VUE_APP_BACKEND_TOKEN,
      taskId() { return this.$route.params.taskId; },
    },
    async mounted() {
      const urlBase = `visitor-statistics/${this.taskId}`;
      const url = `${this.backendUrl}/${urlBase}/?token=${this.backendToken}`;
      const response = await fetch(url);
      this.visits = (await response.json()).visits.sort(
        (a, b) => {
          if (a.timestamp.date.localeCompare(b.timestamp.date)) return -1;
          if (a.timestamp.date.localeCompare(b.timestamp.date)) return 1;
          if (a.timestamp.time.localeCompare(b.timestamp.time)) return -1;
          if (a.timestamp.time.localeCompare(b.timestamp.time)) return 1;
          return 0;
        }
      );
    },
    methods: {
      asDescription: visitor => (
        `${visitor.rdns && `rDNS: ${visitor.rdns}`}\n` +
        `${visitor.as && `Autonomous System:\n`}` +
        `${visitor.as && visitor.as.domain && `- domain: ${visitor.as.domain}`}\n` +
        `${visitor.as && visitor.as.number && `- number: ${visitor.as.number}`}\n` +
        `${visitor.as && visitor.as.name && `- name: ${visitor.as.name}`}`
      )
    }
  }
</script>

<style scoped>
  .ip-col { width: 120px; }
  .text-col { width: 145px; }
  .flag-col { width: 30px; }
  .country-col { width: 115px; }
  .check-col {
    width: 65px;
    text-align: center;
  }
  .timestamp-col {
    width: 100px;
    text-align: right;
  }
</style>
