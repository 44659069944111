<template>
  <a
    ref="container"
    class="row text-decoration-none text-primary cursor-pointer"
  >
    <form
      v-if="editingTitle"
      @submit.prevent="changeTitle"
    >
      <b-input-group
        ref="titleInput"
        class="col ml-1 mt-2 mb-2"
        :style="{height: '38px'}"
        :disabled="saving"
        @click.stop
      >
        <template #append>
          <b-button
            variant="outline-secondary"
            :disabled="saving"
            @click="editingTitle = false"
            v-text="`Cancel`"
          />
          <b-button
            variant="primary"
            type="submit"
            :data-test="`change-group-title-submit_${group.name}`"
            :disabled="saving"
          >
            <template v-if="saving">
              Saving...
              <b-spinner
                class="mb-1"
                small
              />
            </template>
            <template v-else>
              Save
            </template>
          </b-button>
        </template>
        <b-input
          v-model="title"
          :data-test="`change-group-title-text-input_${group.name}`"
          placeholder="Group Title"
          :disabled="saving"
        />
      </b-input-group>
    </form>
    <div
      v-else
      class="col mb-2"
    >
      <h2 data-test="group-title">
        <span
          :data-test="`group-title_${group.name}`"
          v-html="initialTitle"
        />
        <b-icon-pencil
          v-if="deployAll"
          class="ml-3"
          :data-test="`change-group-title-link_${group.name}`"
          @click.stop="editTitle"
        />
      </h2>
      <b-button
        v-if="deployAll"
        size="sm"
        variant="outline-secondary"
        class="ml-1"
        :data-test="`change-column-order-${group.name}`"
        @click.stop="$emit('changeColumnOrder')"
      >
        Change Order of Columns in this Group
      </b-button>
    </div>
    <FileColumnStatistics
      :total-rows="totalRows"
      :group="group"
      class="col mr-3"
      @columnClicked="columnClicked"
    />
  </a>
</template>

<script>
  import FileColumnStatistics from './FileColumnStatistics.vue'
  export default {
    components: {FileColumnStatistics},
    props: {
      initialTitle: {type: String, default: null},
      group: {type: Object, default: null},
      totalRows: {type: Number, default: null},
    },
    data() {
      return {editingTitle: false, title: this.initialTitle, saving: false};
    },
    computed: {
      deployAll: () => ['true', 't', 'yes', 'y', 'on'].includes(
        process.env.VUE_APP_DEPLOY_ALL.toLowerCase()
      ),
      backendUrl: () => process.env.VUE_APP_VISUALIZER_BACKEND_URL,
      backendToken: () => process.env.VUE_APP_BACKEND_TOKEN,
    },
    methods: {
      editTitle() {
        this.editingTitle = true;
        this.$nextTick(() => this.$refs.titleInput.children[0].focus());
      },
      async changeTitle() {
        this.saving = true;
        const taskId = this.$route.params.taskId;
        const urlBase = `change-group-title/${taskId}`;
        const url = `${this.backendUrl}/${urlBase}/?token=${this.backendToken}`;
        const groupName = (
          this.group.name.endsWith('__columngroup') ?
            this.group.name.substr(0, this.group.name.length - 13) :
            this.group.name
        );
        const data = {name: groupName, title: this.title};
        await fetch(url, {method: 'POST', body: JSON.stringify(data)});
        this.$emit('titleChanged', this.title);
        this.editingTitle = false;
        this.saving = false;
      },
      columnClicked(clickType, column, group) {
        this.$emit('columnClicked', clickType, column, group);
      }
    }
  };
</script>
