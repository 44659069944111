<template>
  <VueApexCharts
    type="bar"
    :options="options"
    :series="series"
    height="550"
  />
</template>

<script>
  import Humanize from 'humanize-plus';
  import VueApexCharts from 'vue-apexcharts';
  export default {
    components: {VueApexCharts},
    props: {
      type: {type: String, default: null},
      mostCommonValues: {type: Array, default: null},
      ranges: {type: Boolean, default: false}
    },
    computed: {
      dataToShow() {
        return (this.mostCommonValues || []).slice(0, 20);
      },
      options() {
        const result = {
          plotOptions: {bar: {dataLabels: {position: 'top'}}},
          dataLabels: {
            formatter: (value,) => Humanize.compactInteger(value || 0, 1),
            style: {colors: ['#3e4c51']},
            offsetY: -20
          },
          grid: {padding: {left: 50}},
          xaxis: {categories: this.dataToShow.map(element => element.value)},
          yaxis: {
            labels: {formatter: value => Humanize.intComma(parseInt(value))}
          },
        };
        if (this.type === 'color') {
          const colors = {
            Y: 'rgb(255, 255, 0)',
            R: 'rgb(255, 0, 0)',
            G: 'rgb(100, 150, 0)',
            VG: 'rgb(0, 255, 0)',
          };
          result.colors = this.dataToShow.map(
            element => element.value in colors ? colors[element.value] : '#3e4c51'
          );
          result.plotOptions.bar.distributed = true;
        } else {
          result.colors = ['#d12239'];
        }
        return result;
      },
      series() {
        return [
          {
            name: 'Common Values Count',
            data: this.dataToShow.map(element => element.count)
          }
        ];
      },
    },
    methods: {
      parseIntValue(value) {
        if (value === 'inf') {
          return Infinity;
        }
        return parseInt(value);
      }
    }
  };
</script>
