var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-form-group",
        {
          attrs: {
            label: "Correlation totals by " + _vm.correlations.label,
            "label-cols": "auto"
          }
        },
        [
          _c("b-form-select", {
            attrs: { options: _vm.valueOptions() },
            model: {
              value: _vm.valueChoice,
              callback: function($$v) {
                _vm.valueChoice = $$v
              },
              expression: "valueChoice"
            }
          })
        ],
        1
      ),
      _c("table", { staticClass: "table table-sm table-striped table-hover" }, [
        _c("thead", [
          _c("tr", [
            _vm.correlations.type === "domain"
              ? _c("th", { staticClass: "w-fit" }, [_vm._v(" Icon ")])
              : _vm.correlations.type === "country"
              ? _c("th", { staticClass: "w-fit" }, [_vm._v(" Flag ")])
              : _vm._e(),
            _c("th", [_vm._v("Value")]),
            _c("th", { staticClass: "text-right w-fit" }, [_vm._v(" Total ")])
          ])
        ]),
        _c(
          "tbody",
          [
            _vm._l(_vm.correlations.data, function(correlation) {
              return [
                correlation.correlationValue !== "<all>"
                  ? _c(
                      "tr",
                      {
                        key: correlation.correlationValue,
                        staticClass: "pe-pointer",
                        on: {
                          click: function($event) {
                            return _vm.correlationChosen(correlation)
                          }
                        }
                      },
                      [
                        _vm.correlations.type === "domain"
                          ? _c("td", { staticClass: "w-fit" }, [
                              _c("img", {
                                attrs: {
                                  src:
                                    _vm.backendUrl +
                                    "/favicon/" +
                                    correlation.correlationValue,
                                  width: "20",
                                  height: "20",
                                  onerror: "this.classList.add('d-none')"
                                }
                              })
                            ])
                          : _vm.correlations.type === "country"
                          ? _c("td", [
                              _c("span", {
                                class:
                                  "flag-icon flag-icon-" +
                                  _vm.countryCode(
                                    correlation.correlationValue
                                  ) +
                                  " border"
                              })
                            ])
                          : _vm._e(),
                        _vm.correlations.type === "url" &&
                        correlation.correlationValue
                          ? _c("td", { staticClass: "text-break" }, [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: correlation.correlationValue,
                                    target: "_blank"
                                  }
                                },
                                [_vm._v(_vm._s(correlation.correlationValue))]
                              )
                            ])
                          : correlation.correlationValue
                          ? _c("td", { staticClass: "text-break" }, [
                              _vm._v(
                                " " + _vm._s(correlation.correlationValue) + " "
                              )
                            ])
                          : _c("td", { staticClass: "text-break" }, [
                              _c("em", [_vm._v("empty")])
                            ]),
                        _c("td", { staticClass: "text-right w-fit" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.humanize(_vm.total(correlation))) +
                              " "
                          )
                        ])
                      ]
                    )
                  : _vm._e()
              ]
            })
          ],
          2
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }