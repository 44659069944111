import Vue from 'vue';
import VueRouter from 'vue-router';
import VueApexCharts from 'vue-apexcharts';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import L from 'leaflet';
import 'flag-icon-css/css/flag-icon.css';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

import './app.scss';

import App from './App.vue';
import Report from './components/visualizer/Report.vue';
import Deliverable from './components/visualizer/Deliverable.vue';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions(
  {
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
  }
);

Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueApexCharts);

const routes = [
  {name: 'report', path: '/visualizer/:taskId', component: Report},
  {
    name: 'deliverable',
    path: '/visualizer/deliverable/:deliverableId',
    component: Deliverable
  },
];
const router = new VueRouter({routes});
[window.app] = new Vue({router, render: h => h(App)}).$mount('#app').$children;
