<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
    <div class="container">
      <router-link
        :to="{ name: 'index' }"
        class="navbar-brand"
        data-test="navbar-brand"
      >
        <img
          src="../images/logo.png"
          class="hidden-xs"
          height="60px"
        >
        CSV Analyzer
      </router-link>
      <div class="collapse navbar-collapse">
        <ul class="navbar-nav">
          <li class="nav-item active">
            <router-link
              class="nav-link"
              :to="{ name: 'newJobFileForm' }"
            >
              Start New Job
            </router-link>
          </li>
          <li class="nav-item active">
            <router-link
              class="nav-link"
              :to="{ name: 'importJobResults' }"
            >
              Import Existing Report
            </router-link>
          </li>
          <li class="nav-item active">
            <router-link
              class="nav-link"
              :to="{ name: 'presets' }"
            >
              Presets
            </router-link>
          </li>
        </ul>
      </div>
      <div class="text-light h3">
        {{ filename }}
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data: () => ({ filename: null }),
  watch: {
    filename(value) {
      document.title = value || "CSV Analyzer";
    },
  },
};
</script>
