<template>
  <div>
    <NavBarWithLinks
      v-if="full"
      ref="navbar"
    />
    <NavBarWithoutLinks
      v-else
      ref="navbar"
    />
    <router-view />
  </div>
</template>

<script>
import NavBarWithLinks from "./components/NavBarWithLinks.vue";
import NavBarWithoutLinks from "./components/NavBarWithoutLinks.vue";
export default {
  components: { NavBarWithLinks, NavBarWithoutLinks },
  props: { full: { type: Boolean, default: false } },
  mounted() {
    this.$refs.navbar.filename = "";
  },
};
</script>
