var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "b-modal",
        {
          attrs: {
            visible: _vm.refreshCacheState === "ongoing",
            size: "lg",
            centered: "",
            "hide-header": "",
            "hide-footer": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": ""
          }
        },
        [
          _c(
            "div",
            { staticClass: "d-flex justify-content-around my-2" },
            [
              _c("h3", { staticClass: "flex-fill" }, [
                _vm._v(" Refreshing cache... ")
              ]),
              _c("b-spinner", { staticClass: "border-1" })
            ],
            1
          )
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            visible: _vm.refreshCacheState === "done",
            size: "lg",
            centered: "",
            "hide-header": "",
            "hide-footer": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": ""
          }
        },
        [
          _c("h3", [_vm._v("Cache refreshed successfuly!")]),
          _c("b-button", {
            staticClass: "ml-1 float-right",
            attrs: { variant: "primary" },
            domProps: { textContent: _vm._s("OK") },
            on: {
              click: function($event) {
                return _vm.reload()
              }
            }
          })
        ],
        1
      ),
      _c("b-modal", {
        attrs: {
          visible: _vm.refreshCacheState === "error",
          size: "lg",
          centered: "",
          "hide-header": "",
          "hide-footer": "",
          "no-close-on-backdrop": "",
          "no-close-on-esc": ""
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var hide = ref.hide
              return [
                _c("h3", [_vm._v("Refreshing cache failed")]),
                _c("p", [_vm._v("Error occurred while refreshing cache.")]),
                _vm.refreshCacheError
                  ? _c("p", {
                      staticClass: "text-danger",
                      domProps: { textContent: _vm._s(_vm.refreshCacheError) }
                    })
                  : _vm._e(),
                _c("b-button", {
                  staticClass: "ml-1 float-right",
                  attrs: { variant: "primary" },
                  domProps: { textContent: _vm._s("OK") },
                  on: {
                    click: function($event) {
                      return hide()
                    }
                  }
                })
              ]
            }
          }
        ])
      }),
      _c("b-modal", {
        attrs: {
          id: "save-as-preset",
          size: "lg",
          centered: "",
          "hide-header": "",
          "hide-footer": "",
          "no-close-on-backdrop": "",
          "no-close-on-esc": ""
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var hide = ref.hide
              return [
                _c("h3", [_vm._v("Save Current Report Setup as a Preset")]),
                _c(
                  "b-form-radio-group",
                  {
                    staticClass: "mb-2",
                    staticStyle: { "max-height": "500px" },
                    attrs: { stacked: "" },
                    model: {
                      value: _vm.chosenPreset,
                      callback: function($$v) {
                        _vm.chosenPreset = $$v
                      },
                      expression: "chosenPreset"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "d-flex border rounded m-1 p-1",
                        attrs: {
                          title: _vm.preset
                            ? null
                            : "Couldn't locate preset file."
                        }
                      },
                      [
                        _c(
                          "b-form-radio",
                          {
                            attrs: {
                              value: "overwrite",
                              disabled: _vm.savingPreset || !_vm.preset
                            }
                          },
                          [
                            _vm._v(" Overwrite Used Preset "),
                            _vm.preset
                              ? _c("strong", [
                                  _vm._v(" " + _vm._s(_vm.preset.name) + " "),
                                  _c("em", { staticClass: "text-muted" }, [
                                    _vm._v(
                                      " (" + _vm._s(_vm.preset.filename) + ") "
                                    )
                                  ])
                                ])
                              : _vm._e()
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "border rounded m-1 p-1" },
                      [
                        _c(
                          "b-form-radio",
                          {
                            attrs: { value: "new", disabled: _vm.savingPreset }
                          },
                          [_vm._v(" Create New Preset: ")]
                        ),
                        _c("table", [
                          _c("tr", [
                            _c("th", [_vm._v("Filename")]),
                            _c(
                              "td",
                              { staticClass: "pl-2 pb-1" },
                              [
                                _c("b-input", {
                                  attrs: {
                                    disabled: _vm.savingPreset,
                                    placeholder: _vm.preset
                                      ? "e.g. '" +
                                        _vm.preset.key +
                                        "_forked.json'"
                                      : "",
                                    size: "sm"
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.chosenPreset = "new"
                                    }
                                  },
                                  model: {
                                    value: _vm.newPresetToCreate.filename,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.newPresetToCreate,
                                        "filename",
                                        $$v
                                      )
                                    },
                                    expression: "newPresetToCreate.filename"
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _c("tr", [
                            _c("th", [_vm._v("Name")]),
                            _c(
                              "td",
                              { staticClass: "pl-2" },
                              [
                                _c("b-input", {
                                  attrs: {
                                    disabled: _vm.savingPreset,
                                    placeholder: _vm.preset
                                      ? "e.g. '" + _vm.preset.name + "_forked'"
                                      : "",
                                    size: "sm"
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.chosenPreset = "new"
                                    }
                                  },
                                  model: {
                                    value: _vm.newPresetToCreate.name,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.newPresetToCreate,
                                        "name",
                                        $$v
                                      )
                                    },
                                    expression: "newPresetToCreate.name"
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ])
                      ],
                      1
                    )
                  ]
                ),
                _vm.presetSaveErrors
                  ? _c(
                      "b-alert",
                      { attrs: { show: "", variant: "danger" } },
                      _vm._l(_vm.presetSaveErrors, function(presetSaveError) {
                        return _c("div", {
                          key: presetSaveError,
                          domProps: { textContent: _vm._s(presetSaveError) }
                        })
                      }),
                      0
                    )
                  : _vm._e(),
                _vm.presetSaveWarnings
                  ? _c(
                      "b-alert",
                      { attrs: { show: "", variant: "warning" } },
                      _vm._l(_vm.presetSaveWarnings, function(
                        presetSaveWarning
                      ) {
                        return _c("div", {
                          key: presetSaveWarning,
                          domProps: { textContent: _vm._s(presetSaveWarning) }
                        })
                      }),
                      0
                    )
                  : _vm._e(),
                _c(
                  "b-button",
                  {
                    staticClass: "ml-1",
                    attrs: {
                      variant: "primary",
                      disabled: _vm.savingPreset,
                      "data-test": "save-as-preset-submit"
                    },
                    on: {
                      click: function($event) {
                        return _vm.savePreset()
                      }
                    }
                  },
                  [
                    _vm.savingPreset
                      ? [
                          _vm._v(" Saving... "),
                          _c("b-spinner", { attrs: { small: "" } })
                        ]
                      : [_vm._v(" Save ")]
                  ],
                  2
                ),
                _c("b-button", {
                  staticClass: "ml-1",
                  attrs: { disabled: _vm.savingPreset, variant: "secondary" },
                  domProps: { textContent: _vm._s("Cancel") },
                  on: {
                    click: function($event) {
                      return hide()
                    }
                  }
                })
              ]
            }
          }
        ])
      }),
      _c(
        "b-modal",
        {
          attrs: {
            id: "preset-successfuly-saved-message",
            size: "lg",
            centered: "",
            "hide-header": "",
            "hide-footer": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": ""
          }
        },
        [
          _c("h3", [_vm._v("Preset Saved Successfuly!")]),
          _c("b-button", {
            staticClass: "ml-1 float-right",
            attrs: { variant: "primary" },
            domProps: { textContent: _vm._s("OK") },
            on: {
              click: function($event) {
                return _vm.reload()
              }
            }
          })
        ],
        1
      ),
      _c("b-modal", {
        attrs: {
          id: "load-current-preset",
          size: "lg",
          centered: "",
          "hide-header": "",
          "hide-footer": "",
          "no-close-on-backdrop": "",
          "no-close-on-esc": ""
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var hide = ref.hide
              return [
                _c("h3", [
                  _vm._v("Load Current Preset as a Layout for this Report")
                ]),
                _c("b-alert", { attrs: { show: "", variant: "warning" } }, [
                  _c("strong", [
                    _c("u", [_vm._v("WARNING:")]),
                    _vm._v(
                      " This action results with overriding all changes made to current layout. "
                    )
                  ])
                ]),
                _c("div", { staticClass: "lead mb-4" }, [
                  _vm._v(
                    " Are you sure you want to load current preset and entirely override your current layout of this report? "
                  )
                ]),
                _c(
                  "b-button",
                  {
                    staticClass: "ml-1",
                    attrs: { variant: "primary", disabled: _vm.loadingPreset },
                    on: {
                      click: function($event) {
                        return _vm.loadPreset()
                      }
                    }
                  },
                  [
                    _vm.loadingPreset
                      ? [
                          _vm._v(" Loading... "),
                          _c("b-spinner", { attrs: { small: "" } })
                        ]
                      : [_vm._v(" Load ")]
                  ],
                  2
                ),
                _c("b-button", {
                  staticClass: "ml-1",
                  attrs: { disabled: _vm.loadingPreset, variant: "secondary" },
                  domProps: { textContent: _vm._s("Cancel") },
                  on: {
                    click: function($event) {
                      return hide()
                    }
                  }
                })
              ]
            }
          }
        ])
      }),
      _c(
        "b-modal",
        {
          attrs: {
            id: "preset-successfuly-loaded-message",
            size: "lg",
            centered: "",
            "hide-header": "",
            "hide-footer": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": ""
          }
        },
        [
          _c("h3", [_vm._v("Preset Loaded Successfuly!")]),
          _c("b-button", {
            staticClass: "ml-1 float-right",
            attrs: { variant: "primary" },
            domProps: { textContent: _vm._s("OK") },
            on: {
              click: function($event) {
                return _vm.reload()
              }
            }
          })
        ],
        1
      ),
      _c("b-modal", {
        attrs: {
          id: "preset-failed-to-load-message",
          size: "lg",
          centered: "",
          "hide-header": "",
          "hide-footer": "",
          "no-close-on-backdrop": "",
          "no-close-on-esc": ""
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var hide = ref.hide
              return [
                _c("h3", [_vm._v("Preset Loading Failure")]),
                _c("div", { staticClass: "lead" }, [
                  _vm._v(" " + _vm._s(_vm.presetLoadErrors[0]) + " ")
                ]),
                _c("b-button", {
                  staticClass: "ml-1 float-right",
                  attrs: { variant: "primary" },
                  domProps: { textContent: _vm._s("OK") },
                  on: {
                    click: function($event) {
                      return hide()
                    }
                  }
                })
              ]
            }
          }
        ])
      }),
      _c("b-modal", {
        attrs: {
          id: "select-new-group",
          size: "lg",
          centered: "",
          "hide-header": "",
          "hide-footer": "",
          "no-close-on-backdrop": "",
          "no-close-on-esc": ""
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var hide = ref.hide
              return [
                _c("h3", [_vm._v("Select New Group for Selected Column")]),
                _c(
                  "b-form-radio-group",
                  {
                    staticClass: "overflow-auto px-2 mb-2",
                    staticStyle: { "max-height": "500px" },
                    attrs: { stacked: "" },
                    model: {
                      value: _vm.chosenGroup,
                      callback: function($$v) {
                        _vm.chosenGroup = $$v
                      },
                      expression: "chosenGroup"
                    }
                  },
                  [
                    _c("div", [
                      _c(
                        "div",
                        { staticClass: "d-flex my-2" },
                        [
                          _c(
                            "b-form-radio",
                            {
                              staticClass: "my-2",
                              attrs: {
                                value: "",
                                disabled: _vm.savingGroupChange
                              }
                            },
                            [
                              _c("strong", {
                                staticClass: "text-nowrap",
                                domProps: {
                                  textContent: _vm._s("Create New Group:")
                                }
                              })
                            ]
                          ),
                          _c("b-input", {
                            staticClass: "ml-2",
                            attrs: { placeholder: "Group Title", small: "" },
                            on: {
                              click: function($event) {
                                _vm.chosenGroup = ""
                              }
                            },
                            model: {
                              value: _vm.newGroupToCreate,
                              callback: function($$v) {
                                _vm.newGroupToCreate = $$v
                              },
                              expression: "newGroupToCreate"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._l(_vm.allGroups(), function(option) {
                      return _c(
                        "div",
                        { key: option.name },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex" },
                            [
                              _c(
                                "b-form-radio",
                                {
                                  staticClass: "flex-fill",
                                  attrs: {
                                    value: option.name,
                                    disabled: _vm.savingGroupChange,
                                    "data-test":
                                      "column-group-choice-" + option.name
                                  }
                                },
                                [
                                  _c("strong", {
                                    domProps: {
                                      textContent: _vm._s(option.title)
                                    }
                                  })
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "b-toggle",
                                      rawName: "v-b-toggle",
                                      value: "group-columns-" + option.name,
                                      expression:
                                        "`group-columns-${option.name}`"
                                    }
                                  ]
                                },
                                [
                                  _c("span", { staticClass: "mr-2" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(option.columns.length) +
                                        " Columns "
                                    )
                                  ]),
                                  _c("b-icon-chevron-down")
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-collapse",
                            { attrs: { id: "group-columns-" + option.name } },
                            [
                              _c(
                                "ul",
                                _vm._l(option.columns, function(column) {
                                  return _c("li", {
                                    key: column.name,
                                    domProps: {
                                      textContent: _vm._s(column.title)
                                    }
                                  })
                                }),
                                0
                              )
                            ]
                          )
                        ],
                        1
                      )
                    })
                  ],
                  2
                ),
                _vm.changeGroupError
                  ? _c("div", { staticClass: "text-danger ml-2 my-2" }, [
                      _vm._v(" " + _vm._s(_vm.changeGroupError) + " ")
                    ])
                  : _vm._e(),
                _c(
                  "b-button",
                  {
                    staticClass: "ml-1",
                    attrs: {
                      variant: "primary",
                      disabled: _vm.savingGroupChange,
                      "data-test": "save-group-change"
                    },
                    on: {
                      click: function($event) {
                        return _vm.saveGroupChange()
                      }
                    }
                  },
                  [
                    _vm.savingGroupChange
                      ? [
                          _vm._v(" Saving... "),
                          _c("b-spinner", { attrs: { small: "" } })
                        ]
                      : [_vm._v(" Save ")]
                  ],
                  2
                ),
                _c("b-button", {
                  staticClass: "ml-1",
                  attrs: {
                    disabled: _vm.savingGroupChange,
                    variant: "secondary"
                  },
                  domProps: { textContent: _vm._s("Cancel") },
                  on: {
                    click: function($event) {
                      return hide()
                    }
                  }
                })
              ]
            }
          }
        ])
      }),
      _c(
        "b-modal",
        {
          attrs: {
            id: "column-successfuly-reassigned-message",
            size: "lg",
            centered: "",
            "hide-header": "",
            "hide-footer": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": ""
          }
        },
        [
          _c("h3", [_vm._v("Group Assigned Successfuly!")]),
          _c("div", [
            _vm._v(" Selected columns has been successfuly assigned to "),
            _c("strong", {
              domProps: { textContent: _vm._s(_vm.chosenGroup) }
            }),
            _vm._v(" group. ")
          ]),
          _c("b-button", {
            staticClass: "ml-1 float-right",
            attrs: { disabled: _vm.savingGroupChange, variant: "primary" },
            domProps: { textContent: _vm._s("OK") },
            on: {
              click: function($event) {
                return _vm.reload()
              }
            }
          })
        ],
        1
      ),
      _c("b-modal", {
        attrs: {
          id: "change-group-order",
          size: "lg",
          centered: "",
          "hide-header": "",
          "hide-footer": "",
          "no-close-on-backdrop": "",
          "no-close-on-esc": ""
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var hide = ref.hide
              return [
                _c("h3", [_vm._v("Change Order of Groups")]),
                _c(
                  "draggable",
                  {
                    staticClass: "overflow-auto p-2 m-2",
                    staticStyle: { "max-height": "500px" },
                    model: {
                      value: _vm.orderedGroups,
                      callback: function($$v) {
                        _vm.orderedGroups = $$v
                      },
                      expression: "orderedGroups"
                    }
                  },
                  _vm._l(_vm.orderedGroups, function(group) {
                    return _c(
                      "div",
                      {
                        key: group.name,
                        class: {
                          "bg-light": _vm.savingGroupOrder,
                          "text-muted": _vm.isGroupHidden(group.columns),
                          border: true,
                          "my-1": true,
                          "p-1": true,
                          draggable: true
                        },
                        attrs: { "data-test": "group-reorderer-" + group.name },
                        on: { dragstart: _vm.dragStart, dragend: _vm.dragEnd }
                      },
                      [
                        _c("div", { staticClass: "d-flex" }, [
                          _c("div", { staticClass: "flex-fill" }, [
                            _c(
                              "strong",
                              { staticClass: "mr-2" },
                              [
                                _c("b-icon-grip-vertical"),
                                _vm._v(" " + _vm._s(group.title))
                              ],
                              1
                            ),
                            _vm.isGroupHidden(group.columns)
                              ? _c(
                                  "small",
                                  { staticClass: "text-muted font-italic" },
                                  [_vm._v("(group hidden)")]
                                )
                              : _vm.isGroupIndeterminate(group.columns)
                              ? _c(
                                  "small",
                                  { staticClass: "text-muted font-italic" },
                                  [_vm._v("(group partially hidden)")]
                                )
                              : _vm._e()
                          ]),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "b-toggle",
                                  rawName: "v-b-toggle",
                                  value: "group-order-columns-" + group.name,
                                  expression:
                                    "`group-order-columns-${group.name}`"
                                }
                              ]
                            },
                            [
                              _c("span", { staticClass: "mr-2" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(group.columns.length) +
                                    " Columns "
                                )
                              ]),
                              _c("b-icon-chevron-down")
                            ],
                            1
                          )
                        ]),
                        _c(
                          "b-collapse",
                          {
                            attrs: { id: "group-order-columns-" + group.name }
                          },
                          [
                            _c(
                              "ul",
                              _vm._l(group.columns, function(column) {
                                return _c(
                                  "li",
                                  {
                                    key: column.name,
                                    class: {
                                      "text-muted": _vm.isColumnHidden(
                                        column.name
                                      )
                                    }
                                  },
                                  [
                                    _vm._v(" " + _vm._s(column.title) + " "),
                                    _vm.isColumnHidden(column.name)
                                      ? _c("small", {
                                          staticClass: "font-italic",
                                          domProps: {
                                            textContent: _vm._s(
                                              "(column hidden)"
                                            )
                                          }
                                        })
                                      : _vm._e()
                                  ]
                                )
                              }),
                              0
                            )
                          ]
                        )
                      ],
                      1
                    )
                  }),
                  0
                ),
                _vm.orderGroupError
                  ? _c("div", { staticClass: "text-danger ml-2 my-2" }, [
                      _vm._v(" " + _vm._s(_vm.orderGroupError) + " ")
                    ])
                  : _vm._e(),
                _c(
                  "b-button",
                  {
                    staticClass: "ml-1",
                    attrs: {
                      variant: "primary",
                      disabled: _vm.savingGroupOrder,
                      "data-test": "save-group-order"
                    },
                    on: {
                      click: function($event) {
                        return _vm.saveGroupOrder()
                      }
                    }
                  },
                  [
                    _vm.savingGroupOrder
                      ? [
                          _vm._v(" Saving... "),
                          _c("b-spinner", { attrs: { small: "" } })
                        ]
                      : [_vm._v(" Save ")]
                  ],
                  2
                ),
                _c("b-button", {
                  staticClass: "ml-1",
                  attrs: {
                    disabled: _vm.savingGroupOrder,
                    variant: "secondary"
                  },
                  domProps: { textContent: _vm._s("Cancel") },
                  on: {
                    click: function($event) {
                      return hide()
                    }
                  }
                })
              ]
            }
          }
        ])
      }),
      _c(
        "b-modal",
        {
          attrs: {
            id: "group-order-successfuly-saved-message",
            size: "lg",
            centered: "",
            "hide-header": "",
            "hide-footer": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": ""
          }
        },
        [
          _c("h3", [_vm._v("Group Order Successfuly Saved!")]),
          _c("b-button", {
            staticClass: "ml-1 float-right",
            attrs: { disabled: _vm.savingGroupOrder, variant: "primary" },
            domProps: { textContent: _vm._s("OK") },
            on: {
              click: function($event) {
                return _vm.reload()
              }
            }
          })
        ],
        1
      ),
      _c("b-modal", {
        attrs: {
          id: "column-row-count",
          centered: "",
          "hide-header": "",
          "hide-footer": "",
          "no-close-on-backdrop": "",
          "no-close-on-esc": ""
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var hide = ref.hide
              return [
                _c("h3", [_vm._v("Full Column Row Count")]),
                _c(
                  "table",
                  {
                    staticClass:
                      "table table-sm table-borderless table-responsive mb-1",
                    staticStyle: { "max-height": "500px" }
                  },
                  [
                    _c(
                      "tbody",
                      _vm._l(_vm.histogram, function(histogramEntry) {
                        return _c(
                          "tr",
                          {
                            key: histogramEntry.columnCount,
                            class:
                              "text-" +
                              (_vm.isHistogramEntryFailure(histogramEntry)
                                ? "danger"
                                : "success")
                          },
                          [
                            _c("th", { staticClass: "pl-0 pt-0" }, [
                              _vm._v(
                                " " + _vm._s(histogramEntry.columnCount) + ": "
                              )
                            ]),
                            _c(
                              "td",
                              { staticClass: "pl-0 pt-0" },
                              [
                                !_vm.isHistogramEntryFailure(histogramEntry)
                                  ? _c("strong", [
                                      _vm._v(
                                        " " +
                                          _vm._s(histogramEntry.rowCount) +
                                          " "
                                      )
                                    ])
                                  : [
                                      _vm._v(
                                        " " +
                                          _vm._s(histogramEntry.rowCount) +
                                          " "
                                      )
                                    ]
                              ],
                              2
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ]
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "ml-1",
                    attrs: { size: "lg", variant: "primary" },
                    on: {
                      click: function($event) {
                        return hide()
                      }
                    }
                  },
                  [_vm._v(" OK ")]
                )
              ]
            }
          }
        ])
      }),
      _c("b-modal", {
        attrs: {
          id: "apply-changes",
          size: "lg",
          centered: "",
          "hide-header": "",
          "hide-footer": "",
          "no-close-on-backdrop": "",
          "no-close-on-esc": ""
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var hide = ref.hide
              return [
                _c("h3", [_vm._v(_vm._s(_vm.applyChangesTitle))]),
                _c("p", [
                  _vm._v(
                    " The following changes were introduced to the visualization setup, but not saved in the database yet. "
                  )
                ]),
                _c(
                  "ul",
                  [
                    _vm._l(_vm.visualizationChanges.columnsToHide, function(
                      columnToHide
                    ) {
                      return _c("li", { key: "columnToHide-" + columnToHide }, [
                        _vm._v(" Hide column "),
                        _c("strong", [
                          _vm._v(
                            _vm._s(_vm.columnsWithGroups[columnToHide].title)
                          )
                        ])
                      ])
                    }),
                    _vm._l(_vm.visualizationChanges.columnsToShow, function(
                      columnToShow
                    ) {
                      return _c("li", { key: "columnToShow-" + columnToShow }, [
                        _vm._v(" Show column "),
                        _c("strong", [
                          _vm._v(
                            _vm._s(_vm.columnsWithGroups[columnToShow].title)
                          )
                        ])
                      ])
                    }),
                    _vm._l(
                      _vm.visualizationChanges.supplierMigrations,
                      function(supplierMigration) {
                        return _c(
                          "li",
                          {
                            key: "supplierMigration-" + supplierMigration.name
                          },
                          [
                            _c("strong", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.columnsWithGroups[
                                      supplierMigration.name
                                    ].title
                                  ) +
                                  " "
                              )
                            ]),
                            _vm._v(" marked as "),
                            _c("strong", [
                              _vm._v(" supplied by "),
                              _c("span", {
                                staticClass: "text-capitalize",
                                domProps: {
                                  textContent: _vm._s(
                                    supplierMigration.supplier
                                  )
                                }
                              })
                            ])
                          ]
                        )
                      }
                    )
                  ],
                  2
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "mr-1",
                    attrs: {
                      variant: "primary",
                      disabled: _vm.isApplyingChanges,
                      "data-test": "apply-changes"
                    },
                    on: { click: _vm.applyChanges }
                  },
                  [
                    _vm.isApplyingChanges
                      ? [
                          _vm._v(" Saving... "),
                          _c("b-spinner", { attrs: { small: "" } })
                        ]
                      : [_vm._v(" Save ")]
                  ],
                  2
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "ml-1",
                    attrs: { variant: "secondary" },
                    on: { click: _vm.unapplyChanges }
                  },
                  [_vm._v(" Discard ")]
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "float-right",
                    attrs: {
                      variant: "secondary",
                      disabled: _vm.isApplyingChanges
                    },
                    on: {
                      click: function($event) {
                        hide()
                        _vm.applyChangesTitle = "Changes to apply"
                      }
                    }
                  },
                  [_vm._v(" Cancel ")]
                )
              ]
            }
          }
        ])
      }),
      _c("b-modal", {
        attrs: {
          id: "applying-changes-succeeded",
          size: "lg",
          centered: "",
          "hide-header": "",
          "hide-footer": "",
          "no-close-on-backdrop": "",
          "no-close-on-esc": ""
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var hide = ref.hide
              return [
                _c("h3", [_vm._v("Changes were applied succesfully")]),
                _c(
                  "b-button",
                  {
                    staticClass: "ml-1",
                    attrs: {
                      variant: "primary",
                      disabled: _vm.isApplyingChanges
                    },
                    on: {
                      click: function($event) {
                        return hide()
                      }
                    }
                  },
                  [_vm._v(" OK ")]
                )
              ]
            }
          }
        ])
      }),
      _c(
        "div",
        { staticClass: "visualizations-tabs-container" },
        [
          _c(
            "b-tabs",
            { staticClass: "visualizations-tabs", attrs: { pills: "" } },
            [
              _c(
                "b-tab",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function() {
                        return [
                          _c(
                            "span",
                            {
                              attrs: { "data-test": "file-metadata-tab-title" }
                            },
                            [_vm._v("Metadata")]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c("div", { staticClass: "d-flex align-items-start" }, [
                    _c("div", { staticClass: "meta-data px-2" }, [
                      _c(
                        "div",
                        { staticClass: "meta-section" },
                        [
                          _c("div", { staticClass: "meta-feature" }, [
                            _c("div", { staticClass: "meta-key" }, [
                              _vm._v(" Original Filename: ")
                            ]),
                            _c("div", {
                              staticClass: "meta-value",
                              domProps: {
                                textContent: _vm._s(_vm.data.originalFilename)
                              }
                            })
                          ]),
                          _c("div", { staticClass: "meta-feature" }, [
                            _c("div", { staticClass: "meta-key" }, [
                              _vm._v(" Report Creation Date: ")
                            ]),
                            _c("div", { staticClass: "meta-value" }, [
                              _vm._v(" " + _vm._s(_vm.data.finished) + " ")
                            ])
                          ]),
                          _vm.deployAll || _vm.description
                            ? [
                                _c("div", { staticClass: "meta-feature" }, [
                                  _c("div", { staticClass: "meta-key" }, [
                                    _vm._v(" Description: ")
                                  ]),
                                  _c("div", { staticClass: "meta-value" }, [
                                    _vm.isDescriptionEditing
                                      ? _c(
                                          "div",
                                          [
                                            _c("b-form-textarea", {
                                              staticClass: "mb-2",
                                              attrs: {
                                                size: "sm",
                                                disabled:
                                                  _vm.savingDescriptionChange
                                              },
                                              model: {
                                                value: _vm.newDescription,
                                                callback: function($$v) {
                                                  _vm.newDescription = $$v
                                                },
                                                expression: "newDescription"
                                              }
                                            }),
                                            _c(
                                              "div",
                                              { staticClass: "float-right" },
                                              [
                                                _c(
                                                  "b-button",
                                                  {
                                                    staticClass: "mr-1",
                                                    attrs: {
                                                      variant: "primary",
                                                      size: "sm",
                                                      disabled:
                                                        _vm.savingDescriptionChange
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.saveDescriptionChange
                                                    }
                                                  },
                                                  [
                                                    _vm._v(" Save "),
                                                    _vm.savingDescriptionChange
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "spinner-border spinner-border-sm",
                                                            attrs: {
                                                              role: "status"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "sr-only"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Saving..."
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ]
                                                ),
                                                _c(
                                                  "b-button",
                                                  {
                                                    attrs: {
                                                      size: "sm",
                                                      disabled:
                                                        _vm.savingDescriptionChange
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.cancelDescriptionEdition
                                                    }
                                                  },
                                                  [_vm._v(" Cancel ")]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _c("div", { staticClass: "d-flex" }, [
                                          _vm.description
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "description-field"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.description) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            : _c("em", [
                                                _vm._v("No description.")
                                              ]),
                                          _vm.deployAll
                                            ? _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "text-no-decoration cursor-pointer ml-2",
                                                  on: {
                                                    click: _vm.editDescription
                                                  }
                                                },
                                                [_c("b-icon-pencil")],
                                                1
                                              )
                                            : _vm._e()
                                        ])
                                  ])
                                ])
                              ]
                            : _vm._e()
                        ],
                        2
                      ),
                      _vm.deployAll
                        ? _c(
                            "div",
                            {
                              staticClass: "meta-section admin-section",
                              attrs: { id: "meta-admin" }
                            },
                            [
                              _c(
                                "h4",
                                {
                                  directives: [
                                    {
                                      name: "b-toggle",
                                      rawName: "v-b-toggle",
                                      value: "meta-admin",
                                      expression: "`meta-admin`"
                                    }
                                  ]
                                },
                                [
                                  _vm._v(" Report Setup "),
                                  _c("b-icon-chevron-down")
                                ],
                                1
                              ),
                              _c(
                                "b-collapse",
                                {
                                  staticClass: "meta-collapse",
                                  attrs: { id: "meta-admin", visible: "" }
                                },
                                [
                                  _c("h6", { staticClass: "text-muted" }, [
                                    _vm._v(
                                      " This section is invisible and unavailable for regular users. "
                                    )
                                  ]),
                                  _c("div", { staticClass: "meta-feature" }, [
                                    _c("div", { staticClass: "meta-key" }, [
                                      _vm._v(" Original URL: ")
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "meta-value",
                                        attrs: { title: _vm.data.url }
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: _vm.data.url,
                                              target: "_blank"
                                            }
                                          },
                                          [_vm._v(_vm._s(_vm.data.url))]
                                        )
                                      ]
                                    )
                                  ]),
                                  _c("div", { staticClass: "meta-feature" }, [
                                    _c("div", { staticClass: "meta-key" }, [
                                      _vm._v(" Report Label: ")
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "meta-value",
                                        attrs: { "data-test": "file-name" }
                                      },
                                      [
                                        _vm.newFilename
                                          ? _c(
                                              "div",
                                              { staticClass: "row" },
                                              [
                                                _c("b-input", {
                                                  staticClass: "w-50 ml-3",
                                                  attrs: {
                                                    size: "sm",
                                                    disabled:
                                                      _vm.savingFilenameChange
                                                  },
                                                  model: {
                                                    value: _vm.newFilename,
                                                    callback: function($$v) {
                                                      _vm.newFilename = $$v
                                                    },
                                                    expression: "newFilename"
                                                  }
                                                }),
                                                _c(
                                                  "b-button",
                                                  {
                                                    staticClass: "mx-1",
                                                    attrs: {
                                                      variant: "primary",
                                                      size: "sm",
                                                      disabled:
                                                        _vm.savingFilenameChange
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.saveFilenameChange
                                                    }
                                                  },
                                                  [
                                                    _vm._v(" Save "),
                                                    _vm.savingFilenameChange
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "spinner-border spinner-border-sm",
                                                            attrs: {
                                                              role: "status"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "sr-only"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Saving..."
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ]
                                                ),
                                                _c(
                                                  "b-button",
                                                  {
                                                    attrs: {
                                                      size: "sm",
                                                      disabled:
                                                        _vm.savingFilenameChange
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.cancelFilenameEdition
                                                    }
                                                  },
                                                  [_vm._v(" Cancel ")]
                                                )
                                              ],
                                              1
                                            )
                                          : [
                                              _vm._v(
                                                " " + _vm._s(_vm.filename) + " "
                                              ),
                                              _vm.deployAll
                                                ? _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "text-no-decoration cursor-pointer ml-2",
                                                      on: {
                                                        click: _vm.editFilename
                                                      }
                                                    },
                                                    [_c("b-icon-pencil")],
                                                    1
                                                  )
                                                : _vm._e()
                                            ]
                                      ],
                                      2
                                    )
                                  ]),
                                  _c("div", { staticClass: "meta-feature" }, [
                                    _c("div", { staticClass: "meta-key" }, [
                                      _vm._v(" Collection of Definitions: ")
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "meta-value" },
                                      [
                                        _vm.collection.options
                                          ? _c("b-form-select", {
                                              attrs: {
                                                options: _vm.collection.options,
                                                disabled: Boolean(
                                                  _vm.collection.isLoading ||
                                                    _vm.collection.isSaving ||
                                                    _vm.collection.error
                                                ),
                                                size: "sm"
                                              },
                                              on: {
                                                change: _vm.changeCollection
                                              },
                                              model: {
                                                value: _vm.collection.choice,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.collection,
                                                    "choice",
                                                    $$v
                                                  )
                                                },
                                                expression: "collection.choice"
                                              }
                                            })
                                          : _vm.collection.isLoading ||
                                            _vm.collection.isSaving
                                          ? _c("b-spinner", {
                                              staticClass: "ml-2",
                                              staticStyle: {
                                                width: "23px",
                                                height: "23px"
                                              }
                                            })
                                          : _vm._e(),
                                        _vm.collection.error
                                          ? _c("div", {
                                              staticClass: "text-danger",
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.collection.error
                                                )
                                              }
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("div", { staticClass: "meta-feature" }, [
                                    _c("div", { staticClass: "meta-key" }, [
                                      _vm._v(" Availability: ")
                                    ]),
                                    _c("div", { staticClass: "meta-value" }, [
                                      _vm.isIrrelevant
                                        ? _c("div", { staticClass: "d-flex" }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "mr-3 text-danger"
                                              },
                                              [
                                                _c(
                                                  "strong",
                                                  [
                                                    _vm._v("Private "),
                                                    _c("b-icon-lock-fill")
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "small",
                                                  { staticClass: "text-muted" },
                                                  [
                                                    _c(
                                                      "ul",
                                                      {
                                                        staticClass: "mb-0 pl-3"
                                                      },
                                                      [
                                                        _c("li", [
                                                          _vm._v(
                                                            "unavailable for regular users"
                                                          )
                                                        ]),
                                                        _c("li", [
                                                          _vm._v(
                                                            "invisible on the Job List page by default"
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              [
                                                _c(
                                                  "b-button",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "b-tooltip",
                                                        rawName:
                                                          "v-b-tooltip.hover",
                                                        modifiers: {
                                                          hover: true
                                                        }
                                                      }
                                                    ],
                                                    attrs: {
                                                      variant:
                                                        "outline-secondary",
                                                      size: "sm",
                                                      title:
                                                        "Will be available for regular users and visible on the Job List page by default.",
                                                      disabled:
                                                        _vm.isSavingRelevancy
                                                    },
                                                    on: {
                                                      click: _vm.saveRelevancy
                                                    }
                                                  },
                                                  [
                                                    _vm._v(" Make Public "),
                                                    _vm.isSavingRelevancy
                                                      ? _c("b-spinner", {
                                                          staticClass: "ml-2",
                                                          attrs: { small: "" }
                                                        })
                                                      : _vm._e()
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ])
                                        : _c("div", { staticClass: "d-flex" }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "mr-3 text-success"
                                              },
                                              [
                                                _c(
                                                  "strong",
                                                  [
                                                    _vm._v("Public "),
                                                    _c("b-icon-unlock-fill")
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "small",
                                                  { staticClass: "text-muted" },
                                                  [
                                                    _c(
                                                      "ul",
                                                      {
                                                        staticClass: "mb-0 pl-3"
                                                      },
                                                      [
                                                        _c("li", [
                                                          _vm._v(
                                                            "available for regular users"
                                                          )
                                                        ]),
                                                        _c("li", [
                                                          _vm._v(
                                                            "visible on the Job List page by default"
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              [
                                                _c(
                                                  "b-button",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "b-tooltip",
                                                        rawName:
                                                          "v-b-tooltip.hover",
                                                        modifiers: {
                                                          hover: true
                                                        }
                                                      }
                                                    ],
                                                    attrs: {
                                                      variant:
                                                        "outline-secondary",
                                                      size: "sm",
                                                      title:
                                                        "Will be unavailable for regular users and invisible on the Job List page by default.",
                                                      disabled:
                                                        _vm.isSavingRelevancy
                                                    },
                                                    on: {
                                                      click: _vm.saveRelevancy
                                                    }
                                                  },
                                                  [
                                                    _vm._v(" Make Private "),
                                                    _vm.isSavingRelevancy
                                                      ? _c("b-spinner", {
                                                          staticClass: "ml-2",
                                                          attrs: { small: "" }
                                                        })
                                                      : _vm._e()
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ])
                                    ])
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "meta-feature mt-3" },
                                    [
                                      _c("div", { staticClass: "meta-key" }, [
                                        _vm._v(" Extra Actions: ")
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "meta-value" },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass: "mr-1 text-nowrap",
                                              attrs: {
                                                variant: "secondary",
                                                size: "sm",
                                                href:
                                                  _vm.backendUrl +
                                                  "/export/output/" +
                                                  _vm.taskId() +
                                                  "/?token=" +
                                                  _vm.backendToken
                                              }
                                            },
                                            [
                                              _vm._v(" JSON File "),
                                              _c("b-icon-download")
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-button",
                                            {
                                              directives: [
                                                {
                                                  name: "b-modal",
                                                  rawName:
                                                    "v-b-modal.save-as-preset",
                                                  modifiers: {
                                                    "save-as-preset": true
                                                  }
                                                }
                                              ],
                                              staticClass: "mr-1 text-nowrap",
                                              attrs: {
                                                variant: "secondary",
                                                size: "sm",
                                                "data-test": "save-as-preset"
                                              }
                                            },
                                            [
                                              _vm._v(" Save as a Preset "),
                                              _c("b-icon-box-arrow-in-down")
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-button",
                                            {
                                              directives: [
                                                {
                                                  name: "b-modal",
                                                  rawName:
                                                    "v-b-modal.load-current-preset",
                                                  modifiers: {
                                                    "load-current-preset": true
                                                  }
                                                }
                                              ],
                                              staticClass: "mr-1 text-nowrap",
                                              attrs: {
                                                variant: "secondary",
                                                size: "sm"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " Sync with Current Preset "
                                              ),
                                              _c("b-icon-box-arrow-in-up")
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-button",
                                            {
                                              directives: [
                                                {
                                                  name: "b-modal",
                                                  rawName:
                                                    "v-b-modal.refreshing-cache",
                                                  modifiers: {
                                                    "refreshing-cache": true
                                                  }
                                                }
                                              ],
                                              staticClass: "mr-1 text-nowrap",
                                              attrs: {
                                                variant: "secondary",
                                                size: "sm"
                                              },
                                              on: { click: _vm.refreshCache }
                                            },
                                            [
                                              _vm._v(" Refresh Cache "),
                                              _c("b-icon-arrow-repeat")
                                            ],
                                            1
                                          ),
                                          _vm.failureRowsFromHistogram.length >
                                          0
                                            ? _c(
                                                "b-button",
                                                {
                                                  staticClass:
                                                    "mr-1 text-nowrap",
                                                  attrs: {
                                                    variant: "secondary",
                                                    size: "sm",
                                                    href:
                                                      _vm.backendUrl +
                                                      "/export/too-few-columns/" +
                                                      _vm.taskId() +
                                                      "/?token=" +
                                                      _vm.backendToken
                                                  }
                                                },
                                                [
                                                  _vm._v(" Anomalous Rows "),
                                                  _c("b-icon-download")
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.data.metadata
                        ? _c(
                            "div",
                            { staticClass: "meta-section" },
                            [
                              _c(
                                "h4",
                                {
                                  directives: [
                                    {
                                      name: "b-toggle",
                                      rawName: "v-b-toggle",
                                      value: "meta-metadata",
                                      expression: "`meta-metadata`"
                                    }
                                  ]
                                },
                                [
                                  _vm._v(" File Metadata "),
                                  _c("b-icon-chevron-down")
                                ],
                                1
                              ),
                              _c(
                                "b-collapse",
                                {
                                  staticClass: "meta-collapse",
                                  attrs: { id: "meta-metadata" }
                                },
                                [
                                  _vm.data.metadata.FileSize
                                    ? _c(
                                        "div",
                                        { staticClass: "meta-feature" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "meta-key" },
                                            [_vm._v(" Size: ")]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "meta-value" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.data.metadata.FileSize
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.data.metadata.ModifyDate
                                    ? _c(
                                        "div",
                                        { staticClass: "meta-feature" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "meta-key" },
                                            [
                                              _vm._v(
                                                " Last Modification Date: "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "meta-value" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.data.metadata.ModifyDate
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.data.metadata.FileTypeExtension
                                    ? _c(
                                        "div",
                                        { staticClass: "meta-feature" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "meta-key" },
                                            [_vm._v(" Compression: ")]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "meta-value" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.data.metadata
                                                      .FileTypeExtension
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "meta-section" },
                        [
                          _c(
                            "h4",
                            {
                              directives: [
                                {
                                  name: "b-toggle",
                                  rawName: "v-b-toggle",
                                  value: "meta-csv-dialect",
                                  expression: "`meta-csv-dialect`"
                                }
                              ]
                            },
                            [
                              _vm._v(" CSV Dialect "),
                              _c("b-icon-chevron-down")
                            ],
                            1
                          ),
                          _c(
                            "b-collapse",
                            {
                              staticClass: "meta-collapse",
                              attrs: { id: "meta-csv-dialect" }
                            },
                            [
                              _c("div", { staticClass: "meta-feature" }, [
                                _c(
                                  "div",
                                  { staticClass: "meta-key" },
                                  [
                                    _vm._v(" Field Separator: "),
                                    _c(
                                      "b-link",
                                      {
                                        staticClass: "cursor-help",
                                        attrs: { disabled: "" }
                                      },
                                      [
                                        _c("b-icon-question-circle", {
                                          staticClass: "ml-2",
                                          attrs: {
                                            id: "help-popover-delimiter"
                                          }
                                        }),
                                        _c(
                                          "b-popover",
                                          {
                                            attrs: {
                                              target: "help-popover-delimiter",
                                              triggers: "hover"
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "title",
                                                fn: function() {
                                                  return [
                                                    _c("code", [
                                                      _vm._v(" Dialect."),
                                                      _c("strong", [
                                                        _vm._v("delimiter")
                                                      ])
                                                    ])
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ])
                                          },
                                          [
                                            _c("p", [
                                              _vm._v(
                                                " A one-character string used to separate fields. It defaults to "
                                              ),
                                              _c("code", [_vm._v("','")]),
                                              _vm._v(". ")
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "meta-value" }, [
                                  _c("code", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.stringify(
                                          _vm.data.dialect.delimiter
                                        )
                                      )
                                    )
                                  ])
                                ])
                              ]),
                              _c("div", { staticClass: "meta-feature" }, [
                                _c(
                                  "div",
                                  { staticClass: "meta-key" },
                                  [
                                    _vm._v(" Quoting Delimiter: "),
                                    _c(
                                      "b-link",
                                      {
                                        staticClass: "cursor-help",
                                        attrs: { disabled: "" }
                                      },
                                      [
                                        _c("b-icon-question-circle", {
                                          staticClass: "ml-2",
                                          attrs: {
                                            id: "help-popover-quotechar"
                                          }
                                        }),
                                        _c(
                                          "b-popover",
                                          {
                                            attrs: {
                                              target: "help-popover-quotechar",
                                              triggers: "hover"
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "title",
                                                fn: function() {
                                                  return [
                                                    _c("code", [
                                                      _vm._v(" Dialect."),
                                                      _c("strong", [
                                                        _vm._v("quotechar")
                                                      ])
                                                    ])
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ])
                                          },
                                          [
                                            _c("p", [
                                              _vm._v(
                                                " A one-character string used to quote fields containing special characters, such as the "
                                              ),
                                              _c("em", [_vm._v("delimiter")]),
                                              _vm._v(" or "),
                                              _c("em", [_vm._v("quotechar")]),
                                              _vm._v(
                                                ", or which contain new-line characters. It defaults to "
                                              ),
                                              _c("code", [_vm._v("'\"'")]),
                                              _vm._v(". ")
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "meta-value" }, [
                                  _c("code", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.stringify(
                                          _vm.data.dialect.quotechar
                                        )
                                      )
                                    )
                                  ])
                                ])
                              ]),
                              _c("div", { staticClass: "meta-feature" }, [
                                _c(
                                  "div",
                                  { staticClass: "meta-key" },
                                  [
                                    _vm._v(" Escape Character: "),
                                    _c(
                                      "b-link",
                                      {
                                        staticClass: "cursor-help",
                                        attrs: { disabled: "" }
                                      },
                                      [
                                        _c("b-icon-question-circle", {
                                          staticClass: "ml-2",
                                          attrs: {
                                            id: "help-popover-escapechar"
                                          }
                                        }),
                                        _c(
                                          "b-popover",
                                          {
                                            attrs: {
                                              target: "help-popover-escapechar",
                                              triggers: "hover"
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "title",
                                                fn: function() {
                                                  return [
                                                    _c("code", [
                                                      _vm._v(" Dialect."),
                                                      _c("strong", [
                                                        _vm._v("escapechar")
                                                      ])
                                                    ])
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ])
                                          },
                                          [
                                            _c("p", [
                                              _vm._v(
                                                " A one-character string used by the writer to escape the "
                                              ),
                                              _c("em", [_vm._v("delimiter")]),
                                              _vm._v(" if "),
                                              _c("em", [_vm._v("quoting")]),
                                              _vm._v(" is set to "),
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href:
                                                      "https://docs.python.org/3/library/csv.html#csv.QUOTE_NONE",
                                                    target: "_blank"
                                                  }
                                                },
                                                [
                                                  _c("code", [
                                                    _vm._v("QUOTE_NONE")
                                                  ])
                                                ]
                                              ),
                                              _vm._v(" and the "),
                                              _c("em", [_vm._v("quotechar")]),
                                              _vm._v(" if "),
                                              _c("em", [_vm._v("doublequote")]),
                                              _vm._v(" is "),
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href:
                                                      "https://docs.python.org/3/library/csv.html#csv.QUOTE_NONE",
                                                    target: "_blank"
                                                  }
                                                },
                                                [_c("code", [_vm._v("False")])]
                                              ),
                                              _vm._v(". On reading, the "),
                                              _c("em", [_vm._v("escapechar")]),
                                              _vm._v(
                                                " removes any special meaning from the following character. It defaults to "
                                              ),
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href:
                                                      "https://docs.python.org/3/library/constants.html#None",
                                                    target: "_blank"
                                                  }
                                                },
                                                [_c("code", [_vm._v("None")])]
                                              ),
                                              _vm._v(
                                                ", which disables escaping. "
                                              )
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "meta-value" }, [
                                  _c("code", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.stringify(
                                          _vm.data.dialect.escapechar
                                        )
                                      )
                                    )
                                  ])
                                ])
                              ]),
                              _c("div", { staticClass: "meta-feature" }, [
                                _c(
                                  "div",
                                  { staticClass: "meta-key" },
                                  [
                                    _vm._v(" Line Terminator: "),
                                    _c(
                                      "b-link",
                                      {
                                        staticClass: "cursor-help",
                                        attrs: { disabled: "" }
                                      },
                                      [
                                        _c("b-icon-question-circle", {
                                          staticClass: "ml-2",
                                          attrs: {
                                            id: "help-popover-lineterminator"
                                          }
                                        }),
                                        _c(
                                          "b-popover",
                                          {
                                            attrs: {
                                              target:
                                                "help-popover-lineterminator",
                                              triggers: "hover"
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "title",
                                                fn: function() {
                                                  return [
                                                    _c("code", [
                                                      _vm._v(" Dialect."),
                                                      _c("strong", [
                                                        _vm._v("lineterminator")
                                                      ])
                                                    ])
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ])
                                          },
                                          [
                                            _c("p", [
                                              _vm._v(
                                                " Controls when quotes should be generated by the writer and recognised by the reader. It can take on any of the "
                                              ),
                                              _c("code", [_vm._v("QUOTE_*")]),
                                              _vm._v(
                                                " constants (see section "
                                              ),
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href:
                                                      "https://docs.python.org/3/library/csv.html#csv-contents",
                                                    target: "_blank"
                                                  }
                                                },
                                                [_vm._v(" Module Contents ")]
                                              ),
                                              _vm._v(") and defaults to "),
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href:
                                                      "https://docs.python.org/3/library/csv.html#csv.QUOTE_MINIMAL",
                                                    target: "_blank"
                                                  }
                                                },
                                                [
                                                  _c("code", [
                                                    _vm._v("QUOTE_MINIMAL")
                                                  ])
                                                ]
                                              ),
                                              _vm._v(". ")
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "meta-value" }, [
                                  _c("code", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.stringify(
                                          _vm.data.dialect.lineterminator
                                        )
                                      )
                                    )
                                  ])
                                ])
                              ]),
                              _c("div", { staticClass: "meta-feature" }, [
                                _c(
                                  "div",
                                  { staticClass: "meta-key" },
                                  [
                                    _vm._v(" Quoting: "),
                                    _c(
                                      "b-link",
                                      {
                                        staticClass: "cursor-help",
                                        attrs: { disabled: "" }
                                      },
                                      [
                                        _c("b-icon-question-circle", {
                                          staticClass: "ml-2",
                                          attrs: { id: "help-popover-quoting" }
                                        }),
                                        _c(
                                          "b-popover",
                                          {
                                            attrs: {
                                              target: "help-popover-quoting",
                                              triggers: "hover"
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "title",
                                                fn: function() {
                                                  return [
                                                    _c("code", [
                                                      _vm._v(" Dialect."),
                                                      _c("strong", [
                                                        _vm._v("quoting")
                                                      ])
                                                    ])
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ])
                                          },
                                          [
                                            _c("p", [
                                              _vm._v(
                                                " Controls when quotes should be generated by the writer and recognised by the reader. It can take on any of the "
                                              ),
                                              _c("code", [_vm._v("QUOTE_*")]),
                                              _vm._v(
                                                " constants (see section "
                                              ),
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href:
                                                      "https://docs.python.org/3/library/csv.html#csv-contents",
                                                    target: "_blank"
                                                  }
                                                },
                                                [_vm._v(" Module Contents ")]
                                              ),
                                              _vm._v(") and defaults to "),
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href:
                                                      "https://docs.python.org/3/library/csv.html#csv.QUOTE_MINIMAL",
                                                    target: "_blank"
                                                  }
                                                },
                                                [
                                                  _c("code", [
                                                    _vm._v("QUOTE_MINIMAL")
                                                  ])
                                                ]
                                              ),
                                              _vm._v(". ")
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "meta-value" }, [
                                  _c("code", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.csvQuotingMapping[
                                          _vm.data.dialect.quoting
                                        ]
                                      )
                                    )
                                  ])
                                ])
                              ]),
                              _c("div", { staticClass: "meta-feature" }, [
                                _c(
                                  "div",
                                  { staticClass: "meta-key" },
                                  [
                                    _vm._v(" Double Quote: "),
                                    _c(
                                      "b-link",
                                      {
                                        staticClass: "cursor-help",
                                        attrs: { disabled: "" }
                                      },
                                      [
                                        _c("b-icon-question-circle", {
                                          staticClass: "ml-2",
                                          attrs: {
                                            id: "help-popover-doublequote"
                                          }
                                        }),
                                        _c(
                                          "b-popover",
                                          {
                                            attrs: {
                                              target:
                                                "help-popover-doublequote",
                                              triggers: "hover"
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "title",
                                                fn: function() {
                                                  return [
                                                    _c("code", [
                                                      _vm._v(" Dialect."),
                                                      _c("strong", [
                                                        _vm._v("doublequote")
                                                      ])
                                                    ])
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ])
                                          },
                                          [
                                            _c("p", [
                                              _vm._v(
                                                " Controls how instances of "
                                              ),
                                              _c("em", [_vm._v("quotechar")]),
                                              _vm._v(
                                                " appearing inside a field should themselves be quoted. When "
                                              ),
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href:
                                                      "https://docs.python.org/3/library/constants.html#True",
                                                    target: "_blank"
                                                  }
                                                },
                                                [_c("code", [_vm._v("True")])]
                                              ),
                                              _vm._v(
                                                ", the character is doubled. When "
                                              ),
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href:
                                                      "https://docs.python.org/3/library/constants.html#False",
                                                    target: "_blank"
                                                  }
                                                },
                                                [_c("code", [_vm._v("False")])]
                                              ),
                                              _vm._v(", the "),
                                              _c("em", [_vm._v("escapechar")]),
                                              _vm._v(
                                                " is used as a prefix to the "
                                              ),
                                              _c("em", [_vm._v("quotechar")]),
                                              _vm._v(". It defaults to "),
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href:
                                                      "https://docs.python.org/3/library/constants.html#True",
                                                    target: "_blank"
                                                  }
                                                },
                                                [_c("code", [_vm._v("True")])]
                                              ),
                                              _vm._v(". ")
                                            ]),
                                            _c("p", [
                                              _vm._v(" On output, if "),
                                              _c("em", [_vm._v("doublequote")]),
                                              _vm._v(" is "),
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href:
                                                      "https://docs.python.org/3/library/constants.html#False",
                                                    target: "_blank"
                                                  }
                                                },
                                                [_c("code", [_vm._v("False")])]
                                              ),
                                              _vm._v(" and no "),
                                              _c("em", [_vm._v("escapechar")]),
                                              _vm._v(" is set, "),
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href:
                                                      "https://docs.python.org/3/library/csv.html#csv.Error",
                                                    target: "_blank"
                                                  }
                                                },
                                                [_c("code", [_vm._v("Error")])]
                                              ),
                                              _vm._v(" is raised if a "),
                                              _c("em", [_vm._v("quotechar")]),
                                              _vm._v(" is found in a field. ")
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "meta-value" }, [
                                  _c("code", [
                                    _vm._v(_vm._s(_vm.data.dialect.doublequote))
                                  ])
                                ])
                              ]),
                              _c("div", { staticClass: "meta-feature" }, [
                                _c(
                                  "div",
                                  { staticClass: "meta-key" },
                                  [
                                    _vm._v(" Skip Initial Space: "),
                                    _c(
                                      "b-link",
                                      {
                                        staticClass: "cursor-help",
                                        attrs: { disabled: "" }
                                      },
                                      [
                                        _c("b-icon-question-circle", {
                                          staticClass: "ml-2",
                                          attrs: {
                                            id: "help-popover-skipinitialspace"
                                          }
                                        }),
                                        _c(
                                          "b-popover",
                                          {
                                            attrs: {
                                              target:
                                                "help-popover-skipinitialspace",
                                              triggers: "hover"
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "title",
                                                fn: function() {
                                                  return [
                                                    _c("code", [
                                                      _vm._v(" Dialect."),
                                                      _c("strong", [
                                                        _vm._v(
                                                          "skipinitialspace"
                                                        )
                                                      ])
                                                    ])
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ])
                                          },
                                          [
                                            _c("p", [
                                              _vm._v(" When "),
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href:
                                                      "https://docs.python.org/3/library/constants.html#True",
                                                    target: "_blank"
                                                  }
                                                },
                                                [_c("code", [_vm._v("True")])]
                                              ),
                                              _vm._v(
                                                ", whitespace immediately following the "
                                              ),
                                              _c("em", [_vm._v("delimiter")]),
                                              _vm._v(
                                                " is ignored. The default is "
                                              ),
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href:
                                                      "https://docs.python.org/3/library/constants.html#False",
                                                    target: "_blank"
                                                  }
                                                },
                                                [_c("code", [_vm._v("False")])]
                                              ),
                                              _vm._v(". ")
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "meta-value" }, [
                                  _c("code", [
                                    _vm._v(
                                      _vm._s(_vm.data.dialect.skipinitialspace)
                                    )
                                  ])
                                ])
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "meta-section",
                          attrs: { id: "meta-csv-summary" }
                        },
                        [
                          _c(
                            "h4",
                            {
                              directives: [
                                {
                                  name: "b-toggle",
                                  rawName: "v-b-toggle",
                                  value: "meta-csv-summary",
                                  expression: "`meta-csv-summary`"
                                }
                              ]
                            },
                            [
                              _vm._v(" CSV Data Summary "),
                              _c("b-icon-chevron-down")
                            ],
                            1
                          ),
                          _c(
                            "b-collapse",
                            {
                              staticClass: "meta-collapse",
                              attrs: { id: "meta-csv-summary" }
                            },
                            [
                              _c("div", { staticClass: "meta-feature" }, [
                                _c("div", { staticClass: "meta-key" }, [
                                  _vm._v(" Column Count: ")
                                ]),
                                _c("div", {
                                  staticClass: "meta-value",
                                  domProps: {
                                    textContent: _vm._s(_vm.data.columnCount)
                                  }
                                })
                              ]),
                              _c("div", { staticClass: "meta-feature" }, [
                                _c("div", { staticClass: "meta-key" }, [
                                  _vm._v(" Total Rows: ")
                                ]),
                                _c("div", {
                                  staticClass: "meta-value",
                                  attrs: { "data-test": "total-rows" },
                                  domProps: {
                                    textContent: _vm._s(_vm.data.totalRows)
                                  }
                                })
                              ]),
                              _c("div", { staticClass: "meta-feature" }, [
                                _c(
                                  "div",
                                  { staticClass: "meta-key" },
                                  [
                                    _vm._v(" Column Row Count: "),
                                    _vm.failureRowsFromHistogram.length === 0
                                      ? _c("b-icon-check-circle", {
                                          staticClass: "text-success"
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "meta-value" },
                                  [
                                    _c(
                                      "table",
                                      {
                                        staticClass:
                                          "\n                        table table-sm table-borderless table-responsive\n                        overflow-hidden\n                        mb-1\n                      ",
                                        staticStyle: { "max-height": "70px" }
                                      },
                                      [
                                        _c(
                                          "tbody",
                                          _vm._l(_vm.histogram, function(
                                            histogramEntry
                                          ) {
                                            return _c(
                                              "tr",
                                              {
                                                key: histogramEntry.columnCount,
                                                class:
                                                  "text-" +
                                                  (_vm.isHistogramEntryFailure(
                                                    histogramEntry
                                                  )
                                                    ? "danger"
                                                    : "success")
                                              },
                                              [
                                                _c(
                                                  "th",
                                                  { staticClass: "pl-0 pt-0" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          histogramEntry.columnCount
                                                        ) +
                                                        ": "
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  { staticClass: "pl-0 pt-0" },
                                                  [
                                                    !_vm.isHistogramEntryFailure(
                                                      histogramEntry
                                                    )
                                                      ? _c("strong", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                histogramEntry.rowCount
                                                              ) +
                                                              " "
                                                          )
                                                        ])
                                                      : [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                histogramEntry.rowCount
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                  ],
                                                  2
                                                )
                                              ]
                                            )
                                          }),
                                          0
                                        )
                                      ]
                                    ),
                                    _vm.histogram.length > 2
                                      ? _c(
                                          "b-button",
                                          {
                                            directives: [
                                              {
                                                name: "b-modal",
                                                rawName:
                                                  "v-b-modal.column-row-count",
                                                modifiers: {
                                                  "column-row-count": true
                                                }
                                              }
                                            ],
                                            attrs: {
                                              variant: "outline-secondary",
                                              size: "sm"
                                            }
                                          },
                                          [_vm._v(" Show All ")]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ])
                  ])
                ]
              ),
              _c(
                "b-tab",
                {
                  staticClass: "px-2",
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function() {
                        return [
                          _c(
                            "span",
                            { attrs: { "data-test": "column-list-tab-title" } },
                            [_vm._v("List of Columns")]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c(
                    "div",
                    { staticClass: "mb-2" },
                    [
                      _vm.deployAll
                        ? _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName: "v-b-modal.change-group-order",
                                  modifiers: { "change-group-order": true }
                                }
                              ],
                              staticClass: "mr-2",
                              attrs: {
                                variant: "outline-secondary",
                                size: "sm",
                                "data-test": "change-group-order"
                              }
                            },
                            [
                              _vm._v(" Change Order of Groups "),
                              _c("b-icon-list-ol", { staticClass: "ml-1" })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.deployAll
                        ? _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName: "v-b-modal.select-new-group",
                                  modifiers: { "select-new-group": true }
                                }
                              ],
                              staticClass: "mr-2",
                              attrs: {
                                variant: "outline-secondary",
                                size: "sm",
                                "data-test": "change-columns-group"
                              }
                            },
                            [
                              _vm._v(" Batch Group Assignment "),
                              _c("b-icon-menu-button-wide-fill", {
                                staticClass: "ml-1"
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "b-dropdown",
                        {
                          staticClass: "float-right",
                          attrs: { variant: "primary", size: "sm" },
                          scopedSlots: _vm._u([
                            {
                              key: "button-content",
                              fn: function() {
                                return [
                                  _vm._v(" Export as "),
                                  _c("b-icon-download", { staticClass: "ml-1" })
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c(
                            "b-dropdown-item",
                            {
                              attrs: {
                                href:
                                  _vm.backendUrl +
                                  "/export/report/" +
                                  _vm.ordering.selected +
                                  "-order/" +
                                  _vm.taskId() +
                                  "/rtf/?token=" +
                                  _vm.backendToken
                              }
                            },
                            [
                              _c("pre", { staticClass: "d-inline" }, [
                                _vm._v(".rtf")
                              ])
                            ]
                          ),
                          _c(
                            "b-dropdown-item",
                            {
                              attrs: {
                                href:
                                  _vm.backendUrl +
                                  "/export/report/" +
                                  _vm.ordering.selected +
                                  "-order/" +
                                  _vm.taskId() +
                                  "/pdf/?token=" +
                                  _vm.backendToken
                              }
                            },
                            [
                              _c("pre", { staticClass: "d-inline" }, [
                                _vm._v(".pdf")
                              ])
                            ]
                          ),
                          _c(
                            "b-dropdown-item",
                            {
                              attrs: {
                                href:
                                  _vm.backendUrl +
                                  "/export/report/" +
                                  _vm.ordering.selected +
                                  "-order/" +
                                  _vm.taskId() +
                                  "/csv/?token=" +
                                  _vm.backendToken
                              }
                            },
                            [
                              _c("pre", { staticClass: "d-inline" }, [
                                _vm._v(".csv")
                              ]),
                              _vm._v(" (no definitions) ")
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex mb-2" },
                    [
                      _c(
                        "div",
                        { staticClass: "flex-fill" },
                        [
                          _c("SearchBox", {
                            ref: "searchBox",
                            staticClass: "mb-2",
                            attrs: {
                              placeholder:
                                "Search columns by name, label or group",
                              data: _vm.originalHeaderToShow(),
                              "features-to-query": [
                                "name",
                                "title",
                                "group.title"
                              ],
                              filtered: _vm.filteredOriginalHeaderToShow
                            },
                            on: {
                              "update:filtered": function($event) {
                                _vm.filteredOriginalHeaderToShow = $event
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c("b-form-select", {
                        staticClass: "ml-2",
                        staticStyle: { "max-width": "300px" },
                        attrs: { options: _vm.ordering.options },
                        model: {
                          value: _vm.ordering.selected,
                          callback: function($$v) {
                            _vm.$set(_vm.ordering, "selected", $$v)
                          },
                          expression: "ordering.selected"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.deployAll &&
                  _vm.areDefinitionsLoaded() &&
                  _vm.missingDefinitions().length > 0
                    ? _c(
                        "b-alert",
                        {
                          staticClass: "mb-1",
                          attrs: { show: true, variant: "warning" }
                        },
                        [
                          _c("strong", { staticClass: "mr-1" }, [
                            _vm._v(
                              " There are " +
                                _vm._s(_vm.missingDefinitions().length) +
                                " missing definitions in the report. "
                            )
                          ]),
                          _c("a", {
                            attrs: {
                              href: _vm.collectionUrl(),
                              target: "_blank"
                            },
                            domProps: {
                              textContent: _vm._s("Fill in the gaps.")
                            }
                          })
                        ]
                      )
                    : _vm.deployAll && !_vm.areDefinitionsLoaded()
                    ? _c("b-alert", {
                        staticClass: "mb-1",
                        attrs: { show: true, variant: "warning" },
                        domProps: {
                          textContent: _vm._s("Couldn't fetch definitions.")
                        }
                      })
                    : _vm._e(),
                  _vm.orderedFilteredOriginalHeaderToShow().length > 0
                    ? _c(
                        "table",
                        {
                          staticClass:
                            "table table-sm table-borderless table-hover header-fixed"
                        },
                        [
                          _c("thead", [
                            _vm.deployAll
                              ? _c("tr", [
                                  _c("th", {
                                    staticClass: "pl-0 pt-0 cell-column-no"
                                  }),
                                  _c("th", {
                                    staticClass: "pl-0 pt-0 cell-column-name"
                                  }),
                                  _c("th", {
                                    staticClass: "pl-0 pt-0 cell-column-label"
                                  }),
                                  _c("th", {
                                    staticClass: "pl-0 pt-0 cell-column-group"
                                  }),
                                  _c("th", {
                                    staticClass:
                                      "pl-0 pt-0 cell-column-group-edit"
                                  }),
                                  _c("th", {
                                    staticClass: "pl-0 pt-0 cell-supplier"
                                  }),
                                  _c(
                                    "th",
                                    {
                                      staticClass:
                                        "pl-0 pt-0 cell-show-hide text-center"
                                    },
                                    [_vm._v(" Show ")]
                                  )
                                ])
                              : _vm._e(),
                            _c(
                              "tr",
                              [
                                _c(
                                  "th",
                                  { staticClass: "pl-2 pt-0 cell-column-no" },
                                  [_vm._v(" # ")]
                                ),
                                _c(
                                  "th",
                                  { staticClass: "pl-0 pt-0 cell-column-name" },
                                  [_vm._v(" Column Name ")]
                                ),
                                _c(
                                  "th",
                                  {
                                    staticClass: "pl-0 pt-0 cell-column-label"
                                  },
                                  [_vm._v(" Label ")]
                                ),
                                _c(
                                  "th",
                                  {
                                    staticClass: "pl-0 pt-0 cell-column-group"
                                  },
                                  [_vm._v(" Group ")]
                                ),
                                _vm.deployAll
                                  ? [
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "pl-0 pt-0 cell-column-group-edit text-center"
                                        },
                                        [
                                          _c("div", [_vm._v("Batch")]),
                                          _c("div", [_vm._v("Group")])
                                        ]
                                      ),
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "pl-0 pt-0 cell-supplier text-center"
                                        },
                                        [_vm._v(" Supplied By ")]
                                      ),
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "pr-1 pt-0 cell-show-hide-entry text-right"
                                        },
                                        [_vm._v(" Column ")]
                                      ),
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "pl-1 pt-0 cell-show-hide-entry text-left"
                                        },
                                        [_vm._v(" Group ")]
                                      )
                                    ]
                                  : [
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "pl-0 pt-0 cell-column-type"
                                        },
                                        [_vm._v(" Data Type ")]
                                      ),
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "pl-0 pt-0 cell-column-num"
                                        },
                                        [_vm._v(" Min. ")]
                                      ),
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "pl-0 pt-0 cell-column-num"
                                        },
                                        [_vm._v(" Max. ")]
                                      )
                                    ]
                              ],
                              2
                            )
                          ]),
                          _c(
                            "tbody",
                            [
                              _c("RecycleScroller", {
                                attrs: {
                                  "page-mode": "",
                                  "key-field": "index",
                                  items: _vm.orderedFilteredOriginalHeaderToShow(),
                                  "item-size": 28.8,
                                  "emit-update": true
                                },
                                on: { update: _vm.updateScroller },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var item = ref.item
                                        return [
                                          _c(
                                            "tr",
                                            {
                                              class: _vm.columnEntryClasses(
                                                item.entry.name,
                                                item.entry.supplier
                                              ),
                                              on: {
                                                click: function($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "left",
                                                      37,
                                                      $event.key,
                                                      ["Left", "ArrowLeft"]
                                                    )
                                                  ) {
                                                    return null
                                                  }
                                                  if (
                                                    "button" in $event &&
                                                    $event.button !== 0
                                                  ) {
                                                    return null
                                                  }
                                                  return _vm.columnClicked(
                                                    "left",
                                                    item.entry,
                                                    item.entry.group
                                                  )
                                                },
                                                mouseup: function($event) {
                                                  if (
                                                    "button" in $event &&
                                                    $event.button !== 1
                                                  ) {
                                                    return null
                                                  }
                                                  return _vm.columnClicked(
                                                    "middle",
                                                    item.entry,
                                                    item.entry.group
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "th",
                                                {
                                                  staticClass:
                                                    "pl-2 pt-0 cell-column-no"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.entry.no + 1
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "pl-0 pt-0 cell-column-name d-flex",
                                                  attrs: {
                                                    id: "row-" + item.entry.no
                                                  }
                                                },
                                                [
                                                  _c("span", {
                                                    staticClass:
                                                      "mr-2 flex-fill text-truncate",
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        item.queried.name
                                                      )
                                                    }
                                                  }),
                                                  _vm.deployAll
                                                    ? _c(
                                                        "div",
                                                        [
                                                          _vm.areDefinitionsLoaded() &&
                                                          !_vm.hasDefinition(
                                                            item.entry.name
                                                          )
                                                            ? _c(
                                                                "b-badge",
                                                                {
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    pill: ""
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " no definition "
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ]
                                              ),
                                              _c("td", {
                                                staticClass:
                                                  "pl-0 pt-0 text-truncate cell-column-label",
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    item.queried.title
                                                  )
                                                }
                                              }),
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "pl-0 pt-0 cell-column-group d-flex"
                                                },
                                                [
                                                  _c("span", {
                                                    staticClass:
                                                      "flex-fill text-truncate",
                                                    attrs: {
                                                      "data-test":
                                                        "column-group-name-" +
                                                        item.entry.name
                                                    },
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        item.queried[
                                                          "group.title"
                                                        ]
                                                      )
                                                    }
                                                  })
                                                ]
                                              ),
                                              _vm.deployAll
                                                ? [
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "pl-0 pt-0 cell-column-group-edit text-center",
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("b-form-checkbox", {
                                                          attrs: {
                                                            value:
                                                              item.entry.name,
                                                            "data-test":
                                                              "batch-column-group-" +
                                                              item.entry.name
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.batchGroupEdit,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.batchGroupEdit = $$v
                                                            },
                                                            expression:
                                                              "batchGroupEdit"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "pl-0 pt-0 cell-supplier text-center",
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "btn-group-toggle"
                                                          },
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                class: {
                                                                  btn: true,
                                                                  "btn-outline-secondary": true,
                                                                  "btn-sm": true,
                                                                  "mr-1": true,
                                                                  active:
                                                                    item.entry
                                                                      .supplier ===
                                                                    "customer"
                                                                },
                                                                staticStyle: {
                                                                  "line-height":
                                                                    "1"
                                                                }
                                                              },
                                                              [
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name:
                                                                        "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        item
                                                                          .entry
                                                                          .supplier,
                                                                      expression:
                                                                        "item.entry.supplier"
                                                                    }
                                                                  ],
                                                                  attrs: {
                                                                    type:
                                                                      "radio",
                                                                    value:
                                                                      "customer"
                                                                  },
                                                                  domProps: {
                                                                    checked: _vm._q(
                                                                      item.entry
                                                                        .supplier,
                                                                      "customer"
                                                                    )
                                                                  },
                                                                  on: {
                                                                    change: [
                                                                      function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.$set(
                                                                          item.entry,
                                                                          "supplier",
                                                                          "customer"
                                                                        )
                                                                      },
                                                                      function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.supplierChanged(
                                                                          item.entry
                                                                        )
                                                                      }
                                                                    ]
                                                                  }
                                                                }),
                                                                _vm._v(
                                                                  " Customer "
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "label",
                                                              {
                                                                class: {
                                                                  btn: true,
                                                                  "btn-outline-primary": true,
                                                                  "btn-sm": true,
                                                                  active:
                                                                    item.entry
                                                                      .supplier ===
                                                                    "profound"
                                                                },
                                                                staticStyle: {
                                                                  "line-height":
                                                                    "1"
                                                                }
                                                              },
                                                              [
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name:
                                                                        "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        item
                                                                          .entry
                                                                          .supplier,
                                                                      expression:
                                                                        "item.entry.supplier"
                                                                    }
                                                                  ],
                                                                  attrs: {
                                                                    type:
                                                                      "radio",
                                                                    value:
                                                                      "profound"
                                                                  },
                                                                  domProps: {
                                                                    checked: _vm._q(
                                                                      item.entry
                                                                        .supplier,
                                                                      "profound"
                                                                    )
                                                                  },
                                                                  on: {
                                                                    change: [
                                                                      function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.$set(
                                                                          item.entry,
                                                                          "supplier",
                                                                          "profound"
                                                                        )
                                                                      },
                                                                      function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.supplierChanged(
                                                                          item.entry
                                                                        )
                                                                      }
                                                                    ]
                                                                  }
                                                                }),
                                                                _vm._v(
                                                                  " Profound "
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "pr-1 pt-0 cell-show-hide-entry text-right",
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("b-form-checkbox", {
                                                          attrs: {
                                                            value:
                                                              item.entry.name,
                                                            "data-test":
                                                              "column-show-checkbox_" +
                                                              item.entry.name
                                                          },
                                                          on: {
                                                            change: function(
                                                              $event
                                                            ) {
                                                              return _vm.showColumnSwitched(
                                                                item.entry.name
                                                              )
                                                            }
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.columnsToShow,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.columnsToShow = $$v
                                                            },
                                                            expression:
                                                              "columnsToShow"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "pl-3 pt-0 cell-show-hide-entry text-left",
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("b-form-checkbox", {
                                                          ref:
                                                            item.entry.name +
                                                            "GroupCheckbox",
                                                          attrs: {
                                                            value:
                                                              item.entry.name,
                                                            "data-test":
                                                              "group-show-checkbox_" +
                                                              item.entry.name
                                                          },
                                                          on: {
                                                            change: function(
                                                              $event
                                                            ) {
                                                              return _vm.showGroupSwitched(
                                                                item.entry.name
                                                              )
                                                            }
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.columnsToShow,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.columnsToShow = $$v
                                                            },
                                                            expression:
                                                              "columnsToShow"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                : [
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "pl-0 pt-0 cell-column-type"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.entry.type
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "pl-0 pt-0 cell-column-num"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.entry.minLen
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "pl-0 pt-0 cell-column-num"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.entry.maxLen
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                            ],
                                            2
                                          ),
                                          item.entry.supplier === "customer"
                                            ? _c("b-popover", {
                                                attrs: {
                                                  triggers: "hover",
                                                  placement: "right",
                                                  target: "row-" + item.entry.no
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "title",
                                                      fn: function() {
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.entry.title
                                                              ) +
                                                              " "
                                                          ),
                                                          _c(
                                                            "b-badge",
                                                            {
                                                              attrs: {
                                                                variant:
                                                                  "secondary"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Customer-supplied Data "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      },
                                                      proxy: true
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            : _c(
                                                "b-popover",
                                                {
                                                  attrs: {
                                                    triggers: "hover",
                                                    placement: "right",
                                                    target:
                                                      "row-" + item.entry.no,
                                                    title: item.entry.name
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "title",
                                                        fn: function() {
                                                          return [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.labelFromDefinition(
                                                                    item.entry
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                            _c(
                                                              "b-badge",
                                                              {
                                                                attrs: {
                                                                  variant:
                                                                    "primary"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Supplied by Profound Networks "
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        },
                                                        proxy: true
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    985169178
                                                  )
                                                },
                                                [
                                                  _c("div", {
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        _vm.renderedDefinition(
                                                          item.entry.name
                                                        )
                                                      )
                                                    }
                                                  })
                                                ]
                                              )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  3946943170
                                )
                              })
                            ],
                            1
                          )
                        ]
                      )
                    : _c("div", { staticClass: "ml-5 mt-2" }, [
                        _c("em", [
                          _vm._v("No column meets requested search query.")
                        ])
                      ]),
                  _vm.showVisualizationChangesSummary
                    ? _c(
                        "div",
                        [
                          _c("h3", [
                            _vm._v(
                              "Changes were introduced to the layout of the report."
                            )
                          ]),
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName: "v-b-modal.apply-changes",
                                  modifiers: { "apply-changes": true }
                                }
                              ],
                              staticClass: "mr-1",
                              attrs: {
                                variant: "primary",
                                "data-test": "review-and-save"
                              }
                            },
                            [_vm._v(" Review and Save ")]
                          ),
                          _c(
                            "b-button",
                            {
                              staticClass: "ml-1",
                              attrs: { variant: "secondary" },
                              on: { click: _vm.unapplyChanges }
                            },
                            [_vm._v(" Discard ")]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }