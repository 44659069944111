<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
    <div class="container">
      <div class="navbar-brand">
        <img
          src="../images/logo.png"
          class="hidden-xs"
          height="60px"
        >
        CSV Analyzer
      </div>
      <div class="text-light h3">
        {{ filename }}
      </div>
    </div>
  </nav>
</template>

<script>
  export default {
    data: () => ({filename: null}),
    watch: {
      filename(value) {
        document.title = value
      }
    }
  }
</script>

