<template>
  <span>
    <img
      class="border"
      :src="`${backendUrl}/hilbert/${taskId}/${columnName}/?token=${backendToken}`"
      width="512"
      height="512"
      @mousemove="mapHovered"
      @mouseout="mapOut"
      @click="mapChoose"
    >
  </span>
</template>


<script>
  export default {
    props: {columnName: {type: String, default: null}},
    data() {
      return {
        image: null,
        hoveredIp: null,
        chosenIp: null
      };
    },
    computed: {
      backendUrl: () => process.env.VUE_APP_VISUALIZER_BACKEND_URL,
      backendToken: () => process.env.VUE_APP_BACKEND_TOKEN,
      taskId() { return this.$route.params.taskId; }
    },
    methods: {
      mapHovered(event) {
        if (event.offsetX < 0 || event.offsetY < 0) {
          return;
        }
        const firstOctet = Math.floor(event.offsetX / 2);
        const secondOctet = Math.floor(event.offsetY / 2);
        this.hoveredIp = `${firstOctet}.${secondOctet}.0.0/16`;
      },
      mapOut() {
        this.hoveredIp = this.chosenIp;
      },
      mapChoose() {
        this.chosenIp = this.hoveredIp;
      }
    },
  };
</script>
