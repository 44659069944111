<template>
  <VueApexCharts
    type="bar"
    :options="options"
    :series="series"
  />
</template>

<script>
  import Humanize from 'humanize-plus';
  import VueApexCharts from 'vue-apexcharts';
  import colors from './../../modules/colors.js';
  export default {
    components: {VueApexCharts},
    props: {group: {type: Object, default: null}},
    computed: {
      uniqueMostCommonValues() {
        return Array.from(
          new Set(
            Object.values(this.group).reduce(
              (accumulator, mostCommon) => accumulator.concat(
                mostCommon.map(element => element.value)
              ), []
            )
          )
        );
      },
      options() {
        return {
          plotOptions: {bar: {dataLabels: {position: 'top'}}},
          dataLabels: {
            formatter: (value,) => Humanize.compactInteger(value || 0, 1),
            style: {colors: ['#3e4c51']},
            offsetY: -20
          },
          colors: colors.distinctColors(this.uniqueMostCommonValues.length),
          grid: {padding: {left: 50}},
          xaxis: {categories: Object.keys(this.group)},
          yaxis: {
            labels: {formatter: value => Humanize.intComma(parseInt(value))}
          }
        };
      },
      series() {
        return this.uniqueMostCommonValues.map(
          value => (
            {
              name: value,
              data: Object.values(this.group).map(
                mostCommon => mostCommon.filter(
                  element => element.value === value
                ).map(element => element.count)[0]
              )
            }
          )
        );
      },
    },
  };
</script>
