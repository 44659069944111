<template>
  <div>
    <div class="row ml-5">
      <b-form-checkbox
        v-model="showEmpty"
        @change="refresh"
      >
        Show Empty
      </b-form-checkbox>
    </div>
    <div class="row">
      <VueApexCharts
        ref="chart"
        type="pie"
        height="500px"
        :options="options"
        :series="series"
        class="col"
      />
      <table class="legend col table table-sm table-responsive table-borderless">
        <tbody>
          <tr
            v-for="(legendItem, index) in legend"
            :key="legendItem.label"
            :class="{'font-weight-bold': legendItem.isSelected, 'bg-secondary': legendItem.isHovered, 'text-light': legendItem.isHovered}"
            @click="selectData(index)"
            @mouseover="hoverData(legendItem)"
            @mouseout="unhoverData(legendItem)"
          >
            <td class="d-flex">
              <span
                class="legend-color"
                :style="{'background-color': legendItem.color}"
              />
            </td>
            <td class="legend-label text-truncate">
              <a
                v-if="type === 'url' && legendItem.label && !isOther(legendItem)"
                :href="legendItem.label"
                target="_blank"
                :class="{'text-light': legendItem.isHovered}"
              >
                {{ legendItem.label }}
              </a>
              <template v-else-if="legendItem.label">
                {{ legendItem.label }}
              </template>
              <em v-else>empty</em>
            </td>
            <td class="text-right">
              {{ humanize(legendItem.value) }}
            </td>
            <td
              v-if="type === 'domain' || type === 'domain-key'"
              class="w-fit"
            >
              <img
                v-if="!isOther(legendItem)"
                :src="`${backendUrl}/favicon/${legendItem.label}/?token=${backendToken}`"
                width="20"
                height="20"
                onerror="this.classList.add('d-none')"
              >
            </td>
            <td v-else-if="type === 'country'">
              <span
                v-if="!isOther(legendItem)"
                :class="`flag-icon flag-icon-${countryCode(legendItem.label)} border`"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import VueApexCharts from 'vue-apexcharts';
  import Humanize from 'humanize-plus';
  import colors from './../../modules/colors.js';
  export default {
    components: {VueApexCharts},
    props: {
      label: {type: String, default: 'string'},
      type: {type: String, default: 'string'},
      totalCount: {type: Number, default: 0},
      mostCommonValues: {type: Array, default: null},
      ranges: {type: Boolean, default: false}
    },
    data: () => {
      return {
        selectedItem: null,
        icons: {},
        legend: [],
        showEmpty: true,
        dataToShow: [],
        options: {},
        series: [],
      };
    },
    computed: {
      backendUrl: () => process.env.VUE_APP_VISUALIZER_BACKEND_URL,
      backendToken: () => process.env.VUE_APP_BACKEND_TOKEN,
    },
    watch: {
      mostCommonValues: {
        handler() {
          this.refresh();
        }
      }
    },
    mounted() { this.refresh(); },
    methods: {
      refreshLegend() {
        var dataToShow = this.dataToShow;
        if (dataToShow === []) {
          this.legend = [];
          return;
        }
        var legendColors = null;
        if (this.type !== 'color') {
          legendColors = colors.distinctColors(
            dataToShow.filter(element => element.value).length
          );
        } else {
          legendColors = [
            ['Y', 'rgb(255, 255, 0)'],
            ['R', 'rgb(255, 0, 0)'],
            ['G', 'rgb(100, 150, 0)'],
            ['VG', 'rgb(0, 255, 0)'],
          ].sort(
            ([value0,], [value1,]) => {
              const index0 = dataToShow.findIndex(
                element => value0 === element.value
              );
              const index1 = dataToShow.findIndex(
                element => value1 === element.value
              );
              return index0 - index1;
            }
          ).map(([,color]) => color);
        }
        const emptyIndexes = dataToShow.map(
          (element, index) => [element, index]
        ).filter(([element,]) => !element.value).map(([, index]) => index);
        if (emptyIndexes.length > 0) {
          legendColors.splice(emptyIndexes[0], 0, 'rgb(159, 166, 168)');
        }
        if (this.ranges) {
          var rangeStart = null;
          dataToShow = dataToShow.sort(
            (a, b) => {
              const aIntValue = this.parseIntValue(a.value);
              const bIntValue = this.parseIntValue(b.value);
              if (isNaN(aIntValue)) {
                return 1 - isNaN(bIntValue);
              }
              return aIntValue - bIntValue;
            }
          ).map(
            element => {
              const isInfinity = element.value === 'inf';
              if (!isInfinity && isNaN(parseInt(element.value))) {
                return element;
              }
              var rangeDescription = null;
              if (rangeStart === null) {
                rangeDescription = `up to ${element.value}`;
              } else if (isInfinity) {
                rangeDescription = `over ${rangeStart}`;
              } else {
                rangeDescription = `${rangeStart} – ${element.value}`;
              }
              rangeStart = element.value;
              return {...element, value: rangeDescription};
            }
          );
        }
        const legend = dataToShow.map(
          (element, index) => this.legendItemConstructor(
            element.value, legendColors[index], element.count
          )
        );
        if (this.other() > 0) {
          legend.push(
            this.legendItemConstructor(
              'Other', 'rgb(62, 76, 81)', this.other()
            )
          );
        }
        this.legend = legend;
      },
      refreshDataToShow() {
        if (this.showEmpty) {
          this.dataToShow = (this.mostCommonValues || []).slice(0, 20);
          return;
        }
        this.dataToShow = (this.mostCommonValues || []).filter(
          entry => entry.value && entry.value !== ''
        ).slice(0, 20);
      },
      refresh() {
        this.refreshDataToShow();
        this.refreshLegend();
        this.refreshOptions();
        this.refreshSeries();
      },
      other() {
        return this.totalCount - this.dataToShow.reduce(
          (accumulator, element) => accumulator + element.count, 0
        );
      },
      refreshSeries() {
        this.series = this.legend.map(element => element.value);
      },
      labels() { return this.legend.map(element => element.label); },
      colors() { return this.legend.map(element => element.color); },
      refreshOptions() {
        this.options = {
          chart: {
            toolbar: {show: true},
            events: {
              dataPointMouseEnter: (event, chartContext, config) => {
                this.legend[config.dataPointIndex].isHovered = true;
              },
              dataPointMouseLeave: (event, chartContext, config) => {
                this.legend[config.dataPointIndex].isHovered = false;
              },
              dataPointSelection: (event, chartContext, config) => {
                if (this.selectedItem !== null) {
                  this.selectedItem.isSelected = false;
                }
                this.selectedItem = this.legend[config.dataPointIndex];
                this.selectedItem.isSelected = true;
              },
            }
          },
          legend: {show: false},
          labels: this.labels(),
          colors: this.colors(),
          tooltip: {
            custom: ({ seriesIndex }) => {
              const entry = this.dataToShow[seriesIndex];
              if (!entry) {
                return null;
              }
              const label = entry.value ? entry.value : '<em>empty</em>';
              return (
                `<div class="px-2 py-1">` +
                `  ${label}: <strong>${entry.count}</strong>` +
                `</div>`
              );
            }
          },
        };
      },
      elementByPartial: (key, value) => element => element[key] === value,
      legendItemConstructor(value, color, count) {
        return {
          label: value,
          color: color,
          value: count,
          isSelected: false,
          isHovered: false,
        }
      },
      humanize: Humanize.intComma,
      isOther: legendItem => legendItem.label === 'Other',
      hoverData(legendItem) { legendItem.isHovered = true; },
      unhoverData(legendItem) { legendItem.isHovered = false; },
      selectData(index) {
        this.$refs.chart.toggleDataPointSelection(index);
      },
      countryCode(value) {
        if (!this.label.toLowerCase().includes('lang')) {
          return value.toLowerCase().substr(0, 2);
        }
        const split = value.toLowerCase().split('-');
        return split[split.length - 1];
      },
      parseIntValue(value) {
        if (value === 'inf') {
          return Infinity;
        }
        return parseInt(value);
      },
    }
  };
</script>

<style scoped>
  .legend {
    max-width: 370px;
    cursor: pointer;
  }

  .legend-color {
    width: 20px;
    height: 20px;
  }

  .legend-label {
    max-width: 200px;
  }

  .legend-value {
    max-width: 100px;
  }
</style>
