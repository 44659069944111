<template>
  <LMap
    :center="[20, 0]"
    :zoom="2"
    style="height: 550px"
    :options="mapOptions"
  >
    <ChoroplethLayer
      :data="data"
      id-key="countryCode"
      title-key="countryName"
      :value="{ key: 'count', metric: 'entries in total' }"
      :extra-values="[{ key: 'rate', metric: ' of all entries' }]"
      geojson-id-key="iso_a2"
      :geojson="worldMap"
      :color-scale="mapColorScale"
      :stroke-width="1"
      :current-stroke-width="2"
      current-stroke-color="D12239"
    >
      <template slot-scope="props">
        <InfoControl
          :item="infoControlItem(props.currentItem)"
          :unit="props.unit"
          title="World Countries"
          placeholder="Hover over a country"
          position="bottomleft"
        />
      </template>
    </ChoroplethLayer>
  </LMap>
</template>


<script>
import Humanize from "humanize-plus";
import { LMap } from "vue2-leaflet";
import { InfoControl } from "vue-choropleth";
import worldMap from "../../geodata/world.json";
import iso2CountryCodes from "../../geodata/iso2-country-codes.json";
import ChoroplethLayer from "./ChoroplethLayer.vue";

export default {
  components: { LMap, InfoControl, ChoroplethLayer },
  props: {
    label: { type: String, default: null },
    mostCommonValues: { type: Array, default: null },
  },
  data() {
    return {
      worldMap,
      mapColorScale: ["#FDC9C4", "#D12239"],
      mapOptions: {
        attributionControl: false,
        zoomControl: false,
        scrollWheelZoom: false,
        dragging: false,
      },
    };
  },
  computed: {
    data() {
      return (this.mostCommonValues || [])
        .map((element) => {
          if (typeof element.value !== "string") {
            return null;
          }
          const countryCode = this.countryCode(element.value);
          const countryName = iso2CountryCodes[countryCode];
          return { countryCode, countryName, ...element };
        })
        .filter((element) =>
          Object.prototype.hasOwnProperty.call(
            iso2CountryCodes,
            element.countryCode
          )
        );
    },
  },
  methods: {
    humanize: Humanize.intComma,
    countryCode(value) {
      if (!this.label.toLowerCase().includes("lang")) {
        return value.toLowerCase().substr(0, 2).toUpperCase();
      }
      const split = value.toLowerCase().split("-");
      return split[split.length - 1].toUpperCase();
    },
    infoControlItem(item) {
      return { ...item, value: this.humanize(item.value) };
    },
  },
};
</script>
