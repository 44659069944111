<template>
  <main class="container report-tabs">
    <b-modal
      id="change-column-order"
      size="lg"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
    >
      <template #default="{hide}">
        <template v-if="orderedColumns">
          <h3>
            Change Order of Columns
            in <em v-text="groupForColumnsOrdering.title" /> Group
          </h3>
          <draggable
            v-model="orderedColumns"
            class="overflow-auto p-2 m-2"
            style="max-height: 500px"
          >
            <div
              v-for="column in orderedColumns"
              :key="column.name"
              :class="{'text-muted': savingColumnOrder, 'border': true, 'my-1': true, 'p-1': true, draggable: true}"
              :data-test="`column-reorderer-${column.name}`"
              @dragstart="dragStart"
              @dragend="dragEnd"
            >
              <strong>
                <b-icon-grip-vertical /> {{ column.title }}
              </strong>
            </div>
          </draggable>
          <div
            v-if="orderColumnError"
            class="text-danger ml-2 my-2"
          >
            {{ orderColumnError }}
          </div>
          <b-button
            class="ml-1"
            variant="primary"
            :disabled="savingColumnOrder"
            data-test="save-column-order"
            @click="saveColumnOrder()"
          >
            <template v-if="savingColumnOrder">
              Saving... <b-spinner small />
            </template>
            <template v-else>
              Save
            </template>
          </b-button>
          <b-button
            :disabled="savingColumnOrder"
            class="ml-1"
            variant="secondary"
            @click="hide()"
            v-text="`Cancel`"
          />
        </template>
      </template>
    </b-modal>
    <b-modal
      id="column-order-successfuly-saved-message"
      size="lg"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
    >
      <h3 v-if="groupForColumnsOrdering">
        Order of Columns in {{ groupForColumnsOrdering.title }} Group
        Successfuly Saved!
      </h3>
      <b-button
        :disabled="savingColumnOrder"
        class="ml-1 float-right"
        variant="primary"
        @click="reload()"
        v-text="`OK`"
      />
    </b-modal>
    <div
      v-if="isLoading"
      class="spinner-border ml-5"
      role="status"
    >
      <span class="sr-only">Loading...</span>
    </div>
    <b-alert
      v-else-if="error"
      show
      variant="danger"
    >
      <template v-if="error === 'Access denied.'">
        This report is unavailable at the moment. In order to access the data,
        please revisit site that contained link to this report or
        <a href="mailto:blake@profound.net">contact us</a>.
      </template>
      <template v-else>
        Unexpected server error occurred.
      </template>
    </b-alert>
    <b-tabs
      v-else-if="showData"
      ref="tabs"
      v-model="tab"
      content-class="p-3 bg-light border-left border-right border-bottom rounded-bottom"
      active-nav-item-class="bg-light border-left border-right border-top"
    >
      <b-tab active>
        <template #title>
          <span data-test="groups-tab-title">Groups</span>
        </template>
        <FileColumns
          ref="columns"
          :data="data"
          @groupLeftClicked="loadGroupData(arguments[0], true)"
          @groupMiddleClicked="loadGroupData(arguments[0], false)"
          @columnClicked="columnClicked"
          @groupTitleChanged="groupTitleChanged(...arguments)"
          @changeColumnOrder="initializeChangeColumnOrder(...arguments)"
        />
      </b-tab>
      <b-tab>
        <template #title>
          <span data-test="file-overview-tab-title">File Overview</span>
        </template>
        <FileMetadata
          ref="metadata"
          :task-id="taskId"
          :data="data"
          @columnClicked="columnClicked"
          @columnShown="columnShown"
          @columnHidden="columnHidden"
        />
      </b-tab>
      <b-tab
        v-if="deployAll || data.customReportMd"
        title="Custom Report"
      >
        <CustomReport :init-report-code="data.customReportMd" />
      </b-tab>
      <b-tab
        v-if="deployAll"
        class="px-2 h-100 overflow-auto"
        title="Visitor Statistics"
        lazy
      >
        <VisitorStatistics />
      </b-tab>
      <b-tab
        v-for="group in visualizedGroups"
        :key="group.name"
      >
        <template #title>
          Data:
          <strong
            data-test="visualization-tab-title"
            v-text="group.title"
          >
            {{ group.title }}
          </strong>
          <div
            v-if="group.isLoading"
            class="spinner-border spinner-border-sm ml-2 mb-1"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
          <b-icon-x @click="closeVisualization(group)" />
        </template>
        <FileColumnMostCommonValues
          v-if="group.data"
          :ref="`${group.name}MostCommon`"
          :group="group"
          :total-rows="totalRows()"
          @groupTitleChanged="groupTitleChanged(group)"
          @changeColumnOrder="initializeChangeColumnOrder(...arguments)"
        />
      </b-tab>
    </b-tabs>
    <section v-else>
      <b-form @submit.prevent="forceShowingReport">
        <b-alert
          show
          variant="warning"
        >
          <h1>Configuration consistency problems detected</h1>
          <big>
            <p>
              Analysis results are inconsistent with applied configuration.
            </p>
            <template v-if="data.problems.multigroupColumns">
              <p>The following columns appear in multiple groups:</p>
              <p>
                <ul>
                  <li
                    v-for="([columnName, groups]) in data.problems.multigroupColumns"
                    :key="columnName"
                  >
                    {{ columnName }}
                    <ul>
                      <li
                        v-for="group in groups"
                        :key="group"
                      >
                        {{ group }}
                      </li>
                    </ul>
                  </li>
                </ul>
              </p>
            </template>
            <template v-if="data.problems.emptyGroups">
              <p>
                The following groups do not have any column found in the report:
              </p>
              <p>
                <ul>
                  <li
                    v-for="group in data.problems.emptyGroups"
                    :key="group.name"
                  >
                    {{ group.name }}
                  </li>
                </ul>
              </p>
            </template>
            <template v-if="data.problems.undefinedGroups">
              <p>
                The following group names appear in groups ordering,
                but aren't defined in grouping setup:
              </p>
              <p>
                <ul>
                  <li
                    v-for="groupName in data.problems.undefinedGroups"
                    :key="groupName"
                  >
                    {{ groupName }}
                  </li>
                </ul>
              </p>
            </template>
          </big>
        </b-alert>
        <b-form-group>
          <b-form-checkbox-group>
            <b-form-checkbox v-model="dontShowAgain">
              Don't show this message again
            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
        <b-button
          type="submit"
          variant="primary"
        >
          Show the report anyway
        </b-button>
      </b-form>
    </section>
  </main>
</template>

<script>
import { BIconX } from "bootstrap-vue";
import draggable from "vuedraggable";
import FileMetadata from "./FileMetadata.vue";
import FileColumns from "./FileColumns.vue";
import FileColumnMostCommonValues from "./FileColumnMostCommonValues.vue";
import httpHelper from "../../modules/httpHelper.js";
import CustomReport from './CustomReport.vue';
import VisitorStatistics from "./VisitorStatistics.vue";

export default {
  components: {
    FileMetadata,
    FileColumns,
    FileColumnMostCommonValues,
    BIconX,
    draggable,
    CustomReport,
    VisitorStatistics,
  },
  data() {
    return {
      tab: 0,
      data: null,
      visualizedGroups: [],
      isLoading: true,
      showData: false,
      dontShowAgain: false,
      draggingClasses: ["dragging", "bg-secondary", "text-light"],
      groupForColumnsOrdering: null,
      orderedColumns: null,
      savingColumnOrder: false,
      orderColumnError: null,
      error: null,
      customReportMd: null,
    };
  },
  computed: {
    deployAll: () =>
      ["true", "t", "yes", "y", "on"].includes(
        process.env.VUE_APP_DEPLOY_ALL.toLowerCase()
      ),
    backendUrl: () => process.env.VUE_APP_VISUALIZER_BACKEND_URL,
    backendToken: () => process.env.VUE_APP_BACKEND_TOKEN,
    taskId() {
      return this.$route.params.taskId;
    },
  },
  async mounted() {
    const urlBase = `csv-inside/${this.taskId}`;
    const url = `${this.backendUrl}/${urlBase}/?token=${this.backendToken}`;
    await this.fetchData(url);
    this.showData =
      !this.deployAll || this.data.ignoreInconsistency || !this.data.problems;
    this.isLoading = false;
  },
  methods: {
    reload: () => location.reload(),
    async fetchData(url) {
      const response = await fetch(url);
      const data = await httpHelper.safeJson(response);
      if (response.ok && data && Array.isArray(data.groups)) {
        window.app.$refs.navbar.filename = data.filename;
        data.groups = data.groups.map((element) => {
          element.data = null;
          element.isLoading = false;
          return element;
        });
        this.data = data;
        return;
      }
      if (data && Array.isArray(data.errors) && data.errors.length > 0) {
        this.error = data.errors[0];
        return;
      }
      this.error = "unknown";
    },
    totalRows() {
      return parseInt(this.data.totalRows.replaceAll(",", ""));
    },
    dragStart(event) {
      event.target.classList.add(...this.draggingClasses);
    },
    dragEnd(event) {
      event.target.classList.remove(...this.draggingClasses);
    },
    initializeChangeColumnOrder(group) {
      this.groupForColumnsOrdering = { name: group.name, title: group.title };
      this.orderedColumns = [...group.columns];
      this.$bvModal.show("change-column-order");
    },
    async saveColumnOrder() {
      this.savingColumnOrder = true;
      this.orderColumnError = null;
      const taskId = this.$route.params.taskId;
      let urlBase = `save-column-order/${taskId}`;
      const url = `${this.backendUrl}/${urlBase}/?token=${this.backendToken}`;
      const body = JSON.stringify({
        group: this.groupForColumnsOrdering.name,
        orderedColumns: this.orderedColumns.map((column) => column.name),
      });
      const response = await fetch(url, { method: "POST", body });
      this.savingColumnOrder = false;
      if (!response.ok) {
        const data = await httpHelper.safeJson(response);
        if (data.errors[0] === "Couldn't determine provided group.") {
          this.orderColumnError = data.errors[0];
          return;
        }
        this.orderColumnError = "Unknown error occurred.";
        return;
      }
      this.$bvModal.hide("change-column-order");
      this.$bvModal.show("column-order-successfuly-saved-message");
    },
    groupTitleChanged(group) {
      const visualizedGroup = this.visualizedGroups.find(
        (visualizedGroup) => visualizedGroup.name === group.name
      );
      if (visualizedGroup) {
        visualizedGroup.title = group.title;
      }
      this.data.groups.find(
        (dataGroup) => dataGroup.name === group.name
      ).title = group.title;
      this.$refs.metadata.$refs.searchBox.filter();
      this.$refs.columns.$refs.searchBox.filter();
    },
    openInNewTab(group) {
      this.tab = this.$refs.tabs.tabs.findIndex((tab) => {
        if (!tab.$slots.title) {
          return false;
        }
        const title = tab.$slots.title[1];
        if (!title) {
          return false;
        }
        return title.children[0].text.trim() === group.title;
      });
    },
    initVisualization(group, openInNewTab) {
      if (!this.visualizedGroups.includes(group)) {
        this.visualizedGroups.push(group);
      }
      if (openInNewTab) {
        this.$nextTick(() => this.openInNewTab(group));
      }
    },
    columnEntry(group, columnName) {
      return group.columns.find((column) => column.name === columnName);
    },
    closeVisualization(groupToClose) {
      this.visualizedGroups = this.visualizedGroups.filter(
        (group) => group !== groupToClose
      );
    },
    async loadGroupData(group, openInNewTab) {
      this.initVisualization(group, openInNewTab);
      if (group.data !== null) {
        return;
      }
      group.isLoading = true;
      const backendToken = process.env.VUE_APP_BACKEND_TOKEN;
      const taskId = this.$route.params.taskId;
      const body = JSON.stringify({ taskId, groupName: group.name });
      const url = `${this.backendUrl}/most-common/?token=${backendToken}`;
      const response = await fetch(url, { method: "POST", body });
      const groupData = await httpHelper.safeJson(response);
      group.data = groupData.columns;
      group.attachedImages = groupData.attachedImages;
      group.isLoading = false;
      group.data = Object.fromEntries(
        Object.entries(group.data).map(([columnName, column]) => {
          const isHidden = this.columnEntry(group, columnName).isHidden;
          const definition = this.data.collection.fields.find(
            field => columnName.match(`^${field.name}$`)
          ) || {description: ''};
          return [columnName, {...column, definition, isHidden}];
        })
      );
    },
    async columnClicked(clickType, column, group) {
      await this.loadGroupData(group, clickType === "left");
      const mostCommonElement = this.$refs[`${group.name}MostCommon`][0];
      const columnIndex = mostCommonElement
        .allColumns()
        .findIndex(([elementColumnName]) => elementColumnName === column.name);
      mostCommonElement.activeColumn = columnIndex;
    },
    isHistogramEntryFailure(entry) {
      return parseInt(entry.columnCount) !== this.data.columnCount;
    },
    async forceShowingReport() {
      this.showData = true;
      if (!this.dontShowAgain) {
        return;
      }
      const urlBase = `ignore-inconsistency/${this.taskId}`;
      const url = `${this.backendUrl}/${urlBase}/?token=${this.backendToken}`;
      await fetch(url, { method: "POST" });
    },
    columnShown(columnName) {
      this.$refs.columns.show(columnName);
    },
    columnHidden(columnName) {
      this.$refs.columns.hide(columnName);
    },
  },
};
</script>

<style scoped>
main {
  height: 100vh;
  display: flex;
  flex-flow: column;
}

.draggable {
  cursor: move;
}
.dragging {
  opacity: 0.5;
}
</style>

<style>
.report-tabs .nav-item > * {
  border-bottom: 0px;
}
/* non-scoped styles here due to using bootstrap-vue component styles overloading */
.report-tabs .tabs,
.report-tabs .tab-content,
.report-tabs .tab-pane {
  flex: 1;
  display: flex;
  flex-flow: column;
}
.report-tabs .tab-pane {
  min-height: 600px;
  max-height: calc(100vh - 13rem);
}
</style>
