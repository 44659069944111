<template>
  <VueApexCharts
    type="line"
    :options="options()"
    :series="series()"
    height="550"
  />
</template>

<script>
  import VueApexCharts from 'vue-apexcharts';
  import colors from './../../modules/colors.js';
  export default {
    components: {VueApexCharts},
    props: {correlations: {type: Array, default: null},},
    methods: {
      options() {
        return {
          colors: colors.distinctColors(10),
          chart: {zoom: {enabled: false}},
          xaxis: {
            categories: this.correlations.filter(
              entry => entry.correlationValue !== '<all>'
            ).map(entry => entry.correlationValue).reverse()
          }
        };
      },
      series() {
        return [
          ...new Set(
            this.correlations.filter(
              entry => entry.correlationValue !== '<all>'
            ).map(
              correlation => correlation.correlation.map(entry => entry.value)
            ).flat()
          )
        ].map(
          value => (
            {
              name: value,
              data: this.correlations.filter(
                entry => entry.correlationValue !== '<all>'
              ).map(
                correlation => (
                  correlation.correlation.find(
                    entry => entry.value === value
                  ) || {count: 0}
                ).count
              ).reverse()
            }
          )
        ).sort(
          (entry0, entry1) => {
            const value0 = entry0.data[entry0.data.length - 1];
            const value1 = entry1.data[entry1.data.length - 1];
            return value1 - value0;
          }
        ).splice(0, 10);
      },
    },
  };
</script>
