var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    { staticClass: "container" },
    [
      _vm.isLoading
        ? _c(
            "div",
            { staticClass: "row" },
            [
              _c("h1", [_vm._v("Loading collection of reports...")]),
              _c("b-spinner", { staticClass: "ml-3" })
            ],
            1
          )
        : _vm.data
        ? [
            _c("h1", [_vm._v(_vm._s(_vm.data.name))]),
            _c(
              "ul",
              _vm._l(_vm.data.reports, function(report) {
                return _c("li", { key: report.id }, [
                  _c("a", { attrs: { href: "/#/visualizer/" + report.id } }, [
                    _vm._v(" " + _vm._s(report.filename) + " ")
                  ])
                ])
              }),
              0
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }