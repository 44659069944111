var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "table",
    { staticClass: "table table-sm table-striped table-hover" },
    [
      _c("thead", [
        _c("tr", [
          _vm._m(0),
          _vm.type === "domain" || _vm.type === "domain-key"
            ? _c("th", { staticClass: "w-fit" }, [_vm._v(" Icon ")])
            : _vm.type === "country"
            ? _c("th", { staticClass: "w-fit" }, [_vm._v(" Flag ")])
            : _vm._e(),
          _vm.type === "domain-key"
            ? _c("th", [_vm._v(" Value ")])
            : _c("th", [_vm._v(" Most common value ")]),
          _vm.type !== "domain-key"
            ? _c("th", { staticClass: "text-right w-fit" }, [_vm._v(" Count ")])
            : _vm._e(),
          _c(
            "th",
            { staticClass: "text-right w-fit" },
            [
              _vm.type === "domain-key"
                ? [_vm._v(" Rank by DomainRank™ ")]
                : [_vm._v(" Share ")]
            ],
            2
          )
        ])
      ]),
      _c(
        "tbody",
        _vm._l(_vm.mostCommonValues, function(entry) {
          return _c("tr", { key: entry.value }, [
            _c(
              "td",
              { staticClass: "col-show-on-charts text-center" },
              [
                _c("b-form-checkbox", {
                  attrs: { value: entry.value },
                  on: {
                    change: function($event) {
                      return _vm.$emit(
                        "checkedMostCommonValuesChanged",
                        _vm.checkedMostCommonValues,
                        entry.value
                      )
                    }
                  },
                  model: {
                    value: _vm.checkedMostCommonValues,
                    callback: function($$v) {
                      _vm.checkedMostCommonValues = $$v
                    },
                    expression: "checkedMostCommonValues"
                  }
                })
              ],
              1
            ),
            _vm.type === "domain" || _vm.type === "domain-key"
              ? _c("td", { staticClass: "w-fit" }, [
                  _c("img", {
                    staticClass: "symbol",
                    attrs: {
                      src:
                        _vm.backendUrl +
                        "/favicon/" +
                        entry.value +
                        "/?token=" +
                        _vm.backendToken,
                      onerror: "this.classList.add('d-none')"
                    }
                  })
                ])
              : _vm.type === "country"
              ? _c("td", [
                  _c("span", {
                    class:
                      "flag-icon flag-icon-" +
                      _vm.countryCode(entry.value) +
                      " border"
                  })
                ])
              : _vm._e(),
            _vm.type === "url" && entry.value
              ? _c("td", { staticClass: "text-break" }, [
                  _vm.valueTags &&
                  _vm.valueTags[entry.value] &&
                  _vm.valueTags[entry.value].text
                    ? _c("div", {
                        staticClass: "badge badge-primary mr-2",
                        style: {
                          "background-color":
                            _vm.valueTags[entry.value].color || "#3e4c51"
                        },
                        domProps: {
                          textContent: _vm._s(_vm.valueTags[entry.value].text)
                        }
                      })
                    : _vm._e(),
                  _c("a", { attrs: { href: entry.value, target: "_blank" } }, [
                    _vm._v(_vm._s(entry.value))
                  ])
                ])
              : _vm._e(),
            _vm.type === "vulnerability" && entry.value
              ? _c("td", { staticClass: "text-break" }, [
                  _vm.valueTags &&
                  _vm.valueTags[entry.value] &&
                  _vm.valueTags[entry.value].text
                    ? _c("div", {
                        staticClass: "badge badge-primary mr-2",
                        style: {
                          "background-color":
                            _vm.valueTags[entry.value].color || "#3e4c51"
                        },
                        domProps: {
                          textContent: _vm._s(_vm.valueTags[entry.value].text)
                        }
                      })
                    : _vm._e(),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "https://nvd.nist.gov/vuln/detail/" + entry.value,
                        target: "_blank"
                      }
                    },
                    [_vm._v(_vm._s(entry.value))]
                  )
                ])
              : entry.value
              ? _c("td", { staticClass: "text-break" }, [
                  _vm.type === "color"
                    ? _c("div", {
                        staticClass: "symbol",
                        style: _vm.color(entry.value)
                      })
                    : _vm.valueTags &&
                      _vm.valueTags[entry.value] &&
                      _vm.valueTags[entry.value].text
                    ? _c("div", {
                        staticClass: "badge badge-primary mr-2",
                        style: {
                          "background-color":
                            _vm.valueTags[entry.value].color || "#3e4c51"
                        },
                        domProps: {
                          textContent: _vm._s(_vm.valueTags[entry.value].text)
                        }
                      })
                    : _vm._e(),
                  _vm._v(" " + _vm._s(entry.value) + " ")
                ])
              : _c("td", { staticClass: "text-break" }, [
                  _vm.valueTags &&
                  _vm.valueTags[entry.value] &&
                  _vm.valueTags[entry.value].text
                    ? _c("div", {
                        staticClass: "badge badge-primary mr-2",
                        style: {
                          "background-color":
                            _vm.valueTags[entry.value].color || "#3e4c51"
                        },
                        domProps: {
                          textContent: _vm._s(_vm.valueTags[entry.value].text)
                        }
                      })
                    : _vm._e(),
                  _c("em", [_vm._v("empty")])
                ]),
            _vm.type !== "domain-key"
              ? _c("td", { staticClass: "text-right w-fit" }, [
                  _vm._v(" " + _vm._s(_vm.humanize(entry.count)) + " ")
                ])
              : _vm._e(),
            _c(
              "td",
              { staticClass: "text-right w-fit" },
              [
                _vm.type === "domain-key"
                  ? [_vm._v(" " + _vm._s(-entry.count) + " ")]
                  : [_vm._v(" " + _vm._s(entry.rate) + " ")]
              ],
              2
            )
          ])
        }),
        0
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", { staticClass: "col-show-on-charts text-center" }, [
      _vm._v(" Show"),
      _c("br"),
      _vm._v("on Charts ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }